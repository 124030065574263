<template>
  <base-container class="p-3 gap-3">
    <h1>Retours de disponibilité</h1>
    <ProductAvailabilityPartnerLaboratoriesFilters
      :selected-partner="filter.value"
      @on-filter-click="handleFilterClick"
    />
    <base-flex-spinner v-if="loading" />
    <div
      v-else-if="availabilitiesByDay"
      class="flex flex-col gap-4"
    >
      <product-available-again-card
        v-for="date in sortedDates()"
        :key="date"
        :date="new Date(date)"
        :availabilities-by-source="availabilitiesByDay[date]"
      />
    </div>
    <p
      v-else
      class="text-center"
    >
      Aucune donnée à afficher
    </p>
  </base-container>
</template>

<script lang="ts" setup>
import {ref, onMounted, computed, reactive} from 'vue';
import {disruptionLogisticsApi} from "@/container";
import {ProductAvailability} from "@/disruption-logistics/interfaces";
import BaseContainer from "@/core/components/base/BaseContainer.vue";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";
import ProductAvailableAgainCard
    from "@/disruption-logistics/components/product-availability-card/ProductAvailableAgainCard.vue";
import ProductAvailabilityPartnerLaboratoriesFilters
    from "@/disruption-logistics/components/product-availability-card/ProductAvailabilityPartnerLaboratoriesFilters.vue";

export interface ProductAvailabilityByDayAndSource {
    [key: string]: ProductAvailabilityBySource
}

export interface ProductAvailabilityBySource {
    [key: string]: ProductAvailability[]
}

const loading = ref(true);
const filter = reactive<{ value: number | undefined | "others", include?: number, exclude?: number[] }>({
    value: undefined,
});
const rawData = ref<ProductAvailability[] | undefined>();

const handleFilterClick = ({value, include, exclude}: {
    value: number | "others",
    include?: number;
    exclude?: number[]
}) => {
    if (filter.value === value) {
        filter.value = undefined;
        filter.include = undefined;
        filter.exclude = undefined;
        return;
    }

    filter.value = value;
    filter.include = include;
    filter.exclude = exclude;
};

/**
 * Group availabilities by day and source
 *
 * @param {ProductAvailability[]} availabilities - List of availabilities
 */
const groupByDayAndSource = (availabilities: ProductAvailability[]): ProductAvailabilityByDayAndSource => {
    const groups = availabilities
        .filter((availability) => {
            if (filter.value === undefined) {
                return true;
            }

            return (filter.include && availability.product.laboratory_id === filter.include)
                || (filter.exclude && !filter.exclude.includes(availability.product.laboratory_id));
        })
        .reduce((acc, availability) => {
            availability.history.forEach((history) => {
                const date = new Date(history.updated_at).toDateString();

                if (!acc[date]) {
                    acc[date] = [];
                }

                acc[date].push(availability);
            });

            return acc;
        }, {});

    Object.keys(groups).forEach((date) => {
        groups[date] = groups[date].reduce((acc, availability) => {
            const source = availability.source?.name || '';

            if (!acc[source]) {
                acc[source] = [];
            }

            acc[source].push(availability);

            return acc;
        }, {} as Record<string, ProductAvailability[]>);
    });

    return groups;
};

/**
 * Get sorted dates
 */
const sortedDates = (): string[] => {
    return Object.keys(availabilitiesByDay.value || {}).sort((a, b) => {
        return new Date(b).getTime() - new Date(a).getTime();
    });
};

/**
 * Get availabilities by day and source
 */
const availabilitiesByDay = computed<ProductAvailabilityByDayAndSource>(() => {
    if (!rawData.value) {
        return {};
    }

    return groupByDayAndSource(rawData.value);
});

onMounted(async () => {
    try {
        loading.value = true;
        rawData.value = await disruptionLogisticsApi().productsAvailableAgain();
    } finally {
        loading.value = false;
    }
});
</script>

<style scoped>

</style>
