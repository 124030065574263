<script setup lang="ts">
import {KnowledgeBox} from "@/knowledge-box/interfaces";
import BaseModal from "@/core/components/base/BaseModal.vue";
import {computed, nextTick, onMounted, ref} from "vue";
import BaseSelect from "@/core/components/base/BaseSelect.vue";
import BaseButton from "@/core/components/base/BaseButton.vue";
import Icon from "@/core/components/icon/Icon.vue";
import {knowledgeBoxApi} from "@/container";
import MarkdownView from "@/core/components/markdown/Markdown.vue";
import GlowingContainer from "@/core/components/glowing-container/GlowingContainer.vue";

const props = defineProps<{
    knowledgeBoxes: KnowledgeBox[];
    knowledgeableType: string;
    knowledgeableId: number;
}>();

defineEmits(["close"]);

const knowledgeBoxId = ref<string | null>(props.knowledgeBoxes.length ? props.knowledgeBoxes[0].id.toString() : null);
const messages = ref<{ sender: string, text: string; finished: boolean; }[]>([]);
const isLoading = ref(false);
const userInput = ref();
const abortRequest = ref<(() => void) | null>(null);
const chatWindow = ref<HTMLElement | null>(null);

const knowledgeBox = computed(() => {
    return props.knowledgeBoxes.find(knowledgeBox => knowledgeBox.id.toString() === knowledgeBoxId.value);
});

onMounted(() => {
    messages.value.push({
        sender: 'system',
        text: "Bonjour, comment puis-je vous aider ?",
        finished: true
    });
});

const sendMessage = async () => {
    if(abortRequest.value) {
        abortRequest.value();
    }

    if (!userInput.value || !knowledgeBox.value || userInput.value.trim() === '') {
        return;
    }

    isLoading.value = true;
    messages.value.push({
        sender: 'user',
        text: userInput.value,
        finished: true
    });
    await scrollToBottom();

    const {request, abort} = await knowledgeBoxApi()
        .streamKnowledgeBox(
            knowledgeBox.value.id,
            props.knowledgeableType,
            props.knowledgeableId,
            async (chunk) => {
                if (messages.value[messages.value.length - 1].finished) {
                    messages.value.push({
                        sender: 'system',
                        text: chunk,
                        finished: false
                    });
                } else {
                    messages.value[messages.value.length - 1].text = chunk;
                }

                await scrollToBottom();
            },
            userInput.value
        );

    abortRequest.value = abort;
    request.catch(() => {
    })
        .finally(() => {
            isLoading.value = false;
            messages.value[messages.value.length - 1].finished = true;
            abortRequest.value = null;
        });

    userInput.value = null;
};

const scrollToBottom = async () => {
    await nextTick();
    if(chatWindow.value) {
        chatWindow.value.scrollTop = chatWindow.value.scrollHeight;
    }
};
</script>

<template>
  <BaseModal class="flex flex-col" title="Demandez conseil avec l'IA" @close="$emit('close')">
    <div v-if="knowledgeBoxes.length > 1" class="mb-4">
      <p>Sélectionnez une source et posez votre question</p>
      <BaseSelect
        v-model="knowledgeBoxId"
        :options="knowledgeBoxes.map((box) => ({value: box.id.toString(), label: box.title}))"
      />
    </div>
    <div class="message-container bg-gray-100 flex flex-col rounded-md">
      <div class="flex-1 overflow-hidden">
        <div class="h-full flex flex-col justify-end pl-6 pt-6 pb-6">
          <div ref="chatWindow" class="overflow-y-auto">
            <div v-for="(message, index) in messages" :key="index" class="mb-4">
              <div
                :class="[
                  'max-w-xs md:max-w-md lg:max-w-lg xl:max-w-xl rounded-lg p-3',
                  message.sender === 'user' ? 'bg-blue-500 ml-auto mr-6' : 'bg-white'
                ]"
              >
                <div v-if="message.sender === 'system'">
                  <Icon name="mdi-robot-happy" class="mr-2" color="primary" />
                </div>
                <MarkdownView
                  :class="[
                    message.sender === 'user' ? 'text-white' : 'text-gray-800'
                  ]" :inherit-styles="true" :content="message.text"
                />
              </div>
            </div>
            <div v-if="isLoading" class="flex items-center space-x-1">
              <div class="w-3 h-3 bg-gray-500 rounded-full animate-bounce" />
              <div class="w-3 h-3 bg-gray-500 rounded-full animate-bounce" style="animation-delay: 0.1s" />
              <div class="w-3 h-3 bg-gray-500 rounded-full animate-bounce" style="animation-delay: 0.2s" />
            </div>
          </div>
        </div>
      </div>
      <div class="bg-white border-t border-gray-200 py-2">
        <form class="flex" @submit.prevent="sendMessage">
          <GlowingContainer class="w-full">
            <div class="flex items-center gap-3">
              <input
                v-model="userInput"
                type="text"
                :placeholder="knowledgeBox?.default_prompt ?? 'Entrez votre message...'"
                class="flex-1 rounded-md px-4 py-2 focus:outline-none focus:ring-0"
              >
              <BaseButton v-if="!abortRequest" variant="flat" type="submit">
                Demander
                <Icon name="mdi-creation" color="primary" class="ml-1" />
              </BaseButton>
              <BaseButton v-if="isLoading && abortRequest" variant="flat" @click="abortRequest">
                <Icon name="mdi-stop-circle-outline" color="primary" />
              </BaseButton>
            </div>
          </GlowingContainer>
        </form>
      </div>
    </div>
  </BaseModal>
</template>

<style scoped>
.message-container {
    height: 80vh;
}
</style>
