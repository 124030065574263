<script setup lang="ts">
import HeaderBackButton from "@/core/components/header-back-button/HeaderBackButton.vue";
import imagesHelper from "@/core/helpers/imagesHelper";
import BaseButton from "@/core/components/base/BaseButton.vue";
import {computed, onMounted, ref} from "vue";
import {OfferTarget} from "@/core/interfaces/OfferTarget";
import {useSystemStore} from "@/stores/system.store";
import {useRoute} from "vue-router";
import {offerApi, shopApi} from "@/container";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";
import {LegacySellinOffer} from "@/core/interfaces/SellinOffer";
import DisruptionAlertProduct
    from "@/disruption-logistics/components/disruption-alert-product/DisruptionAlertProduct.vue";
import SellinOfferProduct from "@/sellin-offer/components/SellinOfferProductCard.vue";
import BaseModal from "@/core/components/base/BaseModal.vue";
import {useToastStore} from "@/stores/toast.store";
import {LaboratoryOrder} from "@/core/interfaces/disruption/LaboratoryOrder";
import DisruptionLaboratoryOrder
    from "@/disruption-logistics/components/disruption-laboratory-order/DisruptionLaboratoryOrder.vue";
import LaboratoryProduct from "@/core/interfaces/laboratory/LaboratoryProduct";
import dayjs from "dayjs";

const route = useRoute();
const offerId: number = Number(route.params.offerId);
const {environment} = useSystemStore();
const healthcenterOffer = ref<OfferTarget>();
const offer = ref<LegacySellinOffer>();
const prices = ref<{ [id: number]: { ht: number, discountedht: number }; }>({});
const imageUrl = ref<string>();
const loading = ref(true);
const commandVisible = ref(false);
const selectedOrder = ref<LaboratoryOrder>();
const {showError, showInfo} = useToastStore();

const healthcenterId = environment?.entityId;

onMounted(() => {
    offerApi().readSellinOffer(offerId)
        .then(_offer => {
            imageUrl.value = imagesHelper.getOfferImage(_offer);
            offer.value = _offer;
            if (healthcenterId) {
                healthcenterOffer.value = _offer.targets.find(target => target.target.id === healthcenterId);
                prices.value = _offer.products.reduce((all, item) => ({
                    ...all,
                    [item.productId]: {
                        ht: item.pfht * item.quantity,
                        discountedht: item.totalPriceAfterDiscountWithoutVat
                    }
                }), {}) || {};
            }
        })
        .finally(() => loading.value = false);
});

/**
 * Update product price.
 *
 * @param {LaboratoryProduct} product - The product to update.
 * @param {number} quantity - The new quantity.
 */
const updateProductPrice = (product: LaboratoryProduct, quantity: number) => {
    const productId = product.id;

    prices.value[productId] = {
        ht: (parseFloat(product.laboratoryShopPrice.priceWithoutVat) * quantity),
        discountedht: product.laboratoryShopPrice.totalDiscountedWithoutVat
    };

};

const totalDiscountedPrice = computed(() => {
    return Object.values(prices.value)
        .reduce((a, b) => {
            console.log(b);
            return a + b.discountedht;
        }, 0)
        .toFixed(2);
});

const totalPrice = computed(() => {
    return Object.values(prices.value)
        .reduce((a, b) => a + b.ht, 0)
        .toFixed(2);
});

/**
 * Retrieve order details.
 */
const getOrderDetail = () => {
    if (offer.value && healthcenterId) {
        shopApi().readBySellinOffer(offer.value.laboratory.id, healthcenterId, offer.value.id)
            .then((order) => {
                if (offer.value) {
                    selectedOrder.value = order;
                    commandVisible.value = true;
                }
            })
            .catch(() => showError({content: "Le serveur ne semble pas trouver cette commande"}));
    }
};

/**
 * Validate sellin offer.
 */
const validateOffer = () => {
    if (offer.value) {
        return accept();
    }
};

/**
 * Validate healthcenterOffer.
 */
const valideHeathcenterOffer = () => {
    const copy = {...healthcenterOffer.value};

    if (copy) {
        copy.acceptedAt = dayjs().toISOString();
        copy.hasAccepted = true;
    }

    healthcenterOffer.value = copy as OfferTarget;
};

/**
 * Accept the sellin offer.
 */
const accept = () => {
    if (offer.value && healthcenterId) {
        return offerApi().validateOrderSellin(healthcenterId, offer.value.id)
            .then((response) => {
                if (response) {
                    valideHeathcenterOffer();

                    showInfo({
                        content: "Votre commande a bien été prise en compte."
                    });

                    return;
                }

                return Promise.reject(new Error("Error on sellin validation"));
            });
    }
};

/**
 * Refuse the sellin offer.
 */
const refuse = () => {
    if (offer.value) {
        offerApi().refuseSellinOffer(offer.value.id)
            .then((_offer) => {
                offer.value = _offer;
            });
    }
};

/**
 * Determine if the sellin offer is currently active.
 */
const isSellinActive = () => {
    const start = offer.value?.startDate;
    const end = offer.value?.endDate;
    const now = dayjs();

    return now.isAfter(start) && now.isBefore(end);
};

/**
 * Determine if the offer has products on order quota.
 *
 * @return {boolean | undefined}
 */
const hasProductOnQuota = () => {
    return offer.value?.products.some(offerProduct => offerProduct.product?.has_order_quota);
};
</script>

<template>
  <header-back-button title="Alertes et dépannages" />
  <base-flex-spinner v-if="loading" />
  <div v-if="offer" class="p-3">
    <h1 class="text-center pb-3">
      {{ offer.name }}
    </h1>
    <div class="flex flex-col items-center justify-center">
      <img :src="imageUrl" class="h-32 w-32 rounded-lg">
      <div class="flex flex-col bg-white rounded-lg mt-3">
        <div class="bg-primary bg-opacity-50 rounded-t-lg py-2 px-4 overflow-auto">
          <p class="text-sm font-bold pt-1">
            Du {{ $filters.date(offer.startDate, 'short') }} au {{ $filters.date(offer.endDate, 'short') }}
          </p>
        </div>
        <div class="p-3 rounded-b-lg">
          <p class="text-sm">
            {{ offer.description }}
          </p>
          <p v-if="offer.laboratory.disruptionAlert?.default_description" class="text-sm">
            {{ offer.laboratory.disruptionAlert.default_description }}
          </p>
        </div>
      </div>
      <div class="flex flex-row items-center py-3">
        <v-chip
          v-if="healthcenterOffer?.hasRefused || healthcenterOffer?.hasAccepted"
          :color="healthcenterOffer.hasAccepted ? 'green' : 'red'"
          small
        >
          <p class="text-sm">
            {{ healthcenterOffer.hasAccepted ? 'Accepté' : 'Refusé' }}
          </p>
        </v-chip>
        <div class="px-3">
          <base-button primary @click="getOrderDetail">
            Voir la commande
          </base-button>
          <base-modal v-show="commandVisible" title="Commande" @close="commandVisible=false">
            <disruption-laboratory-order v-if="selectedOrder" :order="selectedOrder" />
          </base-modal>
        </div>
      </div>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-title>
            Rupture {{ offer.notificationDisruptionAlert?.notificationDisruptionAlertProducts[0]?.wholesaler.name }} le
            {{
              $filters.date(offer.notificationDisruptionAlert.date)
            }}
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <disruption-alert-product
              v-for="product in offer.notificationDisruptionAlert.notificationDisruptionAlertProducts"
              :key="product.productId"
              class="py-3"
              :product="product"
            />
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
      <h2 class="text-black font-semibold pt-3">
        Quantité de produits: {{ offer.products.length }}
      </h2>
      <sellin-offer-product
        v-for="product in offer.products"
        :key="product.id"
        :product="product"
        :offer="offer"
        class="my-3"
        @on-quantity-changed="updateProductPrice"
      />
      <div v-if="prices" class="my-4">
        <div class="flex flex-row items-center">
          <h2 class="pr-1">
            Total HT :
          </h2>
          <h2 :class="[{'line-through text-red': totalPrice !== totalDiscountedPrice}]">
            {{ totalPrice }} €
          </h2>
        </div>
        <div class="flex flex-row">
          <h2 class="pr-1">
            Total HT Remisé :
          </h2>
          <h2> {{ totalDiscountedPrice }} €</h2>
        </div>
      </div>
      <div
        v-if="isSellinActive() && healthcenterOffer && !(healthcenterOffer.hasRefused || healthcenterOffer.hasAccepted)"
        class="flex flex-row gap-20"
      >
        <base-button
          :disabled="hasProductOnQuota()"
          @click="refuse"
        >
          Refuser
        </base-button>
        <base-button
          @click="validateOffer"
        >
          Accepter
        </base-button>
      </div>
    </div>
  </div>
</template>
