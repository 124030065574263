<script setup lang="ts">
import useSwr from "@/core/composables/swr/useSwr";
import {laboratoryApi} from "@/container";
import LaboratoryOperatorPodium from "@/laboratory/components/LaboratoryOperatorPodium.vue";
import LaboratoryOperatorSaleHistogram from "@/laboratory/components/LaboratoryOperatorSaleHistogram.vue";
import LaboratoryOperatorSalePie from "@/laboratory/components/LaboratoryOperatorSalePie.vue";
import LaboratoryOperatorsDataTable from "@/laboratory/components/LaboratoryOperatorsDataTable.vue";
import dayjs from "dayjs";
import BaseSelect from "@/core/components/base/BaseSelect.vue";
import {computed, ref} from "vue";
import BaseCard from "@/core/components/base/BaseCard.vue";
import BaseCardBody from "@/core/components/base/BaseCardBody.vue";
import BaseCardHeader from "@/core/components/base/BaseCardHeader.vue";

const props = defineProps<{
    laboratoryId: string;
}>();

const dateOptions = [
    {
        label: "Sur 1 mois",
        value: dayjs().subtract(1, "month").format("YYYY-MM-DD")
    },
    {
        label: "Sur 3 mois",
        value: dayjs().subtract(3, "month").format("YYYY-MM-DD")
    },
    {
        label: "Sur 1 an",
        value: dayjs().subtract(1, "year").format("YYYY-MM-DD")
    },
    {
        label: "Sur 3 ans",
        value: dayjs().subtract(3, "year").format("YYYY-MM-DD")
    }
];

const dateFilter = ref(dateOptions[2].value);

const key = computed(() => {
    const searchParams = new URLSearchParams();

    searchParams.set('since', dateFilter.value);

    return `laboratory/${props.laboratoryId}/operators/analysis${searchParams.toString()}`;
});

const {data, isLoading, isValidating} = useSwr(
    key,
    () => laboratoryApi().operatorAnalysis(Number(props.laboratoryId), {since: dateFilter.value}),
    {
        revalidateOnFocus: false
    }
);
</script>

<template>
  <div v-if="isLoading">
    Loading...
  </div>
  <div v-if="data" class="p-6 flex flex-col gap-6">
    <div class="flex flex-wrap flex-col lg:flex-row gap-6">
      <div class="flex-1 lg:self-end">
        <LaboratoryOperatorPodium :data="data" />
      </div>
      <div class="flex-2 grid grid-cols-1 md:grid-cols-2 gap-6">
        <LaboratoryOperatorSaleHistogram :data="data" />
        <LaboratoryOperatorSalePie :data="data" />
      </div>
    </div>
    <BaseCard>
      <BaseCardHeader>
        <div class="flex gap-4 items-center">
          Top vendeurs
          <BaseSelect v-model:modelValue="dateFilter" trigger-class="justify-end w-auto" :options="dateOptions" />
        </div>
      </BaseCardHeader>
      <BaseCardBody>
        <LaboratoryOperatorsDataTable :data="data" :loading="isLoading || isValidating" />
      </BaseCardBody>
    </BaseCard>
  </div>
</template>

<style scoped>

</style>
