<script setup lang="ts">
import {FileManagerFile} from "@/core/interfaces/FileManagerFile";

defineProps<{
    logo?: FileManagerFile;
    alt: string;
    imgClass?: string;
}>();
</script>

<template>
  <div class="h-28 aspect-square rounded-md overflow-hidden flex">
    <img v-if="logo?.url" class="w-full h-full" :class="imgClass ?? ''" :src="logo.url" :alt="alt">
    <slot v-else name="fallback">
      <div class="bg-gray-200 w-full h-full" />
    </slot>
  </div>
</template>
