import {acceptHMRUpdate, defineStore} from "pinia";
import {taskApi} from "@/container";
import LiveItemMapperService from "../core/services/LiveItemMapperService";
import {
    HomeInterviewInstanceItem,
    HomeOrderItem,
    HomeSurveyAnswerInstanceItem,
    OrderCreatedBroadcastEvent
} from "@/home/interfaces";
import {LiveItemType} from "@/core/interfaces/live/LiveItem";
import {SurveyAnswerInstance} from "@/core/interfaces/survey/Survey";
import dayjs from "dayjs";

interface LiveStore {
    tasks: (HomeInterviewInstanceItem | HomeOrderItem | HomeSurveyAnswerInstanceItem)[];
}

export const useLiveStore = defineStore('live', {
    state: (): LiveStore => ({
        tasks: []
    }),
    actions: {
        /**
         * Add a new order to the list of orders.
         *
         * @param item
         */
        newBroadcastOrder(item: OrderCreatedBroadcastEvent) {
            if (!this.tasks.find(
                previousItem => previousItem.type === LiveItemType.ORDER_CREATED && previousItem.data.id === item.id)
            ) {
                this.tasks.unshift(LiveItemMapperService().newOrder(-1, item));
            }
        },
        /**
         * Update a task completed at for a survey.
         */
        async updateSurveyItem(item: SurveyAnswerInstance) {
            const index = this.tasks.findIndex(
                (currentItem) => currentItem.type === LiveItemType.SURVEY_ANSWER_INSTANCE && currentItem.data.id === item.id
            );
            if (index > -1) {
                const task = this.tasks[index];
                return taskApi().update(task.id, dayjs().format('YYYY-MM-DD HH:mm:ss'))
                    .then(() => this.tasks.splice(index, 1));
            }
            return Promise.resolve();
        },
        /**
         * Delete task item.
         */
        deleteItems(item: any, type: LiveItemType) {
            const index = this.tasks.findIndex(
                (currentItem) => currentItem.type === type && currentItem.data.id === item.id
            );
            if (index > -1) {
                this.tasks.splice(index, 1);
            }
        },
        /**
         * Load all the live items.
         */
        async loadItems() {
            return taskApi().index()
                .then(response => {
                    this.tasks = response.data?.map(task => LiveItemMapperService().newTask(task)) ?? [];
                });
        }
    }
});

//https://pinia.vuejs.org/cookbook/hot-module-replacement.html#HMR-Hot-Module-Replacement-
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useLiveStore, import.meta.hot));
}
