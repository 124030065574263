import {LiveItemType} from "../interfaces/live/LiveItem";
import Order from "@/order/interfaces/Order";
import {
    HomeInterviewInstanceItem,
    HomeOrderItem,
    HomeSurveyAnswerInstanceItem,
    OrderCreatedBroadcastEvent
} from "@/home/interfaces";
import {InterviewInstance} from "@/interview/interfaces/interview";
import {SurveyAnswerInstance} from "@/core/interfaces/survey/Survey";

/**
 * Live item mapper service.
 */
export default function () {
    return {
        newOrder (id: number, order: Order | OrderCreatedBroadcastEvent): HomeOrderItem {
            return {
                id,
                type: LiveItemType.ORDER_CREATED,
                data: order
            };
        },
        newInterviewInstance(id: number, instance: InterviewInstance): HomeInterviewInstanceItem {
            return {
                id,
                type: LiveItemType.INTERVIEW_INSTANCE,
                data: instance
            };
        },
        newSurveyInstance(id: number, instance: SurveyAnswerInstance): HomeSurveyAnswerInstanceItem {
            return {
                id,
                type: LiveItemType.SURVEY_ANSWER_INSTANCE,
                data: instance
            };
        },
        newTask(task: any): any {
            if (task.taskable_type === 'order') {
                return this.newOrder(task.id, task.taskable);
            } else if (task.taskable_type === 'survey-answer') {
                return this.newSurveyInstance(task.id, task.taskable);
            } else if (task.taskable_type === 'interview-instance') {
                return this.newInterviewInstance(task.id, task.taskable);
            }
        }
    };
}
