<script setup lang="ts">

import {computed} from 'vue';
import BaseLaboratoryPodium from "@/laboratory/components/BaseLaboratoryPodium.vue";

interface Props {
    data: {
        operator: string;
        revenue: number;
    }[];
}

const props = defineProps<Props>();

const sortedOperators = computed(() => {
    return [...props.data].sort((a, b) => b.revenue - a.revenue).slice(0, 3);
});

const highestRevenue = computed(() => {
    return sortedOperators.value[0]?.revenue ?? 0;
});

const computePodiumHeights = computed(() => {
    const minHeight = 80;
    const maxHeight = 200;

    const getHeight = (revenue: number) => {
        if (highestRevenue.value === 0) {
            return minHeight;
        }

        const heightPercentage = revenue / highestRevenue.value;
        return Math.max(minHeight, Math.round(maxHeight * heightPercentage));
    };

    return {
        1: `${getHeight(sortedOperators.value[0]?.revenue || 0)}px`,
        2: `${getHeight(sortedOperators.value[1]?.revenue || 0)}px`,
        3: `${getHeight(sortedOperators.value[2]?.revenue || 0)}px`
    };
});

const getOperatorByPosition = (position: number) => {
    const index = position - 1;
    return sortedOperators.value[index];
};
</script>

<template>
  <BaseLaboratoryPodium
    v-if="data.length"
    :podium-heights="computePodiumHeights"
  >
    <template v-for="position in [1, 2, 3]" :key="position" #[`position-${position}`]>
      <template v-if="getOperatorByPosition(position)">
        <h2 class="text-xl font-bold mb-2 text-center">
          {{ getOperatorByPosition(position)?.operator }}
        </h2>
        <p class="text-lg text-center">
          {{ $filters.currencyze(getOperatorByPosition(position)?.revenue || 0) }} TTC
        </p>
      </template>
    </template>
  </BaseLaboratoryPodium>
</template>
