<template>
  <div>
    <base-flex-spinner v-if="loading" class="mt-16" />
    <BaseContainer v-else class="p-5 pb-24">
      <v-stepper
        v-model="step"
        :non-linear="true"
        style="box-shadow: none; background: transparent;"
      >
        <div class="flex flex-row items-center mb-2">
          <div class="flex items-center bg-primary rounded-full p-2 mr-2 cursor-pointer" @click="$router.go(-1)">
            <Icon name="mdi-arrow-left" color="white" class="h-6 w-6" />
          </div>
          <v-stepper-header class="bg-white rounded-xl mb-1 flex-1">
            <v-stepper-item
              :complete="step > 1"
              :value="1"
              title="Saisir"
              editable
              edit-icon=""
            />

            <v-divider class="border-gray-700" />

            <v-stepper-item
              :complete="step > 2"
              :value="2"
              title="Identifier"
              editable
              :disabled="step < 2"
              edit-icon=""
            />

            <v-divider class="border-gray-700" />

            <v-stepper-item
              :complete="step > 3"
              :value="3"
              title="Configurer"
              editable
              :disabled="step < 3"
              edit-icon=""
            />

            <v-divider class="border-gray-700" />

            <v-stepper-item
              :value="4"
              title="Diffuser"
              editable
              edit-icon=""
              :disabled="step < 4"
            />
          </v-stepper-header>
        </div>
        <div class="flex flex-row">
          <prescription-visual
            v-if="prescription"
            :prescription="prescription"
          />
          <div class="flex flex-1 flex-col p-2">
            <v-stepper-window :model-value="step">
              <v-stepper-window-item
                v-for="n in 4"
                :key="`${n}-content`"
                class="pb-2"
                :value="n"
              >
                <prescription-renewal-plan
                  v-if="n===1"
                  v-model:dates="dates"
                  v-model:start-date="startDate"
                  v-model:end-date="endDate"
                  :prescription="prescription!"
                  @next-step="step=2"
                />
                <prescription-renewal-operator
                  v-if="n===2"
                  v-model:selected="selectedOperatorCode"
                  :team-access="teamAccess"
                  :operator-codes="operatorCodes"
                  :selected-operator-code="(selectedOperatorCode as string | undefined)"
                  @next-step="step=3"
                />
                <prescription-renewal-notify
                  v-if="n===3 && prescription"
                  v-model:selected-notification-channel="selectedNotificationChannel"
                  v-model:customer-phone="customerPhone"
                  :dates="dates"
                  :customer="prescription.customer"
                  @next-step="step=4"
                />
                <prescription-renewal-publish
                  v-if="n===4"
                  :dates="dates"
                  :is-update="!!prescription?.renewal_plan"
                  :end-date-formatted="endDateFormatted"
                  :selected-operator-label="itemLabel(selectedOperatorCode)"
                  :selected-notification-channel="selectedNotificationChannel"
                  @on-publish="storeRenewalPlan()"
                />
              </v-stepper-window-item>
            </v-stepper-window>
          </div>
        </div>
      </v-stepper>
    </BaseContainer>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import dayjs from 'dayjs';
import PrescriptionRenewalPlan from '@/prescription/components/PrescriptionRenewalPlan.vue';
import PrescriptionVisual from '@/prescription/components/PrescriptionVisual.vue';
import PrescriptionRenewalPublish from '@/prescription/components/PrescriptionRenewalPublish.vue';
import {prescriptionApi, prescriptionRenewalPlanApi, teamApi} from '@/container';
import PrescriptionRenewalOperator from '@/prescription/components/PrescriptionRenewalOperator.vue';
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";
import {mapActions, mapState} from "pinia";
import {usePatientStore} from "@/stores/patient.store";
import dateHelper from "@/core/helpers/dateHelper";
import {Prescription} from "@/prescription/interfaces/Prescription";
import {useToastStore} from "@/stores/toast.store";
import PrescriptionRenewalNotify from "@/prescription/components/PrescriptionRenewalNotify.vue";
import LegacyAccess from "@/core/interfaces/Access";
import BaseContainer from "@/core/components/base/BaseContainer.vue";
import Icon from "@/core/components/icon/Icon.vue";
import {PrescriptionRenewalPlanDate} from "@/prescription/interfaces/prescription-renewal-plan/PrescriptionRenewalPlan";

export default defineComponent({
    name: 'PrescriptionRenewalDetailScreen',
    components: {
        Icon,
        BaseContainer,
        PrescriptionRenewalNotify,
        BaseFlexSpinner,
        PrescriptionRenewalOperator,
        PrescriptionRenewalPlan,
        PrescriptionVisual,
        PrescriptionRenewalPublish
    },
    data() {
        return {
            prescription: null as Prescription | null,
            dates: [] as Date[],
            teamAccess: [],
            operatorCodes: [] as string[],
            selectedOperatorCode: '' as string,
            selectedNotificationChannel: 'push' as string,
            customerPhone: null as string | null,
            isRenewable: true,
            loading: true,
            step: 1,
            startDate: dayjs().toDate() as Date,
            endDate: dayjs().add(3, 'month').toDate() as Date
        };
    },
    computed: {
        ...mapState(usePatientStore, ['customer']),
        endDateFormatted() {
            return dateHelper.shortFormat(this.endDate);
        },
        queryCustomerId() {
            return parseInt(this.$route.query.customerId as string);
        }
    },
    watch: {
        '$route.params.prescriptionId': {
            immediate: true,

            handler(value) {
                if (this.queryCustomerId) {
                    this.read(this.queryCustomerId, value);
                } else if (this.customer) {
                    this.read(this.customer.id, value);
                }
            }
        }
    },
    mounted() {
        this.indexOperatorCodes();
    },
    methods: {
        ...mapActions(useToastStore, ['showSuccess', 'showError']),
        /**
         * Read details of the prescription, necessary to have information missing in index ressource.
         */
        read(customerId: number, prescriptionKey: string) {
            prescriptionApi()
                .read(customerId, prescriptionKey)
                .then(response => {
                    this.prescription = response;
                    this.isRenewable = response.renewable;
                    this.startDate = new Date(response.created_at);
                    this.customerPhone = response.customer.information?.phone || null;

                    if (response.renewal_plan) {
                        this.endDate = new Date(response.renewal_plan.end_at);
                        this.dates = response.renewal_plan.renewal_dates
                            .map((date: PrescriptionRenewalPlanDate) => new Date(date.renew_at));
                        this.selectedOperatorCode = response.renewal_plan.operator_code;
                        this.selectedNotificationChannel = response.renewal_plan.customer_notification_channel;
                    } else {
                        this.endDate = dayjs(this.startDate).add(3, 'month').toDate();
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        /**
         * Get item label.
         *
         * @param {string} code - The operator code.
         * @return {string}
         */
        itemLabel(code: string | null): string | null {
            const access: LegacyAccess | undefined = this.teamAccess?.find(
                (access: LegacyAccess) => !!code && access.operatorCode === code
            );

            if (access) {
                return `${(access as LegacyAccess).user.name} (${code})`;
            }

            return code;
        },
        /**
         * Get list of operator codes.
         */
        indexOperatorCodes() {
            teamApi().index()
                .then(response => {
                    this.teamAccess = response.team;

                    return teamApi().operatorCodes();
                })
                .then(response => {
                    this.operatorCodes = response;
                });
        },
        /**
         * Update the operator code of the renewal plan.
         *
         * @param code
         */
        updateOperatorCode(code) {
            this.selectedOperatorCode = code;
        },
        /**
         * Create a new renewal plan.
         *
         * @return {Promise<unknown>}
         */
        storeRenewalPlan(): Promise<void> | undefined {
            if (!this.prescription) {
                return Promise.resolve();
            }

            const params = {
                end_at: dayjs(this.endDate).format('YYYY-MM-DD'),
                dates: this.dates.map(date => {
                    return {renew_at: dayjs(date).format('YYYY-MM-DD')};
                }),
                prescription_key: this.prescription.key,
                operator_code: this.selectedOperatorCode,
                customer_notification_channel: this.selectedNotificationChannel,
                customer_phone: this.customerPhone
            };

            if (this.prescription.renewal_plan) {
                return prescriptionRenewalPlanApi().update(this.prescription.renewal_plan, params)
                    .then(() => {
                        this.showSuccess({content: 'Le plan de renouvellement a été mis à jour avec succès'});
                    })
                    .catch(() => {
                        this.showError({content: "Une erreur s'est produite lors de la mise à jour du plan de renouvellement"});
                    })
                    .finally(() => {
                        this.$router.go(-1);
                    });
            } else {
                return prescriptionRenewalPlanApi().create(params)
                    .then(() => {
                        this.showSuccess({content: 'Le plan de renouvellement a été créé avec succès'});
                    })
                    .catch(() => {
                        this.showError({content: "Une erreur s'est produite lors de la création du plan de renouvellement"});
                    })
                    .finally(() => {
                        this.$router.go(-1);
                    });
            }
        }
    }
});
</script>

<style lang="postcss" scoped>
.v-stepper-window {
    @apply !m-0
}

:deep(h1), :deep(h2), :deep(h3), :deep(h4), :deep(p) {
    @apply ml-1
}

:deep(h1) {
    @apply text-2xl font-bold
}

:deep(v-btn) {
    @apply px-10
}

:deep(.step-card) {
    @apply border-gray-200 border rounded-xl p-4 bg-white flex flex-col gap-y-3
}
</style>
