<script setup lang="ts">
import Consumer from "@/core/interfaces/consumers/Consumer";
import BaseLaboratoryPodium from "@/laboratory/components/BaseLaboratoryPodium.vue";
import {computed} from "vue";

const props = defineProps<{
    data: {
        consumer?: Consumer,
        revenue: number
    }[]
}>();

const sortedConsumers = computed(() => {
    return [...props.data].sort((a, b) => b.revenue - a.revenue).slice(0, 3);
});

const highestRevenue = computed(() => {
    return sortedConsumers.value[0]?.revenue ?? 0;
});

const computePodiumHeights = computed(() => {
    const minHeight = 80;
    const maxHeight = 200;

    const getHeight = (revenue: number) => {
        if (highestRevenue.value === 0) {
            return minHeight;
        }

        const heightPercentage = revenue / highestRevenue.value;
        return Math.max(minHeight, Math.round(maxHeight * heightPercentage));
    };

    return {
        1: `${getHeight(sortedConsumers.value[0]?.revenue || 0)}px`,
        2: `${getHeight(sortedConsumers.value[1]?.revenue || 0)}px`,
        3: `${getHeight(sortedConsumers.value[2]?.revenue || 0)}px`
    };
});

const getConsumerByPosition = (position: number) => {
    const index = position - 1;
    return sortedConsumers.value[index];
};
</script>

<template>
  <BaseLaboratoryPodium
    v-if="data.length"
    :podium-heights="computePodiumHeights"
  >
    <template v-for="position in [1, 2, 3]" :key="position" #[`position-${position}`]>
      <template v-if="getConsumerByPosition(position)">
        <h2 class="text-xl font-bold mb-2 text-center">
          {{ getConsumerByPosition(position)?.consumer?.information?.name ?? "Patient Anonyme" }}
        </h2>
        <p class="text-lg text-center">
          {{ $filters.currencyze(getConsumerByPosition(position)?.revenue || 0) }} TTC
        </p>
      </template>
    </template>
  </BaseLaboratoryPodium>
</template>

<style scoped>

</style>
