import {AxiosInstance, AxiosResponse} from "axios";
import Customer from "@/customer/interfaces/Customer";
import {Tag} from "@/prescription/interfaces/Prescription";

/**
 * The customer resource.
 * @author Tony Laurent <t.laurent@apodispharma.com>
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client: AxiosInstance) {
    return {
        /**
         * Get all customers.
         *
         * @param {{ page?: number, certified?: boolean }} params The params used to get customers list.
         *
         * @returns {Promise} Promise object represents the HTTP request.
         */
        get(params = {}) {
            return client.get('/customers', {params})
                .then((response: AxiosResponse) => response.data)
                .catch(() => []);
        },

        /**
         * Read a customer.
         * @param {number} id - The ID of the customer.
         * @returns {Promise} Promise object represents the HTTP request.
         */
        read(id: number): Promise<Customer> {
            return client.get(`/customers/${id}`)
                .then((response: AxiosResponse) => response.data.data)
                .catch(() => null);
        },

        /**
         * Patch a customer.
         * @param id
         * @param declaredInformation
         */
        patch(id: number, declaredInformation: object): Promise<Customer> {
            const data = {'declared_information': declaredInformation};
            return client.patch(`/customers/${id}`, {data})
                .then((response: AxiosResponse) => response.data.data);
        },

        /**
         * Search customers with keywords.
         * @param {string} keywords - Searching keywords.
         * @returns {Promise} Promise object represents the HTTP request.
         */
        search(keywords: string) {
            return client
                .get('/customers?no-stats', {
                    params: {
                        limit: 50,
                        search: keywords
                    }
                })
                .then((response: AxiosResponse) => response.data.data)
                .catch(() => []);
        },
        /**
         * Get the customers dashboard.
         */
        async dashboard(params = {}): Promise<{ data: Customer[], meta: { tags: Tag[] } }> {
            return await client.get('/customers/dashboard', {
                params
            })
                .then((response: AxiosResponse) => response.data);
        }
    };
}
