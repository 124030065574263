<template>
  <installation-step-layout @open-guide="$emit('openGuide')">
    <template #title>
      Installer Apodis Connect
    </template>
    <template #subtitle>
      Communiquez avec vos patients
    </template>
    <template #image>
      <dot-lottie-vue
        style="margin: 0; width: 200px; height: 200px"
        src="animations/bell.json"
        autoplay
        loop
      />
    </template>
    <template #content>
      <p class="text-primary">
        Aidez votre équipe à mieux suivre vos patients <br>À installer <strong>sur tous vos
          postes comptoir</strong>
      </p>
    </template>
    <template #download>
      <DownloadButton
        title="Apodis Connect"
        :icon="apodisConnectIcon"
        @click="openConnect"
      />
    </template>
  </installation-step-layout>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import DownloadButton from '@/onboarding/components/download-button/DownloadButton.vue';
import InstallationStepLayout from '@/onboarding/components/installation-process/InstallationStepLayout.vue';
import {DotLottieVue} from "@lottiefiles/dotlottie-vue";
import apodisConnectIcon from '@/assets/icons/apodis-connect.svg';

export default defineComponent({
    name: 'ApodisConnectStep',
    components: {InstallationStepLayout, DownloadButton, DotLottieVue},
    emits: ['openGuide'],
    data() {
        return {
            apodisConnectIcon
        };
    },
    methods: {
        openConnect() {
            const linkConnect = import.meta.env.VITE_CONNECT_EXE_URL;
            window.open(linkConnect);
        }
    }
});
</script>
