import {useSystemStore} from "@/stores/system.store";
import {ref} from "vue";
import {storeToRefs} from "pinia";

const useEnvironment = () => {
    const systemStore = useSystemStore();
    const isLoading = ref(false);
    const error = ref<Error | null>(null);
    const {environment, groupingLogo} = storeToRefs(systemStore);

    /**
     * Load the environment.
     */
    const loadEnvironment = async () => {
        isLoading.value = true;
        error.value = null;

        try {
            await systemStore.loadEnvironment();
        } catch (_error) {
            error.value = _error instanceof Error ? _error : new Error("Une erreur est survenue lors du chargement de l'environnement.");
        } finally {
            isLoading.value = false;
        }
    };

    return {
        isLoading,
        loadEnvironment,
        error,
        environment,
        groupingLogo
    };
};

export default useEnvironment;
