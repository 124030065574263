<script setup lang="ts">
import BaseButton from "@/core/components/base/BaseButton.vue";

defineProps<{ uncertifiedCustomers: any[] }>();
defineEmits(['on-certify-press']);

/**
 * Get user full name.
 *
 * @param user
 *
 * @return {string}
 */
const userName = (user: any) => {
    return `${user.first_name} ${user.last_name}`;
};

</script>

<template>
  <div class="px-4 pb-4">
    <v-expansion-panels
      v-if="uncertifiedCustomers.length"
    >
      <v-expansion-panel
        class="rounded-lg border-orange-default border-2"
      >
        <v-expansion-panel-title>
          Vous avez {{ uncertifiedCustomers.length }} {{
            $filters.pluralize('patient', uncertifiedCustomers.length)
          }}
          en attente de certification
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <p class="text-primary">
            Ces patients se sont inscrits et ont choisi votre pharmacie. En certifiant leur compte, ils pourront
            accéder à leurs données (ordonnances, etc.) et bénéficier des services Apodis.
          </p>
          <div
            v-for="(uncertifiedCustomer, index) in uncertifiedCustomers"
            :key="index"
            class="flex flex-row p-2 align-center justify-space-between"
          >
            <div class="flex flex-1 flex-col">
                    <span class="font-bold">
                      {{ userName(uncertifiedCustomer) }}
                    </span>
              <span>{{ $filters.phoneFormat(uncertifiedCustomer.phone) }}</span>
            </div>
            <div class="flex flex-grow">
              <span>s'est inscrit le {{ $filters.date(uncertifiedCustomer.created_at, 'short') }}</span>
            </div>
            <div class="flex flex-2 justify-end ">
              <base-button
                primary
                @click="$emit('on-certify-press',uncertifiedCustomer)"
              >
                Certifier
              </base-button>
            </div>
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<style scoped>

</style>