import {AxiosError, AxiosResponse} from "axios";
import {useToastStore} from "@/stores/toast.store";

const duration = 5000;

export default {
    success(response: AxiosResponse) {
        return response;
    },

    failure(error: AxiosError) {
        const toastStore = useToastStore();
        let content = '';
        switch (error.response?.status) {
        case 401:
            content = `
              <div class="flex items-center gap-4">
                <label class="text-2xl">🙅‍</label>
                <div>
                  <p class="font-semibold text-lg">Action non autorisée</p>
                  <p>Contactez notre support, ou essayez de vous reconnecter.</p>
                </div>
              </div>
            `;

            toastStore.showError({content, timeout: duration});
            break;

        case 403:
            toastStore.showError({content: 'Action non autorisée', timeout: duration});
            break;

        case 500:
            content = `
              <div class="flex items-center gap-4">
                <label class="text-2xl">😅</label>
                <div>
                  <p class="font-semibold text-lg">Oups ! Une erreur est survenue</p>
                  <p>Contactez notre support, nous sommes là pour vous aider !</p>
                </div>
              </div>
            `;

            toastStore.showError({
                content,
                timeout: duration
            });
            break;
        }

        return Promise.reject(error);
    }
};
