<script setup lang="ts">
import letTheMagicHappen from "@/assets/images/let-the-magic-happens.png";

const emit = defineEmits(['file-upload']);
defineProps<{
    showLetTheMagicHappen: boolean;
}>();

/**
 * Open the file upload dialog
 */
function openFileUpload() {
    document.getElementById('input-file')?.click();
}

/**
 * Handle the file upload
 *
 * @param {File} file
 */
function handleFileUpload(file: File | File[]): void {
    const blob = new Blob(Array.isArray(file) ? file : [file]);

    emit('file-upload', blob);
}
</script>

<template>
  <div
    class="flex"
    :class="[
      {'bg-neutral justify-between flex-col mb-4 p-4': !showLetTheMagicHappen},
      {'justify-end': showLetTheMagicHappen}
    ]"
  >
    <div
      v-if="!showLetTheMagicHappen"
      class="flex flex-row"
    >
      <img
        :src="letTheMagicHappen"
        alt="Let the magic happen"
        width="150"
      >
      <div class="p-4">
        <span class="font-bold">Laissez la magie opérer !</span>
        <p>Importez un fichier audio contenant votre entretien pour remplir automatiquement le questionnaire</p>
      </div>
    </div>
    <div class="flex justify-end">
      <p class="text-gray-500 items-end">
        <button
          class="text-primary"
          @click="openFileUpload"
        >
          Remplir automatiquement à partir d’un fichier audio
        </button>
      </p>
    </div>
    <v-file-input
      v-show="false"
      id="input-file"
      accept="audio/*"
      @update:model-value="handleFileUpload"
    />
  </div>
</template>
