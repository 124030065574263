<script setup lang="ts">
import {computed, onMounted, ref} from "vue";
import {SurveyAnswerInstanceIndex} from "@/core/interfaces/survey/Survey";
import {surveyAnswerInstanceApi} from "@/container";
import {MetaData} from "@/core/interfaces/api";
import dayjs from "dayjs";
import CustomerInformation from "@/core/interfaces/CustomerInformation";
import {useRouter} from "vue-router";
import formatHelper from "@/core/helpers/formatHelper";
import InformationPanel from "@/core/components/information-panel/InformationPanel.vue";
import Icon from "@/core/components/icon/Icon.vue";

const surveyInstances = ref<SurveyAnswerInstanceIndex[]>();
const meta = ref<MetaData>();
const isLoading = ref<boolean>(true);
const router = useRouter();

onMounted(() => {
    indexInstances(1);
});

/**
 * Get completed answer instances of current organization.
 */
const indexInstances = (page: number, limit = 15) => {
    isLoading.value = true;
    return surveyAnswerInstanceApi().index({page, limit, filler_type: 'patient_user'})
        .then(response => {
            surveyInstances.value = response.data;
            meta.value = response.meta;
        })
        .finally(() => isLoading.value = false);
};

/**
 * Get age based on customer information.
 */
const age = (customerInformation: CustomerInformation) => {
    const birthdate = dayjs(customerInformation.birth_date);

    return Math.floor(dayjs().diff(birthdate, 'year')) + 'ans';
};

/**
 * Redirect to survey built page.
 */
const showSurveyBuilt = (instance: SurveyAnswerInstanceIndex) => {
    router.push({name: 'customer.survey', params: {customerId: instance.customer?.id, instanceId: instance.id}});
};

/**
 * Headers used for the data table.
 */
const headers = computed(() => {
    return [
        {
            title: 'Patient',
            key: 'name'
        },
        {
            title: 'Résultat du questionnaire',
            key: 'survey-verdict'
        },
        {
            title: 'Complété le',
            key: 'completed_at'
        },
    ];
});

</script>

<template>
  <div>
    <information-panel class="p-6">
      <div class="flex flex-col items-start">
        <h2 class="text-white">
          Questionnaires patient
        </h2>
        <p class="text-neutral">
          Consultez les résultats des questionnaires remplis par vos patients depuis l’application Apodis
        </p>
      </div>
    </information-panel>
    <div class="px-5 pb-5">
      <v-data-table-server
        :items-per-page="meta?.per_page"
        :headers="headers"
        :items="surveyInstances"
        :items-length="meta?.total || 15"
        :loading="isLoading"
        item-value="name"
        @update:options="(options) => indexInstances(options.page, options.itemsPerPage)"
      >
        <template v-slot:item="{ item }">
          <tr class="hover:bg-gray-100 cursor-pointer" @click="showSurveyBuilt(item)">
            <td v-if="item?.customer">{{ item.customer.information.name }} ({{ age(item.customer.information) }})</td>
            <td v-else>-</td>
            <td>
              <div class="flex flex-col">
                <strong>{{ item.survey.name }}</strong>
                <div class="flex flex-row">
                  <img
                    alt="Verdict"
                    src="@/assets/images/verdict-chevrons.png"
                    class="mr-3 w-6 object-contain"
                  >
                  <span v-if="item?.verdict">{{ item.verdict.title }}</span>
                </div>
              </div>
            </td>
            <td v-if="item?.completed_at">{{ formatHelper.readableDate(item.completed_at) }}</td>
            <td>
              <icon name="mdi-chevron-right" color="primary" :size="25" />
            </td>
          </tr>
        </template>
      </v-data-table-server>
    </div>
  </div>
</template>
