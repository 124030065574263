import {OrderEvent} from "../../../order/interfaces/NewOrderEvent";
import PatientDetectedEvent from "./PatientDetectedEvent";
import SharingRequestEvent from "./SharingRequestEvent";

/**
 * LiveItem interface.
 *
 * @property {LiveItemType} type - The live item type.
 * @property {Partial<LiveItemData>} data - The live item data.
 */
interface LiveItem {
    type: LiveItemType;
    data: Partial<any>
}

export enum LiveItemType {
    ORDER_CREATED = "order-created",
    CUSTOMER_DETECTED = "customer-detected",
    SHARING = "sharing.request",
    INTERVIEW_INSTANCE = "interview-instance",
    SURVEY_ANSWER_INSTANCE = "survey-answer-instance",
}

export type LiveItemData = OrderEvent | PatientDetectedEvent | SharingRequestEvent;

export default LiveItem;
