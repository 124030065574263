import {type ClassValue, clsx} from 'clsx';
import {twMerge} from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

/**
 * Filter empty keys from an object.
 * @param obj
 */
export function filterEmptyKeys(obj: Record<string, any>) {
    return Object.fromEntries(
        Object.entries(obj).filter(([_, v]) => v != null && _.length > 0)
    );
}
