export default function (hdsClient) {
    return {
        /**
         * Index carts for the consumer.
         */
        summary(backingId: number, consumerKey: string): Promise<{summary: string}> {
            const params = {consumer_key: consumerKey};
            return hdsClient.get(`backings/${backingId}/summary`, {params})
                .then(response => response.data.data);
        },
        /**
         * Index carts for the consumer.
         */
        pdf(backingId: number, consumerKey: string, summary: string): Promise<Blob> {
            const params = {summary};
            return hdsClient.get(`backings/${backingId}/summary-pdf`,{
                params,
                responseType: 'blob'
            })
                .then(response => response.data);
        }
    };
}
