<script setup lang="ts">
import useLaboratory from "@/laboratory/composables/useLaboratory";
import BaseContainer from "@/core/components/base/BaseContainer.vue";
import useSwr from "@/core/composables/swr/useSwr";
import {laboratoryApi} from "@/container";
import LaboratoryHomeStatisticCard from "@/laboratory/components/LaboratoryHomeStatisticCard.vue";
import {currencyze} from "@/core/filters/Currencyze";
import LaboratoryExtensions from "@/laboratory/components/LaboratoryExtensions.vue";
import LaboratoryKnowledgeBoxButton from "@/laboratory/components/LaboratoryKnowledgeBoxButton.vue";
import {ref} from "vue";
import KnowledgeBoxesModal from "@/laboratory/components/LaboratoryKnowledgeBoxModal.vue";
import {useRouter} from "vue-router";
import dayjs from "dayjs";

const props = defineProps<{
    laboratoryId: string;
}>();

const router = useRouter();

const knowledgeBoxesModalVisibile = ref(false);

const {data: laboratory} = useLaboratory(props.laboratoryId);
const {data: statistics} = useSwr(
    `partnership/patient/laboratories/${props.laboratoryId}/statistics`,
    () => laboratoryApi().patientPartnerStatistics(Number(props.laboratoryId)),
);
</script>

<template>
  <BaseContainer class="p-6 flex flex-col gap-6">
    <LaboratoryKnowledgeBoxButton
      v-if="laboratory?.knowledge_boxes"
      :laboratory-name="laboratory?.name ?? ''"
      @click="knowledgeBoxesModalVisibile = true"
    />
    <div class="flex flex-col lg:flex-row gap-x-16 gap-y-6 items-start">
      <div class="w-full lg:w-auto flex flex-col md:flex-row lg:flex-col gap-6">
        <LaboratoryHomeStatisticCard
          class="flex-1 cursor-pointer"
          :value="(statistics?.consumer_count ?? 0).toString()"
          :label="`Patient${(statistics?.consumer_count ?? 0) > 1 ? 's' : ''}`"
          :sub-label="`${statistics?.today_consumer_count ?? 0} ont acheté aujourd'hui`"
          :color="laboratory?.branding?.primary_color ?? ''"
          @click="router.push({name: 'laboratory.consumers'})"
        />
        <LaboratoryHomeStatisticCard
          class="flex-1 cursor-pointer"
          :value="currencyze(statistics?.total_revenue ?? 0, 0)"
          label="Cette année"
          :sub-label="`Meilleur vendeur : ${statistics?.top_operator?.name ?? statistics?.top_operator?.code ?? 'Aucun'}`"
          :color="laboratory?.branding?.primary_color ?? ''"
          @click="router.push({
            name: 'laboratory.sales',
            query: {since: dayjs().subtract(1, 'year').format('YYYY-MM-DD')}
          })"
        />
      </div>
      <LaboratoryExtensions
        v-if="laboratory?.extensions"
        class="flex-1"
        :extensions="laboratory.extensions"
      />
    </div>
    <KnowledgeBoxesModal
      v-if="laboratory?.knowledge_boxes && knowledgeBoxesModalVisibile"
      knowledgeable-type="laboratory"
      :knowledgeable-id="Number(laboratoryId)"
      :knowledge-boxes="laboratory.knowledge_boxes"
      @close="knowledgeBoxesModalVisibile = false"
    />
  </BaseContainer>
</template>

<style scoped>

</style>
