<script setup lang="ts">
import useSwr from "@/core/composables/swr/useSwr";
import {laboratoryApi} from "@/container";
import BaseContainer from "@/core/components/base/BaseContainer.vue";
import {computed, ref} from "vue";
import {currencyze} from "@/core/filters/Currencyze";
import BaseCard from "@/core/components/base/BaseCard.vue";
import BaseCardHeader from "@/core/components/base/BaseCardHeader.vue";
import BaseCardBody from "@/core/components/base/BaseCardBody.vue";
import dateHelper from "@/core/helpers/dateHelper";
import {useRoute} from "vue-router";
import BaseTag from "@/core/components/base/BaseTag.vue";
import BaseSelect from "@/core/components/base/BaseSelect.vue";
import dayjs from "dayjs";

const route = useRoute();
const props = defineProps<{
    laboratoryId: string;
}>();

const dateOptions = [
    {
        label: "Aujourd'hui",
        value: dayjs().format("YYYY-MM-DD")
    },
    {
        label: "Sur 1 mois",
        value: dayjs().subtract(1, "month").format("YYYY-MM-DD")
    },
    {
        label: "Sur 3 mois",
        value: dayjs().subtract(3, "month").format("YYYY-MM-DD")
    },
    {
        label: "Sur 1 an",
        value: dayjs().subtract(1, "year").format("YYYY-MM-DD")
    },
    {
        label: "Sur 3 ans",
        value: dayjs().subtract(3, "year").format("YYYY-MM-DD")
    }
];

const operator = ref<string | null>((route.query.operator_code as string) ?? null);
const consumer = ref<string | null>((route.query.consumer_key as string) ?? null);
const dateFilter = ref<string>((route.query.since as string) ?? dateOptions[2].value);
const productId = ref<string | null>((route.query.product_id as string) ?? null);
const itemsPerPage = ref(20);
const page = ref(1);
const sortBy = ref<null | { key: string, order: 'asc' | 'desc' }>(null);

const key = computed(() => {
    let params = new URLSearchParams();

    if (operator.value) {
        params.append("operator_code", operator.value);
    }

    if (consumer.value) {
        params.append("consumer_key", consumer.value);
    }

    if (dateFilter.value) {
        params.append("since", dateFilter.value);
    }

    if (sortBy.value) {
        params.append("sort_by", sortBy.value.key);
        params.append("sort_order", sortBy.value.order);
    }

    if (productId.value) {
        params.append("product_id", productId.value);
    }

    params.append("size", itemsPerPage.value.toString());
    params.append("page", page.value.toString());

    return `/laboratories/${props.laboratoryId}/sales${params.toString()}`;
});

const {data: sales, isLoading, isValidating} = useSwr(
    key,
    () => laboratoryApi().sales(Number(props.laboratoryId), {
        operator_code: operator.value ?? undefined,
        consumer_key: consumer.value ?? undefined,
        page: page.value,
        size: itemsPerPage.value,
        sortBy: sortBy.value?.key,
        order: sortBy.value?.order,
        since: dateFilter.value,
        product_id: productId.value ?? undefined
    }),
    {
        revalidateOnFocus: false,
        ttl: 1000 * 60 * 5
    }
);

const headers = [
    {
        title: "Date",
        key: "datetime"
    },
    {
        title: "Vendeur",
        key: "operator",
        sortable: false
    },
    {
        title: "Patient",
        key: "consumer",
        sortable: false
    },
    {
        title: "Produit",
        key: "product"
    },
    {
        title: "Quantité",
        key: "quantity"
    },
    {
        title: "CA TTC",
        key: "revenue"
    }
];

const dataTableItems = computed(() => {
    if (!sales.value?.data) {
        return [];
    }

    return sales.value.data.map((item) => {
        return {
            datetime: dateHelper.longFormat(item.datetime),
            operator: item.operator,
            consumer: item.consumer_key ? item.consumer?.information?.name ?? "Patient Anonyme" : "-",
            product: `${item.product.name} ${item.product.code ? `(${item.product.code})` : ""}`,
            quantity: item.quantity,
            revenue: currencyze(item.revenue)
        };
    });
});

const handleItemPerPageUpdate = (_itemsPerPage: number) => {
    page.value = 1;
    itemsPerPage.value = _itemsPerPage;
};

/**
 * Handle page update.
 *
 * @param _page
 */
const handlePageUpdate = (_page: number) => {
    page.value = _page;
};

/**
 * Handle sort by update.
 *
 * @param _sortBy
 */
const handleSortByUpdate = (_sortBy: { key: string, order: "asc" | "desc" }[]) => {
    page.value = 1;
    sortBy.value = {
        key: _sortBy[0]?.key ?? null,
        order: _sortBy[0]?.order ?? null
    };
};

/**
 * On tag close, reset the operator and consumer filters.
 */
const handleTagClose = () => {
    operator.value = null;
    consumer.value = null;
    productId.value = null;
    page.value = 1;
};
</script>

<template>
  <BaseContainer class="p-6">
    <BaseCard>
      <BaseCardHeader>
        <div class="flex gap-4 items-center">
          <div class="flex items-center gap-4">
            Tableau des ventes
            <BaseTag v-if="operator || consumer || productId" @close="handleTagClose">
              {{ route.query.entity_name }}
            </BaseTag>
          </div>
          <BaseSelect v-model:modelValue="dateFilter" trigger-class="justify-end w-auto" :options="dateOptions" />
        </div>
      </BaseCardHeader>
      <BaseCardBody>
        <v-data-table-server
          :loading="isLoading || isValidating"
          :items-length="sales?.meta.total ?? 0"
          :items="dataTableItems"
          :headers="headers"
          :page="page"
          :items-per-page-options="[10, 25, 50]"
          :items-per-page="itemsPerPage"
          @update:page="handlePageUpdate"
          @update:items-per-page="handleItemPerPageUpdate"
          @update:sort-by="handleSortByUpdate"
        />
      </BaseCardBody>
    </BaseCard>
  </BaseContainer>
</template>

<style scoped>

</style>
