<script setup lang="ts">
import BaseCard from "@/core/components/base/BaseCard.vue";
import {LegacySellinOffer} from "@/core/interfaces/SellinOffer";
import imagesHelper from "@/core/helpers/imagesHelper";

const {offer} = defineProps<{ offer: LegacySellinOffer }>();

const sumPFHT = offer.products.map(p => p.pfht * p.quantity).reduce((all, n) => {
    return all + n;
}, 0);
const sumDiscounted = offer.products.map(p => p.priceAfterDiscountWithoutVat * p.quantity).reduce((all, n) => {
    return all + n;
}, 0);
const discounted = (sumPFHT - sumDiscounted) > 0;
const imageUrl = imagesHelper.getOfferImage(offer);

const productQuantity = (offer: LegacySellinOffer) => {
    return offer.products.map(p => p.quantity).reduce((all, n) => {
        return all + n;
    }, 0);
};
</script>

<template>
  <base-card class="p-4 cursor-pointer hover:opacity-75">
    <div class="flex flex-row">
      <img :src="imageUrl" class="h-32 w-32">
      <div class="flex flex-col flex-1 pl-3">
        <div class="flex flex-row flex-1 items-center justify-between">
          <h2>{{ offer.name }}</h2>
          <p class="text-gray-500">
            {{ $filters.date(offer.startDate, 'dayOnly') }}
          </p>
        </div>
        <p>{{ offer.description }}</p>
        <p>{{ productQuantity(offer) }} produits</p>
        <div v-if="discounted" class="flex flex-row">
          <span class="text-red line-through">{{ sumPFHT.toFixed(2) }}€</span>
          <span class="pl-2 text-green">
            -{{ (((sumPFHT - sumDiscounted) / sumPFHT) * 100).toFixed(0) }}% de remise
          </span>
        </div>
        <div class="flex flex-row justify-end">
          <span>TOTAL: </span>
          <span class="pl-2 text-primary">{{ sumDiscounted.toFixed(2) }}€ HT</span>
        </div>
      </div>
    </div>
  </base-card>
</template>
