import {computed, Ref} from "vue";

const useSwrState = <T>(data: Ref<T>, error: Ref, isValidating: Ref<boolean, boolean>) => {
    /**
     * Determine if the SWR state is loading.
     */
    const isLoading = computed(() => {
        return data.value === undefined && !error.value && isValidating.value;
    });

    return {
        isLoading
    };
};

export default useSwrState;
