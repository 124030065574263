import {nextTick, Ref, ref} from "vue";
import type {Component} from "vue";
import {BroadcastNotificationProps, DetectionNotificationProps} from "@/notification/interfaces";
import notificationService from "@/core/services/notificationService";

const useNotification = (notificationRef: Ref<HTMLElement | null>) => {
    const item = ref<DetectionNotificationProps | BroadcastNotificationProps | null>(null);
    const component = ref<Component | null>(null);

    /**
     * Show the notification window after next tick.
     *
     * @param height
     */
    const showNotification = async (height?: number) => {
        await nextTick();
        const notificationHeight = height || notificationRef.value?.scrollHeight;
        notificationService().showNotification(notificationHeight);
    };

    /**
     * Resize the notification window after next tick.
     */
    const resizeNotification = async () => {
        await nextTick();
        const height = notificationRef.value?.scrollHeight;
        window.ipcRenderer.resizeNotificationWindow(height);
    };

    return {
        notificationRef,
        item,
        component,
        showNotification,
        resizeNotification
    };
};

export default useNotification;
