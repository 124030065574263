<script setup lang="ts">
import {onMounted, ref} from "vue";
import {disruptionApi} from "@/container";
import {LegacySellinOffer} from "@/core/interfaces/SellinOffer";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";
import DisruptionAlertCard from "@/disruption-logistics/components/dirsuption-alert-card/DisruptionAlertCard.vue";
import {useRouter} from "vue-router";

const loading = ref(true);
const offers = ref<LegacySellinOffer[]>([]);
const router = useRouter();

/**
 * On mount, fetch disruption emergency offers.
 */
onMounted(() => {
    disruptionApi().getDisruptionEmergencyOffers()
        .then(newOffers => {
            offers.value = newOffers;
        })
        .finally(() => loading.value = false);
});

/**
 * Action when card is pressed.
 *
 * @param offer
 */
const onCardPress = (offer: LegacySellinOffer) => {
    router.push({name: 'disruption-logistics.disruption-alerts.detail', params: {offerId: offer.id}});

};

</script>

<template>
  <base-flex-spinner v-if="loading" />
  <div v-else>
    <div v-for="offer in offers" :key="offer.id" class="p-4">
      <disruption-alert-card
        :offer="offer"
        @click="onCardPress(offer)"
      />
    </div>
  </div>
</template>
