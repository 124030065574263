<script setup lang="ts">
import Extension from "@/core/interfaces/extension/Extension";
import ExtensionLogo from "@/extension/components/extension-logo/ExtensionLogo.vue";
import useSwr from "@/core/composables/swr/useSwr";
import {extensionApi} from "@/container";
import {computed} from "vue";
import {ShowcaseType} from "@/core/interfaces/showcase";
import Icon from "@/core/components/icon/Icon.vue";
import ExtensionNavigationService from "@/extension/services/ExtensionNavigationService";
import {useRouter} from "vue-router";

const props = defineProps<{
    extension: Extension;
}>();
const router = useRouter();

const {data} = useSwr(
    `extension/${props.extension.id}/showcases`,
    () => extensionApi().showcase(props.extension.id),
    {
        revalidateOnFocus: false
    }
);

const consumerCountShowcase = computed(() => {
    if (!data.value) {
        return null;
    }

    return data.value.find((showcase) => showcase.type === ShowcaseType.CONSUMER_COUNT);
});

const navigateToExtension = () => {
    ExtensionNavigationService.navigate(props.extension, router);
};
</script>

<template>
  <div class="rounded-md overflow-hidden shadow-md flex items-center cursor-pointer" @click="navigateToExtension">
    <ExtensionLogo :extension="extension" class="!h-20" />
    <div v-if="consumerCountShowcase" class="p-2">
      <p>Potentiel</p>
      <div class="flex gap-1">
        <Icon name="mdi-account" />
        <label class="font-content-bold">{{ consumerCountShowcase.value }}</label>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
