<script setup lang="ts">
import BaseSpinner from "@/core/components/base/spinner/BaseSpinner.vue";
import Icon from "@/core/components/icon/Icon.vue";
import BaseButton from "@/core/components/base/BaseButton.vue";

defineProps<{
    loading: boolean;
    recording: boolean;
}>();

defineEmits(['record', 'import']);
</script>

<template>
  <base-button
    :primary="true"
    class="font-bold text-lg relative flex items-center justify-between"
    :class="[
      recording ? '' : 'pr-0'
    ]"
    @click="$emit('record')"
  >
    <div class="flex items-center gap-3 flex-1 pr-2 py-2">
      <div v-if="loading">
        <base-spinner size="button" color="white" />
      </div>
      <icon
        v-else-if="!recording"
        name="mdi-microphone"
        :size="20"
        color="#ffffff"
      />
      <div
        v-else
        class="w-4 h-4 rounded-full bg-red-500"
      />
      <span class="truncate">
        {{ recording ? 'Terminer l\'entretien' : 'Lancer l\'entretien' }}
      </span>
    </div>
    <template v-if="!recording">
      <div class="h-10 w-[1px] bg-white" />

      <v-menu>
        <template #activator="{ props }">
          <div
            class="pl-2 pr-3 py-2 hover:bg-white/10 transition-colors cursor-pointer"
            v-bind="props"
          >
            <Icon name="mdi-chevron-down" color="white" :size="16" />
          </div>
        </template>

        <v-list class="mt-1">
          <v-list-item>
            <BaseButton variant="text" @click="$emit('record')">
              Commencer à enregistrer
            </BaseButton>
          </v-list-item>
          <v-list-item>
            <BaseButton variant="text" @click="$emit('import')">
              Importer un enregistrement
            </BaseButton>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </base-button>
</template>
