import {AxiosInstance} from "axios";
import {Laboratory} from "@/core/interfaces/laboratory/Laboratory";
import Customer from "@/customer/interfaces/Customer";
import Consumer from "@/core/interfaces/consumers/Consumer";

export interface CustomerAnalysisResponse {
    data: {
        customer?: Customer,
        consumer?: Consumer,
        consumer_key?: string,
        units: number,
        revenue: number,
        cart_count: number,
        last_visit: string,
        average_cart_quantity: number,
        average_cart_revenue: number
    }[],
    meta: {
        top_consumers: {
            customer?: Customer,
            consumer?: Consumer,
            consumer_key?: string,
            units: number,
            revenue: number,
            cart_count: number,
            last_visit: string
        }[],
        total_consumer_count: number,
        today_consumers: number,
        average_quantity: number,
        average_price: number,
    }
}

export interface LaboratoryOperatorAnalysis {
    /** The name of the operator or the operator code if it could not be found */
    operator: string;
    operator_code: string;
    revenue: number;
    average_cart_price: number;
    total_quantity: number;
    average_cart_quantity: number;
    sale_histogram: {
        key_as_string: string;
        key: number;
        revenue: number;
    }[]
}

export interface LaboratorySale {
    consumer?: Consumer;
    consumer_key?: string;
    product: {
        name: string;
        code: string;
    },
    datetime: string;
    quantity: number;
    revenue: number;
    operator: string;
}

export interface LaboratoryProductsStatistics {
    product: {
        id: string;
        name: string;
        code: string;
    },
    consumer_count: number;
    revenue: number;
    quantity: number;
    last_sale: string;
}

export default function (client: AxiosInstance, hdsClient: AxiosInstance) {
    return {
        /**
         * Get laboratories that has patient partnership.
         */
        async patientPartnerLaboratories(): Promise<Laboratory[]> {
            return await client.get('/partnership/patient/laboratories')
                .then((response) => {
                    return response.data.data;
                });
        },

        /**
         * Read a laboratory that has patient partnership.
         */
        async patientPartnerLaboratory(laboratoryId: number): Promise<Laboratory> {
            return await client.get(`/partnership/patient/laboratories/${laboratoryId}`)
                .then((response) => {
                    return response.data.data;
                });
        },

        async patientPartnerStatistics(laboratoryId: number): Promise<{
            total_revenue: number,
            consumer_count: number,
            today_consumer_count: number,
            top_operator: { name?: string, code?: string },
        }> {
            return await client.get(`/partnership/patient/laboratories/${laboratoryId}/statistics`)
                .then((response) => {
                    return response.data.data;
                });
        },

        /**
         * Get the analysis of customers for a laboratory.
         */
        async customers(laboratoryId: number, size: number, page: null | number = 1, since: null | string = null, sortBy?: string, order?: string, search?: string | null): Promise<CustomerAnalysisResponse> {
            return await hdsClient.get(`/laboratories/${laboratoryId}/customers/analysis`, {
                params: {
                    size,
                    page,
                    since,
                    sortBy,
                    order,
                    search
                }
            })
                .then((response) => {
                    return response.data;
                });
        },
        /**
         * Get the analysis of operators for a laboratory.
         *
         * @param {number} laboratoryId
         * @param {object} params
         */
        async operatorAnalysis(laboratoryId: number, params: {
            since?: string
        } = {}): Promise<LaboratoryOperatorAnalysis[]> {
            return await client.get(`laboratories/${laboratoryId}/operators/analysis`, {params})
                .then((response) => {
                    return response.data.data;
                });
        },

        /**
         * Get the sales of a laboratory.
         */
        async sales(laboratoryId: number, params: {
            operator_code?: string;
            consumer_key?: string,
            page?: number,
            size?: number,
            sortBy?: string,
            order?: string,
            since?: string,
            product_id?: string
        } = {}): Promise<{
            data: LaboratorySale[],
            meta: { total: number }
        }> {
            return await hdsClient.get(`laboratories/${laboratoryId}/sales`, {params})
                .then((response) => {
                    return response.data;
                });
        },

        /**
         * Get the products statistics for a laboratory.
         */
        async products(
            laboratoryId: number,
            params: {
                page?: number,
                size?: number,
                sort_by?: string,
                order?: string,
                since?: string,
            } = {}
        ): Promise<{ data: LaboratoryProductsStatistics[]; meta: { total: number } }> {
            return await client.get(`laboratories/${laboratoryId}/products/statistics`, {params})
                .then((response) => {
                    return response.data;
                });
        },
    };
}
