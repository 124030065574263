import useSwr from "@/core/composables/swr/useSwr";
import {laboratoryApi} from "@/container";
import {computed, Ref} from "vue";
import dayjs from "dayjs";

/**
 * Composable to get the analysis of customers for a laboratory.
 */
const useLaboratoryConsumerAnalysis = (
    laboratoryId: string,
    size?: Ref<number>,
    page?: Ref<number | null>,
    since?: Ref<string>,
    sortBy?: Ref<null | {
        key: string,
        order: 'asc' | 'desc'
    }>,
    search?: Ref<string | null>
) => {
    const defaultSince = dayjs().subtract(1, "month").format('YYYY-MM-DD');

    const swrKey = computed(() => {
        const _size = `size=${size?.value ?? 20}`;
        const defaultSince = dayjs().subtract(1, "month").format('YYYY-MM-DD');
        const sort = sortBy?.value ? `&sortBy=${sortBy.value.key}&order=${sortBy.value.order}` : "";
        const _page = `&page=${page?.value ?? 1}`;
        const _since = `&since=${since?.value ?? defaultSince}`;

        const _search = (search?.value?.length ?? 0)> 3 ? `&search=${search?.value}` : "";

        return `/laboratories/${laboratoryId}/customers/analysis?${_size}${_since}${sort}${_page}${_search}`;
    });

    const {data, error, isLoading, isValidating} = useSwr(
        swrKey,
        () => laboratoryApi().customers(
            Number(laboratoryId),
            size?.value ?? 20,
            page?.value ?? 1,
            since?.value ?? defaultSince,
            sortBy?.value?.key,
            sortBy?.value?.order,
            (search?.value?.length ?? 0) > 3 ? search?.value : null
        ),
        {
            revalidateOnFocus: false,
            ttl: 1000 * 60 * 5,
            errorRetryCount: 0
        }
    );

    return {
        data,
        error,
        size,
        page,
        isLoading,
        isValidating
    };
};

export default useLaboratoryConsumerAnalysis;
