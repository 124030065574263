<script setup lang="ts">
import {LaboratoryOperatorAnalysis} from "@/core/http/resources/laboratory/LaboratoryResource";
import BaseCard from "@/core/components/base/BaseCard.vue";
import BaseCardHeader from "@/core/components/base/BaseCardHeader.vue";
import BaseCardBody from "@/core/components/base/BaseCardBody.vue";
import {computed} from "vue";
import ChartService from "@/laboratory/services/ChartService";
import {Pie} from "vue-chartjs";


import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    Colors
} from 'chart.js';
import {currencyze} from "@/core/filters/Currencyze";

/**
 * Custom plugin to draw text inside the pie cutout.
 * It will compute the text size to fit the cutout.
 */
const centerTextPlugin = {
    id: 'centerText',
    afterDatasetsDraw(chart) {
        const {ctx} = chart;

        const formattedTotal = currencyze(totalRevenue.value);

        // Get canvas center coordinates
        const centerX = chart.getDatasetMeta(0).data[0].x;
        const centerY = chart.getDatasetMeta(0).data[0].y;

        ctx.save();
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';

        ctx.font = '500 14px Comfortaa';
        ctx.fillStyle = '#6B7280';
        ctx.fillText('Total', centerX, centerY - 15);

        const radius = Math.min(chart.width, chart.height) / 2;
        const cutoutRadius = radius * 0.65;
        const maxWidth = cutoutRadius * 1.5;
        let fontSize = 20;

        while (ctx.measureText(formattedTotal).width > maxWidth && fontSize > 10) {
            fontSize--;
            ctx.font = `600 ${fontSize}px Comfortaa`;
        }

        ctx.fillStyle = '#111827';
        ctx.fillText(formattedTotal, centerX, centerY + 10);

        ctx.restore();
    }
};

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    ArcElement,
    Colors
);

const props = defineProps<{
    data: LaboratoryOperatorAnalysis[];
}>();

const chartData = computed(() => {
    return {
        labels: props.data.map((item) => item.operator),
        datasets: [{
            data: props.data.map((item) => item.revenue),
            backgroundColor: ChartService.colorPalette(),
            borderColor: "white",
            borderWidth: 2
        }]
    };
});

const totalRevenue = computed(() => {
    return props.data.reduce((sum, item) => sum + item.revenue, 0);
});
</script>

<template>
  <BaseCard>
    <BaseCardHeader>
      Parts de marché
    </BaseCardHeader>
    <BaseCardBody class="p-0 h-[200px] relative">
      <Pie
        :plugins="[centerTextPlugin]"
        :data="chartData"
        :options="{
          responsive: true,
          maintainAspectRatio: false,
          cutout: '65%',
          radius: '100%',
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              backgroundColor: 'rgba(255, 255, 255, 1)',
              titleColor: '#1f2937',
              bodyColor: '#1f2937',
              padding: 12,
              borderColor: 'rgba(0, 0, 0, 0.1)',
              borderWidth: 1,
              callbacks: {
                label: (context) => {
                  const value = context.raw as number;
                  const percentage = ((value / totalRevenue) * 100).toFixed(1);
                  const formattedValue = currencyze(value);
                  return `${context.label}: ${formattedValue} (${percentage}%)`;
                }
              }
            }
          }
        }"
      />
    </BaseCardBody>
  </BaseCard>
</template>

<style scoped>
:deep(.chartjs-tooltip) {
    z-index: 10000 !important;
}
</style>
