<template>
  <div
    ref="notificationRef"
    class="flex flex-1 justify-end overflow-x-hidden"
  >
    <component
      :is="component"
      v-if="component"
      :item="item"
      @resize="resizeNotification"
    />
  </div>
</template>

<script lang="ts" setup>
import NotificationBroadcast from "@/notification/components/notification-broadcast/NotificationBroadcast.vue";
import useNotification from "@/notification/composables/useNotification";
import useEnvironment from "@/notification/composables/useEnvironment";
import useCustomerDetectionIpcEvents from "@/notification/composables/useCustomerDetectionIpcEvents";
import {markRaw, onMounted, onUnmounted, ref} from "vue";
import useBroadcastNotification from "@/notification/composables/useBroadcastNotification";
import NotificationDetection from "@/notification/components/notification-detection/NotificationDetection.vue";
import {UserSettings} from "@/settings/interfaces";
import {DetectionPatient} from "@/notification/interfaces";

const notificationRef = ref<HTMLElement | null>(null);
const {component, item, showNotification, resizeNotification} = useNotification(notificationRef);
const environment = useEnvironment();
const hostName = ref<string | null>(null);
const customerDetection = useCustomerDetectionIpcEvents();

/**
 * Callback for handling broadcast notifications.
 *
 * @param data
 * @param height
 */
const handleBroadcastNotification = (data, height) => {
    component.value = markRaw(NotificationBroadcast);
    item.value = data;

    showNotification(height);
};

const broadcast = useBroadcastNotification(
    handleBroadcastNotification
);

onMounted(async () => {
    hostName.value = await window.ipcRenderer.invoke('getHostname');
    window.ipcRenderer.on('extract-patient', async (_, patient: DetectionPatient, settings: UserSettings) => {
        if (!hostName.value) {
            return;
        }

        item.value = await customerDetection.handleDetection(hostName.value, patient, settings);
        component.value = markRaw(NotificationDetection);
        await showNotification();
    });

    window.ipcRenderer.on('reset-patient', (_, patient) => {
        if (!hostName.value) {
            return;
        }

        customerDetection.resetPatient(patient, hostName.value);
    });

    window.ipcRenderer.on('preview-detection', async (_, settings: UserSettings) => {
        item.value = customerDetection.previewItem(settings);
        component.value = markRaw(NotificationDetection);

        await showNotification();
    });

    await environment.loadEnvironment();
    broadcast.subscribe(environment.environment.value?.userId);
});

onUnmounted(() => {
    window.ipcRenderer.removeAllListeners('extract-patient');
    window.ipcRenderer.removeAllListeners('reset-patient');
    window.ipcRenderer.removeAllListeners('preview-detection');
});
</script>
