<template>
  <BaseContainer class="flex flex-col gap-4 px-4 py-2">
    <div v-if="program.knowledge_boxes?.length">
      <ProgramKnowledgeBox :knowledge-boxes="program?.knowledge_boxes" :kid="program.id" type="program" />
    </div>
    <base-flex-spinner v-if="isLoading" />
    <div
      v-if="attachments"
    >
      <program-attachment-grid
        :attachments="attachments"
        :attachable-id="program.id"
        attachable-type="programs"
      />
    </div>
  </BaseContainer>
</template>

<script lang="ts">
import {programAttachmentApi} from '@/container';
import BaseContainer from '@/core/components/base/BaseContainer.vue';
import ProgramAttachmentGrid from '@/program/components/program-attachment/ProgramAttachmentGrid.vue';
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";
import {PropType} from "vue";
import {Program} from "@/program/interfaces/Program";
import ProgramKnowledgeBox from "@/program/components/program-knowledge-box/ProgramKnowledgeBox.vue";

export default {
    name: 'ProgramDetailAttachments',
    components: {ProgramKnowledgeBox, BaseFlexSpinner, ProgramAttachmentGrid, BaseContainer},
    props: {
        program: {
            type: Object as PropType<Program>,
            required: true
        }
    },
    data() {
        return {
            isLoading: true,
            attachments: null
        };
    },
    watch: {
        'program': {
            immediate: true,

            handler(value: Program) {
                this.index(value.id);
            }
        }
    },
    methods: {
        /**
         * Get the attachment index for the given program.
         *
         * @param {Number} programId - The program id.
         */
        index(programId: number) {
            programAttachmentApi()
                .attachmentIndex('programs', programId)
                .then(attachments => {
                    this.attachments = attachments;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        /**
         * Extract the sections from the given attachments.
         *
         * @param {Array} attachments - The attachments to extract the sections from.
         *
         * @return {Array} The unique attachments sections.
         */
        extractSections(attachments) {
            return attachments.reduce((sections, attachment) => {
                const attachmentSections = attachment.sections;

                attachmentSections.forEach(attachmentSection => {
                    const section = sections.find(_section => _section.id === attachmentSection.id);

                    if (!section) {
                        sections.push(attachmentSection);
                    }
                });

                return sections;
            }, []);
        },
        /**
         * Get the attachments for the given section.
         *
         * @param {Array} attachments - The attachments to filter.
         * @param {Object} section - The section to filter the attachments for.
         *
         * @return {Array} The attachments for the given section.
         */
        attachmentsForSection(attachments, section) {
            return attachments.filter(attachment => {
                return attachment.sections.find(_section => _section.id === section.id);
            });
        },
        /**
         * Get the attachment without section.
         *
         * @param {Array} attachments - The attachments to filter.
         *
         * @return {Array} The attachments without section.
         */
        attachmentsWithoutSection(attachments) {
            return attachments.filter(attachment => {
                return attachment.sections.length === 0;
            });
        }
    }
};
</script>
