import {AxiosInstance} from "axios";
import {
    DisruptionLogisticsRequest,
    ProductAvailability, ProductAvailabilitySource
} from "@/disruption-logistics/interfaces";
import {
    AvailabilityProviderData,
    ProductAvailabilityStatistics
} from "@/disruption-logistics/interfaces/availability-providers";
import {HealthcenterGroup, HealthcenterGroupInvitation} from "@/disruption-logistics/interfaces/healthcenter-groups";

export const AVAILABILITY_PROVIDERS = {
    medidestock: 'medidestock',
    leComptoirDesPharmacies: 'le-comptoir-des-pharmacies',
    healthcenterGroups: 'healthcenter-groups',
    direct: 'direct',
    healthcenterGroupsDistributor: 'healthcenter-groups-distributor'
};

export default function (apiClient: AxiosInstance) {
    /**
     * Get the availability of a product
     *
     * @param {string} provider The provider to use.
     * @param {number} productId The product id to check.
     * @param {string} requestToken The request token to use.
     *
     * @returns {Promise<{
     *   is_available: boolean,
     *   messages: string[]|null
     * }>}
     */
    const productAvailability = async (provider: string, productId: number | string, requestToken: string): Promise<AvailabilityProviderData> => {
        const response = await apiClient.get(`/disruption-logistics/availability/${provider}/products/${productId}`, {
            headers: {
                'X-Request-Token': requestToken
            }
        });

        return response.data.data;
    };

    /**
     * Get the healthcenter groups.
     *
     * @returns {Promise<HealthcenterGroup[]>}
     */
    const healthcenterGroups = () => {
        return apiClient.get('disruption-logistics/healthcenter-groups')
            .then(response => response.data.data);
    };

    /**
     * Read a healthcenter group.
     *
     * @param {Number} healthcenterGroupId The healthcenter group id to read.
     */
    const readHealthcenterGroup = async (healthcenterGroupId: number): Promise<HealthcenterGroup> => {
        return apiClient.get(`disruption-logistics/healthcenter-groups/${healthcenterGroupId}`)
            .then(response => response.data.data);
    };

    /**
     * Get all the group members of all the healthcenter groups.
     *
     * @return {Promise<HealthcenterGroupMemberWithWarehouses[]>}
     */
    const indexGroupsMembers = () => {
        return apiClient.get('disruption-logistics/healthcenter-groups/members')
            .then(response => response.data.data);
    };

    /**
     * Get the healthcenter group invitations received.
     */
    const receivedHealthcenterGroupInvitations = async (invitationStatus: string): Promise<HealthcenterGroupInvitation[]> => {
        const params: { status?: string } = {};

        if (invitationStatus) {
            params.status = invitationStatus;
        }

        return apiClient.get('disruption-logistics/healthcenter-groups/invitations/received', {params})
            .then(response => response.data.data);
    };

    /**
     * Get the healthcenter group invitations sent.
     *
     * @param {Number} groupId The healthcenter group id to get the sent invitations for.
     * @param {string} invitationStatus The invitation status to filter on.
     *
     * @returns {Promise<HealthcenterGroupInvitation[]>}
     */
    const sentHealthcenterGroupInvitations = (groupId, invitationStatus) => {
        const params: any = {};

        if (invitationStatus) {
            params.status = invitationStatus;
        }

        return apiClient.get(`disruption-logistics/healthcenter-groups/${groupId}/invitations/sent`, {params})
            .then(response => response.data.data);
    };

    /**
     * Accept or reject an invitation
     */
    const handleInvitation = async (invitationId: number, accepted: boolean): Promise<HealthcenterGroupInvitation> => {
        const url = `disruption-logistics/healthcenter-groups/invitations/${invitationId}/${accepted ? 'accept' : 'reject'}`;

        return apiClient.patch(url)
            .then(response => response.data.data);
    };

    /**
     * Create an invitation
     */
    const createInvitation = async (healthcenterGroupId: number, email: string, healthcenterId: number): Promise<HealthcenterGroupInvitation[]> => {
        const body = {
            email,
            healthcenter_id: healthcenterId
        };

        return apiClient.post(`disruption-logistics/healthcenter-groups/${healthcenterGroupId}/invitations`, body)
            .then(response => {
                return response.data.data[0] ?? null;
            });
    };

    /**
     * Delete a healthcenter group invitation
     *
     * @param groupId The healthcenter group id
     * @param invitationId The invitation id
     *
     * @returns {Promise<{
     *   deleted: boolean
     * }>}
     */
    const deleteHealthcenterGroupInvitation = (groupId, invitationId) => {
        return apiClient.delete(`disruption-logistics/healthcenter-groups/${groupId}/invitations/${invitationId}`)
            .then(response => response.data.data);
    };

    /**
     * Delete a healthcenter from a healthcenter group.
     *
     * @param {Number} healthcenterGroupId The healthcenter group that the healthcenter belongs to.
     * @param {Number} healthcenterId The healthcenter to delete.
     *
     * @return {Promise<{
     *   deleted: boolean
     * }>}
     */
    const deleteHealthcenterGroupMember = (healthcenterGroupId, healthcenterId) => {
        return apiClient.delete(`disruption-logistics/healthcenter-groups/${healthcenterGroupId}/healthcenters/${healthcenterId}`)
            .then(response => response.data.data);
    };

    /**
     * Create a healthcenter group
     *
     * @param {{name: string; description: string; invited_emails: string[]}} data
     *   The data to create the healthcenter group with.
     *
     * @return {Promise<HealthcenterGroup>}
     */
    const createHealthcenterGroup = (data) => {
        return apiClient.post('disruption-logistics/healthcenter-groups', data)
            .then(response => response.data.data);
    };

    /**
     * Delete a healthcenter group
     *
     * @param healthcenterGroupId
     * @return {Promise<{deleted: boolean}>}
     */
    const deleteHealthcenterGroup = (healthcenterGroupId) => {
        return apiClient.delete(`disruption-logistics/healthcenter-groups/${healthcenterGroupId}`)
            .then(response => response.data.data);
    };

    /**
     * Edit a healthcenter group
     */
    const editHealthcenterGroup = async (healthcenterGroupId: number, data: {
        name: string;
        description: string
    }): Promise<HealthcenterGroup> => {
        return apiClient.patch(`disruption-logistics/healthcenter-groups/${healthcenterGroupId}`, data)
            .then(response => response.data.data);
    };

    /**
     * Get the statistics of the product availability
     */
    const productAvailabilityStatistics = async (productId: number, requestToken: string): Promise<ProductAvailabilityStatistics> => {
        return apiClient.get(`disruption-logistics/products/${productId}/availabilities/statistics`, {
            headers: {
                'X-Request-Token': requestToken
            }
        })
            .then(response => response.data.data);
    };

    /**
     * Get availabilities that are available again scoped on the healthcenter ranked warehouses if applicable.
     */
    const productsAvailableAgain = async (): Promise<ProductAvailability[]> => {
        return apiClient.get('availabilities/available-again')
            .then(response => response.data.data);
    };

    /**
     * Create a request for a product
     *
     * @param productId The product id
     */
    const createRequest = async (productId: number): Promise<DisruptionLogisticsRequest> => {
        return apiClient.post(`disruption-logistics/requests/${productId}`)
            .then(response => response.data.data);
    };

    /**
     * Get the remaining requests of the user
     *
     * @return {Promise<{remaining: number; subscribed: boolean}>}
     */
    const remainingRequests = (): Promise<{ remaining: number; subscribed: boolean }> => {
        return apiClient.get('disruption-logistics/requests/remaining')
            .then(response => response.data.data);
    };

    /**
     * Get the availability sources
     */
    const availabilitySources = async (params = {}): Promise<ProductAvailabilitySource[]> => {
        return apiClient.get('availabilities/sources', {params})
            .then(response => response.data.data);
    };

    /**
     * Get availabilities for a source
     */
    const availabilities = async (sourceType: 'depositary' | 'warehouse', sourceId: number): Promise<ProductAvailability[]> => {
        return await apiClient.get('availabilities', {
            params: {
                source_type: sourceType,
                source_id: sourceId
            }
        })
            .then(response => response.data.data);
    };

    /**
     * Get the availability history
     */
    const availabilityHistory = async (availabilityId: number): Promise<ProductAvailability> => {
        return apiClient.get(`availabilities/${availabilityId}`)
            .then(response => response.data.data);
    };

    /**
     * Get the partner laboratories
     */
    const partnerLaboratories = async (): Promise<{ id: number; name: string; color?: string }[]> => {
        return apiClient.get('availabilities/partners')
            .then(response => response.data.data);
    };

    /**
     * Leave a healthcenter group
     */
    const leaveHealthcenterGroup = async (healthcenterGroupId: number): Promise<void> => {
        return apiClient.delete(`disruption-logistics/healthcenter-groups/${healthcenterGroupId}/leave`)
            .then(response => response.data.data);
    };

    return {
        productAvailability,
        receivedHealthcenterGroupInvitations,
        handleInvitation,
        healthcenterGroups,
        readHealthcenterGroup,
        createInvitation,
        sentHealthcenterGroupInvitations,
        deleteHealthcenterGroupInvitation,
        deleteHealthcenterGroupMember,
        indexGroupsMembers,
        createHealthcenterGroup,
        deleteHealthcenterGroup,
        productAvailabilityStatistics,
        createRequest,
        remainingRequests,
        productsAvailableAgain,
        availabilitySources,
        availabilities,
        availabilityHistory,
        editHealthcenterGroup,
        leaveHealthcenterGroup,
        partnerLaboratories
    };
}
