/**
 * The notification helper.
 *
 * @author Thomas Denneulin <t.denneulin@apodispharma.com>
 * @returns {Object} The public object.
 */
export default {
    /**
     * Generate a route from a notification.
     *
     * @param {Object} notification - The notification object.
     * @returns {Object|null} The generated route.
     */
    generateRoute(notification) {
        switch (notification.type) {
        case 'sellinOffer':
            return {
                name: 'brand.offer.sellin',

                params: {
                    laboratoryId: notification.data.laboratory_id,
                    sellinOfferId: notification.data.resource_id
                }
            };
        case 'sellinChallenge':
            return {
                name: 'brand.offer.sellin.challenge',

                params: {
                    laboratoryId: notification.data.laboratory_id,
                    sellinChallengeId: notification.data.resource_id
                }
            };
        case 'event':
            return {
                name: 'brand.event',

                params: {
                    laboratoryId: notification.data.laboratory_id,
                    eventId: notification.data.resource_id
                }
            };

        case 'laboratoryDirectOffer':
            return {
                name: 'brand.need.list',

                params: {
                    laboratoryId: notification.data.resource_id
                }
            };

        case 'surveyCompleted':
            return {
                name: 'patient-administration.surveys'
            };

        default:
            return null;
        }
    }
};
