import type Pathology from "../../pathology/interfaces/Pathology";
import {OrganizationType} from "@/core/interfaces/organization/Organization";
import type {FileManagerFile} from "@/core/interfaces/FileManagerFile";
import {ProgramBookable} from "@/program/interfaces/ProgramBookable";
import {Backing} from "@/backing/interfaces/backing";
import {Term} from "@/customer/interfaces/term";
import {KnowledgeBox} from "@/knowledge-box/interfaces";

export interface Program {
    /** The program id. */
    id: number;
    /** The programmable type. */
    programmable_type: string;
    /** The programmable id. */
    programmable_id: number;
    /** The program name. */
    name: string;
    /** The program description. */
    description: string;
    /** The program user communication text. */
    user_communication?: string;
    /** The program onboarding communication text. */
    onboarding_communication?: string;
    /** The program heading. */
    short_description?: string;
    /** Program teaser. */
    teaser?: string;
    /** Program additional information. */
    additional_information?: string;
    /** The teaser file. */
    file: FileManagerFile;
    /** The teaser logo. */
    logo: FileManagerFile;
    /** The start date. */
    start_at: string;
    /** The end date. */
    end_at: string;
    /** The enabled state. */
    enabled: boolean;
    /** The program policy. */
    policy: string;
    /** The backing ids **/
    backing_ids: [];
    /** The organization */
    organization: {
        id: number;
        entity_id: number;
        logo: string;
        entity_type: OrganizationType;
    };
    /** Number of attachments linked to the program. */
    attachments_count?: number;
    /**
     * The knowledge boxes associated with the program.
     */
    knowledge_boxes?: KnowledgeBox[];
    /** The program bookable object. */
    bookable?: ProgramBookable;
    /** The program pathway id. */
    pathway_id?: number;
    /** The next_step if pathway exists */
    next_step?: SupportProgramPathwayStep;
    /** The program user feedback. */
    feedbacks?: string;
    /** The program attachments. */
    attachments?: ProgramAttachment[];
    /** The program backings. */
    backings?: Backing[];
    /** The programmable */
    programmable?: SupportProgram;
}

/** The program snapshot interface. */
export type ProgramSnapshot = Program & SupportProgramPatient;

export interface ProgramBundle {
    /** The program bundle id. */
    id: number;
    /** The program bundle name. */
    name: string;
    /** The program bundle logo. */
    logo: FileManagerFile;
    /** The program bundle description. */
    description: string;
    /** The program bundle programs. */
    programs: (Program & { consumer_count: number })[];
}

/**
 * @interface SupportProgram
 *
 * @property {number} id - The program id.
 * @property {Program} program - The parent program data.
 */
export interface SupportProgram {
    id: number;
    program: Program;
    statistics: SupportProgramStatistics;
    meta: SupportProgramMeta;
    /** The support program pathway summary.*/
    pathway: SupportProgramPathway;
    /** The support program consumer settings. */
    settings: SupportProgramConsumerSettings;
    /** The support program advices. */
    advices: SupportProgramAdvice[];
    /** The support program terms. */
    terms: Term[];
}

export interface SupportProgramPathway {
    /** The pathway id. */
    id: number;
    /** The support program id. */
    support_program_id: number;
    /** The pathway next step. */
    next_step: SupportProgramPathwayStep;
    /** The number of steps. */
    steps_count: number;
    /** The pathway steps. */
    steps: SupportProgramPathwayStep[];
    /** The pathway description. */
    description: string;
}

export interface SupportProgramPathwayStep {
    /** The step id. */
    id: number;
    /** The pathway id. */
    pathway_id: number;
    /** The step index. */
    index: number;
    /** The step image file. */
    image: FileManagerFile;
    /** The step type. */
    type: "survey" | "guidance" | "aftercare" | "survey-bundle";
    /**
     * The step steppable.
     * TODO: Steppable à définir correctement, any utilisé pour fixer une erreur de build du à l'absence la propriété.
     */
    steppable: any;
    /** The step title. */
    title: string;
    /** The step description. */
    description: string;
    /** The step instructions. */
    instructions: string;
    /** The step lead */
    lead: string;
    resolved: {
        /** Resolved lead. */
        lead: string;
        /** Resolved description. */
        description: string;
        /** Resolved action name. */
        action: string;
    };
}

/**
 * @interface SupportProgram
 *
 * @property {number} id - The program id.
 * @property {SupportProgram} support_program - The parent support program.
 * @property {string} counter_notifications - The counter notifications setting.
 * @property {boolean} pathology_information - The pathology information setting.
 * @property {Pathology} pathology - The related pathology.
 */
export interface SupportProgramSettings {
    id: number;
    support_program: SupportProgram;
    counter_notifications: string;
    pathology_information: boolean;
    pathology: Pathology;
}

/**
 * The support program meta data.
 *
 * @property {number} page - The page.
 * @property {number} size - The size.
 * @property {number} total - The total.
 * @property {object} included - The included group labels.
 * @property {object} excluded - The excluded group labels.
 */
export interface SupportProgramMeta {
    /** The requested page number */
    page: number;
    /** The number of items per page */
    size: number;
    /** The total number of items */
    total: number;
    /** The support program labels */
    labels: {
        [key: string]: string
    }
    /** The included group labels */
    included: {
        [key: string]: string
    },
    /** The excluded group labels */
    excluded: {
        [key: string]: string
    },
    /** The not excluded group labels */
    not_excluded: {
        [key: string]: string
    },
    /** The support program options */
    options: {
        hideGroups: boolean,
        minimumVisits: number,
    },
    /** The support program pathway progress trackers */
    progress?: string[]
    /** Whether the API response was degraded because of the extension not accessible for the user.*/
    degraded?: boolean;
}

/**
 * @interface SupportProgramStatistics
 *
 * @property {string} mode - The statistics display mode.
 * @property {string} danger - Whether to display warnings.
 * @property {SupportProgramCategory} included - The inclusion groups.
 * @property {SupportProgramCategory} excluded - The exclusion groups.
 */
export interface SupportProgramStatistics {
    mode: string;
    danger: string;
    included: SupportProgramCategory;
    excluded: SupportProgramCategory;

    [key: string]: SupportProgramCategory | string | undefined;
}

/**
 * @interface SupportProgramCategory
 *
 * @property {string} label - The general label.
 * @property {string} short - The short label.
 * @property {number} total - The total.
 * @property {number} ratio - The ratio.
 * @property {number} intersection - Part of the patients that are out of the opposite group.
 * @property {any} key - The statistics groups.
 */
export interface SupportProgramCategory {
    label: string;
    short: string;
    total: number;
    ratio: number;
    intersection: number;

    [key: string]: SupportProgramGroup | number | string | undefined;
}

/**
 * @interface SupportProgramGroup
 *
 * @property {string} label - The group label.
 * @property {string} description - The group description.
 * @property {number} count - The group count.
 * @property {any} excluded - The group exclusions count.
 */
export interface SupportProgramGroup {
    label: string;
    description: string;
    count: number;
    excluded: {
        total: number;
        [key: string]: number;
    };
}

/**
 * @interface SupportProgramPatient
 *
 * @property {string} key - The consumer key.
 * @property {string} name - The consumer name.
 * @property {string} birthdate - The consumer birthdate.
 * @property {object} groups - The consumer groups.
 */
export interface SupportProgramPatient {
    key: string;
    last_visits: {
        included: string;
        excluded: string;

        [key: string]: string;
    };
    name: string;
    birthdate: string;
    /** The patient phone number. */
    phone: string | null;
    groups: {
        included: SupportProgramPatientGroup[],
        excluded: SupportProgramPatientGroup[]
    };
    /** @property {object} pathway - The consumer pathway if any **/
    pathway: {
        current: {
            repetition: number;
            step: number;
        },
        progress: any[]
    } | null;
    tracker?: {
        columns: {
            label: string;
            value?: string | number
        }[]
    };
    /** The support program meta. */
    meta: SupportProgramMeta;
}

/**
 * Support Program Patient Group
 *
 * @property {string} slug - The group slug.
 * @property {number} visits - The number of consumer visits for that group.
 * @property {string} last_visit - The last consumer visit date.
 * @property {SupportProgramPatientSale[]} sales - The patient sales.
 */
export interface SupportProgramPatientGroup {
    slug: string;
    visits: number;
    last_visit: string;
    sales?: SupportProgramPatientSale[];
}

/**
 * Support program patient sale interface.
 *
 * @property {string} name - The product name.
 * @property {string} cip - The comma separated product cips.
 * @property {string} date - The sale date.
 */
export interface SupportProgramPatientSale {
    name: string;
    date: string;
    cip?: string;
    quantity?: number;
    price?: number;
}

/**
 * SupportProgramMarketStatistic interface.
 *
 * @property {SupportProgramMarketStatisticType} type - The statistic type.
 * @property {string} label - The statistic label.
 * @property {number} value - The statistic value.
 * @property {string} [extra] - The extra statistic label.
 */
export interface SupportProgramMarketStatistic {
    type: SupportProgramMarketStatisticType;
    label: string;
    value: number | string | [];
    extra?: string;
    color?: string;
}

/**
 * SupportProgramAdvice interface.
 *
 * @property {string} slug - The advice slug.
 * @property {string} description - The advice description.
 * @property {ProductAdvice[]} advices - The advices.
 *
 * TODO: Add a type to the support program advice, and make different advice interface for each type.
 */
export interface SupportProgramAdvice {
    slug: string;
    description: string;
    advices: ProductAdvice[];
}

/**
 * ProductAdvice interface.
 *
 * @property {string[]} advices - The product advices as string.
 * @property {string} background_color - The background color (RGBA).
 * @property {string} color - The text color (RGBA).
 * @property {string} image - The image url.
 * @property {string} name - The product name.
 * @property {number[]} products - The product CIPs.
 * @property {string|null} purchased_at - The purchase date.
 */
export interface ProductAdvice {
    /** The product advices content. */
    advices: string;
    /** The background color. */
    background_color: string;
    /** The text color. */
    color: string;
    /** The image url. */
    image: string;
    /**
     * The linked product price.
     */
    price: number | null;

    /** The product advice name. */
    name: string;
    /** The product CIPs. */
    products: number[];
    /** The purchase date. */
    purchased_at: string | null;
    /** The advice sub title. */
    sub_title: string | null;
    /** The advice images. */
    images: string[];
}

/**
 * Support program market statistic type enum.
 */
export enum SupportProgramMarketStatisticType {
    SALE = "sale",
    CONSUMER = "consumer",
    CONSUMER_SALE = "consumer-sale",
    COVERAGE = "coverage",
    KPI = "kpi",
    PIE_CHART = "pie-chart",
    BINOMIAL_PIE_CHART = "binomial-pie-chart",
}

/**
 * Program types enum.
 */
export enum ProgramType {
    SUPPORT = "support",
    DISCOUNT = "discount",
    LOYALTY_CARD = "loyalty-card",
    FEATURE = "feature"
}

/**
 * ProgramAttachmentSection interface
 *
 * @property {string} description - The section description
 * @property {number} id - The section id
 * @property {string} title - The section title
 */
export interface ProgramAttachmentSection {
    /** The section description */
    description: string,
    /** The section id. */
    id: number,
    /** The section title. */
    title: string
}

/**
 * ProgramAttachment interface
 */
export interface ProgramAttachment {
    /** The program attachment description. */
    description: string,
    /** The program attachment external link. */
    external_link: string,
    /** The program attachment entity types. */
    entity_types: { slug: string }[],
    /** The program attachment has thumbnail logic value. */
    has_thumbnail: boolean,
    /** The program attachment id. */
    id: number,
    /** The program attachment sections. */
    sections: ProgramAttachmentSection[],
    /** The program attachment title. */
    title: string,
    /** The program attachment type. */
    type: string
    /** Whether the attachment is relevant for current consumer. */
    relevant: boolean;
    /** The attachement related product sale, if any. */
    related_sale: {
        /** The sale date & time. */
        datetime: string;
        /** The sale product. */
        product: {
            /** The product presentation id. */
            presentation: number;
        },
        item: {
            /** The product name. */
            name: string;
        }
    }
}

export interface SupportProgramConsumerSettings {
    /** The consumer settings id. */
    id: number;
    /** Whether the consumer has been manually disabled from the support program. */
    disabled: boolean;
    /** The inclusion options. */
    options: {
        [key: string]: number | boolean | string;
    },
    /** The unserialized options. */
    unserializedOptions: {
        [key: keyof SupportProgramConsumerSettings["options"]]: UnserializedSupportProgramConsumerSettings;
    }
}

export interface UnserializedSupportProgramConsumerSettings {
    /** The heading to display. */
    heading?: string;
    /** The label to display. */
    label?: string;
    /** The secure file to display. */
    secure_file?: string;
}
