<script setup lang="ts">
import useProductAvailabilityPartners from "@/disruption-logistics/composables/useProductAvailabilityPartners";

defineProps<{
    selectedPartner: number | undefined | "others";
}>();

defineEmits<{
    (e: 'onFilterClick', {include, exclude}: { value: number | "others", include?: number, exclude?: number[] }): void;
}>();

const {data, isLoading} = useProductAvailabilityPartners();
</script>

<template>
  <div class="flex gap-x-3">
    <template v-if="isLoading">
      <v-skeleton-loader
        v-for="index in [1,2,3]" :key="index" :loading="true" class="m-0 bg-transparent rounded-xl"
        width="100"
        height="30"
      />
    </template>
    <template v-else>
      <div
        v-for="partner in data" :key="partner.id"
        class="h-[30px] bg-white flex items-center px-4 rounded-xl cursor-pointer"
        :class="{
          'bg-primary text-white font-bold': selectedPartner === partner.id
        }"
        @click="$emit('onFilterClick', {value: partner.id, include: partner.id})"
      >
        <p>{{ partner.name }}</p>
      </div>
      <div
        v-if="data?.length"
        class="h-[30px] bg-white flex items-center px-4 rounded-xl cursor-pointer"
        :class="{
          'bg-primary text-white font-bold': selectedPartner === 'others'
        }"
        @click="$emit('onFilterClick', {value: 'others', exclude: data?.map(p => p.id)})"
      >
        <p>Autres</p>
      </div>
    </template>
  </div>
</template>

<style scoped>
:deep(.v-skeleton-loader__chip) {
    margin: 0;
}
</style>
