import {AxiosInstance, AxiosResponse} from "axios";
import {PaginatedData} from "@/core/interfaces/api";
import {Task} from "@/core/interfaces/Task";

/**
 * The task resource.
 *
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client: AxiosInstance) {
    return {
        /**
         * Get laboratory product prices
         *
         * @returns {Promise<PaginatedData<any[]>>}
         */
        async index(limit?: number): Promise<PaginatedData<Task[]>> {
            return client.get(`/tasks`, {
                params: {
                    limit
                }
            })
                .then((response: AxiosResponse) => response.data);
        },
        /**
         * Get laboratory product prices
         *
         * @returns {Promise<PaginatedData<any[]>>}
         */
        async update(taskId: number, completed_at: string): Promise<Task> {
            return client.patch(`/tasks/${taskId}`, {
                data: {
                    completed_at
                }
            })
                .then((response: AxiosResponse) => response.data);
        },
    };
};
