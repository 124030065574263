<script setup lang="ts">
import {laboratoryApi} from "@/container";
import useSwr from "@/core/composables/swr/useSwr";
import BaseCard from "@/core/components/base/BaseCard.vue";
import BaseCardHeader from "@/core/components/base/BaseCardHeader.vue";
import BaseCardBody from "@/core/components/base/BaseCardBody.vue";
import {computed, ref} from "vue";
import dayjs from "dayjs";
import BaseSelect from "@/core/components/base/BaseSelect.vue";
import {useRouter} from "vue-router";

const props = defineProps<{
    laboratoryId: string;
}>();

const router = useRouter();

const dateOptions = [
    {
        label: "Aujourd'hui",
        value: dayjs().format("YYYY-MM-DD")
    },
    {
        label: "Sur 1 mois",
        value: dayjs().subtract(1, "month").format("YYYY-MM-DD")
    },
    {
        label: "Sur 3 mois",
        value: dayjs().subtract(3, "month").format("YYYY-MM-DD")
    },
    {
        label: "Sur 1 an",
        value: dayjs().subtract(1, "year").format("YYYY-MM-DD")
    },
    {
        label: "Sur 3 ans",
        value: dayjs().subtract(3, "year").format("YYYY-MM-DD")
    }
];

const page = ref(1);
const itemsPerPage = ref(20);
const dateFilter = ref(dateOptions[2].value);
const sortBy = ref<null | { key: string, order: 'asc' | 'desc' }>(null);

const key = computed(() => {
    let params = new URLSearchParams();
    params.append("page", String(page.value));
    params.append("size", String(itemsPerPage.value));

    if (sortBy.value) {
        params.append("sortBy", sortBy.value.key);
        params.append("sortOrder", sortBy.value.order);
    }

    if (dateFilter.value) {
        params.append("since", dateFilter.value);
    }

    return `laboratory/${props.laboratoryId}/products/analysis` + params.toString();
});

const {data, isLoading, isValidating} = useSwr(
    key,
    () => laboratoryApi().products(Number(props.laboratoryId), {
        size: itemsPerPage.value,
        page: page.value,
        sort_by: sortBy.value?.key ?? undefined,
        order: sortBy.value?.order ?? undefined,
        since: dateFilter.value ?? undefined
    }),
    {
        revalidateOnFocus: false,
        ttl: 1000 * 60 * 5
    }
);

const headers = [
    {
        title: "Produit",
        key: "name",
        sortable: false
    },
    {
        title: "CA TTC",
        key: "revenue"
    },
    {
        title: "Unités achetées",
        key: "quantity"
    },
    {
        title: "Clients",
        key: "consumer_count"
    },
    {
        title: "Dernier achat",
        key: "last_sale"
    },
];

const dataTableItems = computed(() => {
    return data.value?.data.map(item => {
        return {
            id: item.product.id,
            name: item.product.name,
            code: item.product.code,
            revenue: item.revenue,
            last_sale: item.last_sale,
            quantity: item.quantity,
            consumer_count: item.consumer_count
        };
    }) ?? [];
});

/**
 * Handle item per page update.
 *
 * @param _itemsPerPage
 */
const handleItemPerPageUpdate = (_itemsPerPage: number) => {
    page.value = 1;
    itemsPerPage.value = _itemsPerPage;
};

/**
 * Handle page update.
 *
 * @param _page
 */
const handlePageUpdate = (_page) => {
    page.value = _page;
};

/**
 * Handle sort by update.
 *
 * @param _sortBy
 */
const handleSortByUpdate = (_sortBy: { key: string, order: "asc" | "desc" }[]) => {
    page.value = 1;
    sortBy.value = {
        key: _sortBy[0].key ?? null,
        order: _sortBy[0].order ?? null
    };
};

const handleClickRow = (event: PointerEvent, row) => {
    router.push({
        name: 'laboratory.sales',
        query: {
            product_id: row.item.id,
            entity_name: row.item.name,
            since: dateFilter.value
        }
    });
};
</script>

<template>
  <div class="p-6">
    <BaseCard>
      <BaseCardHeader>
        <div class="flex gap-4 items-center">
          Top produits
          <BaseSelect v-model:modelValue="dateFilter" trigger-class="justify-end w-auto" :options="dateOptions" />
        </div>
      </BaseCardHeader>
      <BaseCardBody>
        <v-data-table-server
          :items="dataTableItems"
          :headers="headers"
          :items-length="data?.meta?.total ?? 0"
          :page="page"
          :items-per-page="itemsPerPage"
          :loading="isLoading || isValidating"
          :hover="true"
          @update:items-per-page="handleItemPerPageUpdate"
          @update:page="handlePageUpdate"
          @update:sort-by="handleSortByUpdate"
          @click:row="handleClickRow"
        >
          <template #[`item.name`]="{ item }">
            <div>
              <p class="text-primary font-bold">
                {{ item.name }}
              </p>
              <p class="text-sm text-gray-500">
                {{ item.code }}
              </p>
            </div>
          </template>
        </v-data-table-server>
      </BaseCardBody>
    </BaseCard>
  </div>
</template>

<style scoped>

</style>
