import {BroadcastNotificationProps} from "@/notification/interfaces";
import {BROADCAST_NOTIFICATION_HEIGHT} from "@/notification/components/notification-broadcast/constants";

const useBroadcastNotification = (
    onNotification: (data: BroadcastNotificationProps, height: number) => void
) => {

    /**
     * Subscribe to the broadcast notification channel.
     *
     * @param userId
     */
    const subscribe = (userId: number | undefined) => {
        if (!userId) {
            return;
        }

        const channel = 'Illuminate\\Notifications\\Events\\BroadcastNotificationCreated';
        window.ipcRenderer.send('socket:subscribe', `private-user.${userId}`);
        window.ipcRenderer.send('socket:listen', channel);

        window.ipcRenderer.on('socket:event-received.' + channel, (_, data: BroadcastNotificationProps) => {
            onNotification(data, BROADCAST_NOTIFICATION_HEIGHT);
        });
    };

    return {
        subscribe
    };
};

export default useBroadcastNotification;
