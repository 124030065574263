<script setup lang="ts">
import {HomeSurveyAnswerInstanceItem} from "@/home/interfaces";
import HomeLiveItem from "@/home/components/HomeLiveItem.vue";
import formatHelper from "@/core/helpers/formatHelper";
import {useRouter} from "vue-router";
import {SurveyAnswerInstance} from "@/core/interfaces/survey/Survey";
import ConsumerKeyService from "@/consumer/services/ConsumerKeyService";
import {useLiveStore} from "@/stores/live.store";

const router = useRouter();
const {updateSurveyItem} = useLiveStore();
defineProps<{
    item: HomeSurveyAnswerInstanceItem['data']
}>();

/**
 * Redirect to the interview instance view.
 */
const goToInstance = (instance: SurveyAnswerInstance) => {
    const customerId = ConsumerKeyService.customerIdFromKey(instance.consumer_key);
    if (customerId) {
        updateSurveyItem(instance)
            .then(() => router.push({
                name: 'customer.survey',
                params: {customerId: customerId, instanceId: instance.id}
            }));
    }
};
</script>

<template>
  <HomeLiveItem
    :time-ago="item?.completed_at ? formatHelper.readableDate(item?.completed_at) : 'Questionnaire incomplet'"
    bg-color-class="bg-gray-300"
    @click="goToInstance(item)"
  >
    <template #image>
      <img
        alt="Survey"
        width="30"
        src="@/assets/icons/survey.svg"
        class="cursor-pointer mr-5"
      />
    </template>
    <p v-if="item?.filler">
      <strong>{{ item.filler.name }}</strong>
    </p>
    <p>Résultat questionnaire : {{ item.survey?.theme || item.survey.name }}</p>
  </HomeLiveItem>
</template>
