<template>
  <form class="container max-h-screen overflow-y-auto mx-auto px-4 sm:px-6 lg:px-8">
    <div class="flex text-white text-xl justify-center my-10">
      Veuillez compléter ce formulaire pour créer un compte
    </div>
    <div class="md:grid md:grid-cols-2">
      <div class="hidden md:block md:h-full md:w-[384px]">
        <dot-lottie-vue
          src="animations/73812-cloud-computing-security.json"
          style="width: 100%; height: 100%"
          autoplay
          loop
        />
      </div>
      <div class="max-w-xl w-full">
        <div class="flex flex-row flex-wrap mt-4 mb-2 gap-10 text-sm text-white">
          <div class="flex flex-row items-center" @click="gender=0">
            <input
              id="man"
              v-model="gender"
              value="0"
              type="radio"
              class="border-2 border-white mr-2"
            >
            <label
              for="man"
              class="flex flex-row items-center cursor-pointer"
            >
              <img
                :class="['h-16 mr-2', gender === 0
                  ? 'rounded-full border-white border-2'
                  : 'border-0'
                ]"
                :src="profileMale"
              >
              Homme
            </label>
          </div>
          <br>
          <div class="flex flex-row items-center" @click="gender=1">
            <input
              id="woman"
              v-model="gender"
              value="1"
              type="radio"
              class="border-2 border-white mr-2"
            >
            <label
              for="woman"
              class="flex flex-row items-center cursor-pointer"
            >
              <img
                :class="['h-16 mr-2', gender === 1
                  ? 'rounded-full border-white border-2'
                  : 'border-0'
                ]"
                :src="profileFemale"
              >
              Femme
            </label>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-10">
          <RegisterInput
            v-model="firstname"
            :error="errors.firstname"
            type="text"
            placeholder="Prénom"
          />
          <RegisterInput
            v-model="lastname"
            :error="errors.lastname"
            type="text"
            placeholder="Nom"
          />
        </div>
        <div
          v-if="errors.gender"
          class="mt-2 text-sm text-orange-light"
        >
          {{ errors.gender }}
        </div>

        <RegisterInput
          v-model="postalCode"
          :error="errors.postalCode"
          type="text"
          placeholder="Code Postal de votre pharmacie"
          @blur="formatZipcode"
          @input="() => debounceFormatZipCode(10000)"
        />

        <RegisterInput
          v-model="email"
          :error="errors.email"
          type="email"
          placeholder="Email"
          @blur="verifyEmail"
        />

        <div class="flex flex-1 flex-col py-4">
          <div class="grid grid-cols-2 w-full gap-10">
            <RegisterInput
              v-model="password"
              type="password"
              placeholder="Mot de passe"
            />
            <RegisterInput
              v-model="passwordConfirmation"
              :error="errors.passwordConfirmation"
              type="password"
              placeholder="Confirmez votre mot de passe"
            />
          </div>
          <div
            :class="['mt-2 text-sm', errors.password
            ? 'text-orange-light'
            : 'text-gray-300'
          ]"
          >
            {{ errors.password || passwordInfo }}
          </div>
        </div>
        <div
          :class="['mt-1 relative', errors.lgo
            ? 'border-orange-light'
            : ''
          ]"
        >
          <v-combobox
            v-model="lgo"
            label="Choisissez votre LGO"
            class="text-white"
            variant="underlined"
            placeholder="Aucun lgo. Cliquez ici pour chercher..."
            :items="lgos"
            :item-title="item => item.name"
          />
        </div>
        <div
          v-if="errors.lgo"
          class="mt-2 text-sm text-orange-light"
        >
          {{ errors.lgo }}
        </div>
        <v-combobox
          v-model="grouping"
          label="Choisissez votre groupement"
          class="text-white"
          variant="underlined"
          placeholder="Aucun groupement. Cliquez ici pour chercher..."
          :items="[{name: 'Aucun groupement', id: 0}, ...groupings]"
          :item-title="item => item.name"
        />
        <div class="my-4">
          <input
            v-model="referral"
            type="text"
            placeholder="Code de parrainage"
            class="px-3 py-2 h-8 placeholder-gray-700 rounded-md border-0 focus:border-0 focus:ring-0 sm:text-sm bg-lightPurple-default"
            style="color: #353535"
          >
        </div>
      </div>
    </div>
    <BaseAlert
      v-if="errors.page"
      type="error"
      title="Erreur"
    >
      {{ errors.page }}
    </BaseAlert>
    <div class="text-gray-200 my-2">
      <input
        v-model="checkedCGU"
        type="checkbox"
        class="accent-secondary mr-2"
      >
      j'ai lu et j'accepte les
      <a
        href="https://api.apodispharma.com/contracts/versions/3/show"
        target="_blank"
        class="underline"
      >
        CGU
      </a>
    </div>
    <div class="text-gray-200 my-2">
      <input
        v-model="checkedTERMS"
        type="checkbox"
        class="accent-secondary mr-2"
      >
      j'ai lu et j'accepte la
      <a
        href="https://www.apodis-sante.com/politique-de-confidentialite/"
        target="_blank"
        class="underline"
      >
        politique de confidentialité des données
      </a>
    </div>
    <button
      type="button"
      class="flex justify-center mx-auto py-3 px-7 mt-8 text-xl text-primary-dark rounded-full bg-secondary"
      @click="validate"
    >
      Créer mon compte
    </button>
  </form>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {lgoApi, registrationService, groupingsApi} from '@/container';
import BaseAlert from '@/core/components/base/BaseAlert.vue';
import RegisterInput from '@/register/views/RegisterInput.vue';
import passwordPolicyHelper from '@/core/helpers/passwordPolicyHelper';
import formatHelper from '@/core/helpers/formatHelper';
import {DotLottieVue} from "@lottiefiles/dotlottie-vue";
import {Grouping} from "@/core/interfaces/laboratory/Grouping";
import profileMale from '@/assets/images/profile-male.png';
import profileFemale from '@/assets/images/profile-female.png';

export default defineComponent({
    name: 'RegisterForm',

    components: {
        DotLottieVue,
        BaseAlert,
        RegisterInput
    },

    props: {
        ecps: {
            type: Object,
            required: false,
            default: null
        }
    },
    emits: ['validate'],

    data() {
        return {
            errors: [] as any,
            lgos: [] as any[],
            groupings: [] as Grouping[],
            alreadyUsedEmail: false,
            firstname: undefined as string | undefined,
            lastname: undefined as string | undefined,
            gender: undefined as number | undefined,
            postalCode: undefined as string | undefined,
            email: undefined as string | undefined,
            password: undefined as string | undefined,
            passwordConfirmation: undefined as string | undefined,
            referral: null as string | null,
            checkedCGU: false,
            checkedTERMS: false,
            lgo: null as any,
            grouping: null as Grouping | null,
            timer: null as any,
            debouncedSearch: null as null | any,
            profileMale,
            profileFemale
        };
    },

    computed: {
        isEmailValid() {
            return this.email && /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.email);
        },

        submitEnabled() {
            return (this.firstname && this.firstname.length > 0) &&
                (this.lastname && this.firstname.length > 0) &&
                (this.postalCode && this.postalCode.length > 0) &&
                (this.gender !== null) &&
                this.isEmailValid && !this.alreadyUsedEmail &&
                (this.lgo !== null) &&
                this.checkedTERMS &&
                this.checkedCGU &&
                (this.password === this.passwordConfirmation) &&
                passwordPolicyHelper.isValid(this.password);
        },

        passwordInfo() {
            if (passwordPolicyHelper.isValid(this.password)) {
                return 'Votre mot de passe est sécurisé 👍';
            } else {
                var info = 'Votre mot de passe doit contenir';
                if (!passwordPolicyHelper.hasMinimumLength(this.password)) {
                    info += ' au moins ' + String(passwordPolicyHelper.minLength) + ' caractères,';
                }
                if (!passwordPolicyHelper.hasNumber(this.password)) {
                    info += ' un nombre,';
                }
                if (!passwordPolicyHelper.hasLowercase(this.password)) {
                    info += ' un caractère minuscule,';
                }
                if (!passwordPolicyHelper.hasUppercase(this.password)) {
                    info += ' un caractère majuscule,';
                }
                if (!passwordPolicyHelper.hasSpecial(this.password)) {
                    info += ' un caractère spécial,';
                }
                return info.replace(/.$/, '.');
            }
        }
    },

    beforeUnmount() {
        if (this.debouncedSearch) {
            this.debouncedSearch.cancel();
        }
    },

    mounted() {
        this.email = import.meta.env.VITE_LOGIN_EMAIL ?? '';
        this.password = import.meta.env.VITE_LOGIN_PASSWORD ?? '';

        lgoApi().getList().then(list => {
            this.lgos = list.lgo;
        });

        groupingsApi().index()
            .then(response => this.groupings = response);

        this.autocompleteECPS();
    },

    methods: {
        autocompleteECPS() {
            if (this.ecps) {
                if (this.firstname === null && this.ecps.given_name) {
                    this.firstname = this.ecps.given_name;
                }
                if (this.lastname === null && this.ecps.family_name) {
                    this.lastname = this.ecps.family_name;
                }
                if (
                    this.email === null &&
                    this.ecps.SubjectRefPro?.exercices?.length > 0 &&
                    this.ecps.SubjectRefPro?.exercices[0]?.activities?.length > 0 &&
                    this.ecps.SubjectRefPro?.exercices[0]?.activities[0].adresseEMail
                ) {
                    this.email = this.ecps.SubjectRefPro?.exercices[0]?.activities[0].adresseEMail;
                }
            }
        },

        verifyEmail() {
            if (this.isEmailValid) {
                registrationService().isMailAvailable(this.email).then(response => {
                    this.alreadyUsedEmail = !response.success;
                    const newErrors: any = Object.assign([], this.errors);
                    if (!response.success) {
                        newErrors.email = 'Un compte existe déjà avec cet email';
                    } else {
                        newErrors.email = undefined;
                    }
                    this.errors = newErrors;
                });
            }
        },
        debounceFormatZipCode(timeout) {
            if (this.timer) {
                clearTimeout(this.timer);
            }
            this.timer = setTimeout(() => {
                this.formatZipcode();
            }, timeout);
        },
        formatZipcode() {
            if (this.postalCode) {
                this.postalCode = formatHelper.formatZipCode(this.postalCode);
            }
        },
        validate() {
            if (this.submitEnabled) {
                const user = {
                    firstname: this.firstname,
                    lastname: this.lastname,
                    gender: this.gender,
                    email: this.email,
                    password: this.password,
                    lgo: this.lgo ? this.lgo.id : null,
                    referral: this.referral,
                    postalCode: this.postalCode,
                    groupingId: this.grouping && this.grouping.id !== 0 ? this.grouping.id : null
                };
                this.$emit('validate', user);
            } else {
                const errorsToDisplay: any = [];
                if (this.firstname === null || (this.firstname && this.firstname.length === 0)) {
                    errorsToDisplay.firstname = 'Prénom requis';
                }
                if (this.lastname === null || (this.lastname && this.lastname.length === 0)) {
                    errorsToDisplay.lastname = 'Nom requis';
                }
                if (this.gender === null) {
                    errorsToDisplay.gender = 'Genre requis';
                }
                if (this.postalCode === null || (this.postalCode && this.postalCode.length === 0)) {
                    errorsToDisplay.postalCode = 'Code postal non valide';
                }
                if (!this.isEmailValid) {
                    errorsToDisplay.email = 'Email non valide';
                }
                if (this.alreadyUsedEmail) {
                    errorsToDisplay.email = 'Un compte existe déjà avec cet email';
                }
                if (this.lgo === null) {
                    errorsToDisplay.lgo = 'Le choix de votre lgo est requis';
                }

                if (!passwordPolicyHelper.isValid(this.password)) {
                    errorsToDisplay.password = this.passwordInfo;
                } else if (this.password !== this.passwordConfirmation) {
                    errorsToDisplay.password = 'Les 2 mots de passe entrés ne correspondent pas';
                }

                if (!this.checkedTERMS) {
                    errorsToDisplay.page = 'Veuillez accepter la politique de confidentialité des données pour continuer';
                }
                if (!this.checkedCGU) {
                    errorsToDisplay.page = 'Veuillez accepter les CGU pour continuer';
                }

                this.errors = errorsToDisplay;
            }
        }
    }
});
</script>

<style scoped>

:deep(.v-field__input input) {
    background-color: transparent;
    color: white;
}

:deep(.v-field__input input:focus) {
    border-color: transparent;
    --tw-ring-color: transparent;
}

:deep(.v-input--density-default) {
    --v-input-padding-top: 10px;
}


</style>