<template>
  <div>
    <header-back-button title="Vos agences" />
    <div class="p-4 flex flex-col gap-3">
      <div>
        <h1>
          Vos agences
        </h1>
        <p>
          Sélectionnez vos agences #1 et enfin l’accès sur lequel vous passez votre flux principal.
        </p>
      </div>
      <div
        v-if="!loading"
        class="flex gap-3 flex-wrap"
      >
        <select-wholesaler
          class="flex-1"
          :rank="1"
          :loading="loading"
          :warehouses="warehouses"
          :healthcenter-warehouse="firstWarehouse"
          :healthcenter-warehouses="healthcenterWarehouses"
          :refreshing="refreshing"
          @save="saveHealthcenterWarehouse"
        />
      </div>
      <div v-else>
        <base-flex-spinner />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import HeaderBackButton from "@/core/components/header-back-button/HeaderBackButton.vue";
import {wholesalerApi} from "@/container";
import {Warehouse, WholesalerWarehouseHealthcenter} from "@/disruption-logistics/interfaces/wholesalers";
import {useToastStore} from "@/stores/toast.store";
import SelectWholesaler from "@/disruption-logistics/components/select-wholesaler/SelectWholesaler.vue";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";

export default defineComponent({
    name: "WholesalerWarehouseHealthcenterView",
    components: {BaseFlexSpinner, SelectWholesaler, HeaderBackButton},
    data() {
        return {
            warehouses: undefined as undefined | Warehouse[],
            healthcenterWarehouses: undefined as undefined | WholesalerWarehouseHealthcenter[],
            loading: true,
            refreshing: false
        };
    },
    computed: {
        /**
         * Get the healthcenter warehouse rank 1.
         */
        firstWarehouse(): WholesalerWarehouseHealthcenter | undefined {
            if (!this.healthcenterWarehouses) {
                return undefined;
            }

            return this.healthcenterWarehouses.find(warehouse => warehouse.rank === 1);
        }
    },
    mounted() {
        this.loading = true;
        Promise.all([
            this.fetchWholesalerWarehouses(),
            this.fetchHealthcenterWarehouses()
        ])
            .finally(() => {
                this.loading = false;
            });
    },
    methods: {
        /**
         * Fetch the wholesaler warehouses
         */
        async fetchWholesalerWarehouses(): Promise<void> {
            return wholesalerApi()
                .getWholesalerWarehouses({include: 'wholesaler'})
                .then(wholesalerWarehouses => {
                    this.warehouses = wholesalerWarehouses;
                });
        },
        /**
         * Fetch the healthcenter warehouses
         */
        async fetchHealthcenterWarehouses(): Promise<void> {
            return wholesalerApi()
                .getCurrentHealthcenterWarehouses({include: 'wholesaler'})
                .then((healthcenterWarehouses) => {
                    this.healthcenterWarehouses = this.sortHealthcenterWarehouses(healthcenterWarehouses);
                });
        },
        /**
         * Sort the healthcenter warehouses by rank
         */
        sortHealthcenterWarehouses(healthcenterWarehouses: WholesalerWarehouseHealthcenter[]) {
            return healthcenterWarehouses.sort((a, b) => {
                return (a.rank || Infinity) - (b.rank || Infinity);
            });
        },

        /**
         * Save the healthcenter warehouse
         */
        async saveHealthcenterWarehouse({healthcenterWarehouseId, warehouseId, wholesalerId, rank}): Promise<void> {
            this.refreshing = true;
            return wholesalerApi()
                .patchHealthcenterWarehouse(
                    healthcenterWarehouseId,
                    wholesalerId,
                    warehouseId,
                    rank
                )
                .then(() => {
                    const toast = useToastStore();

                    toast.showSuccess({
                        content: 'Les agences principales ont été enregistrées avec succès'
                    });

                    return this.fetchHealthcenterWarehouses();
                })
                .finally(() => {
                    this.refreshing = false;
                });
        }
    }
});
</script>

<style scoped>

</style>
