import {Grouping} from "@/core/interfaces/laboratory/Grouping";

/**
 * The groupings resource.
 * @author Mathieu Bolard <m.bolard@apodispharma.com>
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
    return {

        /**
         * Search for groupings.
         * @param {string} name - The name query.
         * @returns {Promise} Promise object represents the HTTP request.
         */
        async search(name: string): Promise<{groups: Grouping[]}> {
            return client
                .get('/grouping/search', {params: {name}})
                .then(response => response.data);
        },

        /**
         * Index of groupings.
         *
         * @returns {Promise} Promise object represents the HTTP request.
         */
        async index(): Promise<Grouping[]> {
            return client.get('/grouping-list')
                .then(response => response.data.data);
        }
    };
}
