<script setup lang="ts">
import {Survey} from "@/core/interfaces/survey/Survey";
import {useInterviewStore} from "@/stores/interview-store";
import {computed, onMounted, ref} from "vue";
import {interviewApi, surveyApi} from "@/container";
import BaseContainer from "@/core/components/base/BaseContainer.vue";
import InterviewAudioImport from "@/interview/components/instance-modal/InterviewAudioImport.vue";
import {storeToRefs} from "pinia";
import InterviewContainer from "@/interview/components/instance-modal/InterviewContainer.vue";
import {DotLottieVue} from "@lottiefiles/dotlottie-vue";
import InterviewSurveyBuilt from "@/interview/components/instance-modal/InterviewSurveyBuilt.vue";
import BaseButton from "@/core/components/base/BaseButton.vue";
import domHelper from "@/core/helpers/domHelper";
import dayjs from "dayjs";
import {useRoute, useRouter} from "vue-router";
import InterviewConsumerNoteInput from "@/interview/components/instance-modal/InterviewConsumerNoteInput.vue";

const interviewStore = useInterviewStore();
const {prefilledSurveyAnswers, interview, interviewInstance, transcript} = storeToRefs(interviewStore);
const survey = ref<Survey | undefined>();
const dirty = ref(false);
const userAnswerValues = ref<Record<number, unknown>>({});
const loading = ref(true);
const submitting = ref(false);
const router = useRouter();
const route = useRoute();

/**
 * On mount, read the survey attached to the interview, and prefill the survey with the audio if available.
 */
onMounted(async () => {
    if (interviewInstance.value && interview.value) {
        loading.value = true;
        const _instance = await interviewApi()
            .readInstance(interview.value.id, interviewInstance.value.id);

        if (_instance.survey_answer_instance_id) {
            return surveyApi().builtAnsweredInstance(_instance.survey_answer_instance_id)
                .then((response: { survey: Survey }) => {
                    survey.value = response.survey;
                    initAnswers(survey.value);
                })
                .finally(() => {
                    loading.value = false;
                });
        } else {
            survey.value = await readSurvey();
            if (survey.value) {
                return prefillSurvey(null, survey.value)
                    .finally(() => {
                        loading.value = false;
                    });
            }

            loading.value = false;
        }
    }
});

/**
 * Compute the answers.
 * If the user has answered a question, use that answer.
 * Otherwise, use the prefilled answer.
 */
const answers = computed(() => {
    return Object.keys(userAnswerValues.value)
        .reduce((acc, key) => {
            acc[key] = userAnswerValues.value[key].length
                ? userAnswerValues.value[key]
                : prefilledAnswers.value[key] ?? [];

            return acc;
        }, {});
});

/**
 * Compute the prefilled answers.
 */
const prefilledAnswers = computed(() => {
    return prefilledSurveyAnswers.value?.reduce((acc, answer) => {
        acc[answer.question_id] = answer.answer;

        return acc;
    }, {});
});

/**
 * Get the survey errors.
 */
const errors = computed(() => {
    if (!survey.value?.sections || !dirty.value) {
        return {};
    }

    return survey.value.sections.map(section => section.questions)
        .flat()
        .reduce((acc, question) => {
            if (!question) {
                return acc;
            }

            const answer = answers.value[question.id]?.filter(value => {
                return value !== null && value !== undefined && value !== '';
            });

            if (!question.optional) {
                acc[question.id] = !answer.length ? 'Ce champ est obligatoire' : undefined;
            }

            return acc;
        }, {});
});

/**
 * Read the survey attached to the interview.
 */
const readSurvey = async (): Promise<Survey | undefined> => {
    if (!interviewStore.interview?.survey) {
        return Promise.resolve(undefined);
    }

    return surveyApi()
        .readBuiltSurvey(interviewStore.interview?.survey.id);
};

/**
 * Prefill the survey with the audio transcript.
 *
 * @param {Blob|null} audio The audio file to transcribe.
 * @param {Survey} survey The survey to prefill.
 */
const prefillSurvey = async (audio: Blob | null, survey: Survey) => {
    if (interviewStore.interview && interviewStore.consumerKey && interviewStore.interviewInstance) {
        if (audio) {
            await interviewStore.readTranscript(interviewStore.interviewInstance.id, audio);
        }

        await interviewStore.prefillInterviewSurvey(interviewStore.interview.id, transcript.value?.transcript_id, transcript.value?.text);
        initAnswers(survey);
    }
};

/**
 * Initialize the user answers.
 */
const initAnswers = (survey: Survey) => {
    const questions = survey.sections?.map(section => section.questions)
        .flat() ?? [];

    userAnswerValues.value = questions.reduce((acc: Record<number, unknown>, question) => {
        if (!question) {
            return acc;
        }

        acc[question.id] = question.user_answer?.value ?? prefilledAnswers[question?.id] ?? [];

        return acc;
    }, {});
};

/**
 * Handle the file upload.
 *
 * @param {Blob} file The uploaded file.
 */
const handleFileUpload = (file: Blob) => {
    loading.value = true;
    interviewStore.audio = file;

    if (survey.value) {
        prefillSurvey(file, survey.value)
            .finally(() => {
                loading.value = false;
            });
    }
};

/**
 * Handle the change event.
 *
 * @param {Object} value The new value.
 * @param {number} questionId The question ID.
 */
const handleChange = ({value, questionId}) => {
    userAnswerValues.value[questionId] = [value];
};

/**
 * Determine if the survey has errors.
 */
const surveyHasErrors = (): boolean => {
    return Object.keys(errors.value)
        .some(key => errors.value[key] !== undefined);
};

/**
 * Scroll to the first error.
 */
const scrollToFirstError = (): void => {
    const ids = Object.keys(errors.value)
        .filter(key => !!errors.value[key]);

    if (ids.length > 0) {
        const firstErrorId = ids[0];
        domHelper.scrollIntoView(`question-${firstErrorId}`, {
            behavior: 'smooth',
            block: 'start',
            inline: 'start'
        });
    }
};

/**
 * Submit the survey.
 */
const submit = () => {
    dirty.value = true;

    if (surveyHasErrors()) {
        scrollToFirstError();
        return;
    }

    if (interview.value?.id && interviewInstance.value) {
        submitting.value = true;
        const formattedAnswers = Object.keys(answers.value)
            .map(key => {
                return {
                    question_id: Number(key),
                    answer: answers.value[key]
                };
            });

        return interviewApi()
            .updateInstance(interview.value.id, interviewInstance.value.id, {
                survey_answers: formattedAnswers,
                ended_at: dayjs().format('YYYY-MM-DD HH:mm:ss')
            })
            .then((instance) => {
                interviewStore.interviewInstance = instance;
                submitting.value = false;

                router.replace({
                    name: 'interview-instances.finished',
                    query: {...route.query}
                });
            })
            .finally(() => {
                submitting.value = false;
            });
    }
};
</script>

<template>
  <BaseContainer class="relative h-full">
    <div class="flex gap-x-6 pl-5 py-5 h-full">
      <div class="flex-1">
        <h2>
          Vérifiez et complétez le questionnaire
        </h2>
        <div class="flex xl:flex-row flex-col justify-between">
          <p>L’IA a répondu aux questions, vérifiez-les et modifiez si besoin</p>
          <InterviewAudioImport
            v-if="transcript"
            show-let-the-magic-happen
            @file-upload="handleFileUpload"
          />
        </div>
        <InterviewContainer class="mt-4 flex">
          <div v-if="loading" class="flex-1">
            <h2>Nous préparons le questionnaire… encore un petit instant 🙂</h2>
            <dot-lottie-vue
              src="animations/interview.json"
              class="mx-auto my-auto w-100"
              :autoplay="true"
              :loop="true"
            />
          </div>
          <div v-else-if="survey" class="flex-1">
            <InterviewAudioImport
              v-if="!transcript"
              :show-let-the-magic-happen="false"
              @file-upload="handleFileUpload"
            />
            <interview-survey-built
              density="compact"
              :survey="survey"
              :answers="answers"
              :errors="errors"
              @change="handleChange"
            />
            <BaseButton
              :disabled="submitting"
              :loading="submitting"
              :primary="true"
              class="float-right mt-6 font-bold"
              @click="submit"
            >
              Enregistrer les réponses
            </BaseButton>
          </div>
        </InterviewContainer>
      </div>
      <slot name="shortcuts" />
    </div>
    <InterviewConsumerNoteInput
      v-if="interviewStore.interviewInstance"
      class="bottom-0 w-full"
      :interview-instance="interviewStore.interviewInstance"
    />
  </BaseContainer>
</template>
