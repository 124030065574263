<template>
  <span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-indigo-100 text-indigo-800">
    <svg
      v-if="dot"
      viewBox="0 0 8 8"
      fill="currentColor"
      class="-ml-1 mr-1.5 h-2 w-2 text-indigo-400"
    >
      <circle
        r="3"
        cx="4"
        cy="4"
      />
    </svg>

    <slot />

    <button v-if="hasCloseListener" class="ml-2 p-1" @click="$emit('close')">
      <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
        <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
      </svg>
    </button>
  </span>
</template>

<script lang="ts" setup>
import {computed, getCurrentInstance} from "vue";

defineProps<{
    dot?: boolean
}>();

defineEmits(['close']);

const hasCloseListener = computed(() => {
    return getCurrentInstance()?.vnode.props?.onClose;
});
</script>
