<template>
  <div
    class="pt-5 pb-5 flex flex-1 leading-4"
    :class="isActive ? 'bg-primary text-white': 'bg-white text-primary'"
    style="min-height: 90px"
  >
    <div class="px-2 flex items-center">
      <button @click="back">
        <icon
          :name="'mdi-arrow-left'"
          :size="24"
          :color="isActive ? '#ffffff': '#3513e2'"
        />
      </button>
    </div>
    <div
      v-show="customer && customer.id && customer.information.name"
      class="flex flex-1 items-center"
    >
      <router-link
        :to="{ name: 'customer.home' }"
        class="flex"
        @mousedown="clicked"
      >
        <CustomerSummary
          :customer="customer"
          :active="isActive"
        />
      </router-link>
      <div class="flex flex-1 justify-end pr-5">
        <CustomerCertificationHeader
          :customer="customer"
          class="self-end"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import CustomerCertificationHeader from '@/customer-certification/components/CustomerCertificationHeader.vue';
import CustomerSummary from '@/customer/components/customer-summary/CustomerSummary.vue';
import Icon from '@/core/components/icon/Icon.vue';
import Customer from "@/customer/interfaces/Customer";

export default defineComponent({
    name: 'CustomerHeaderInsured',
    components: { CustomerSummary, CustomerCertificationHeader, Icon },
    props: {
        customer: {
            type: Object as PropType<Customer>,
            required: true
        }
    },

    computed: {
        isActive () {
            return this.$route.name !== 'customer.beneficiary';
        }
    },

    methods: {
        back () {
            this.$router.go(-1);
        },
        clicked () {
            if (this.$route.name === 'customer.home') {
                window.dispatchEvent(new Event('insured-clicked'));
            }
        }
    }
});
</script>
