<template>
  <div class="flex w-full items-center justify-center">
    <form class="container max-h-screen overflow-y-auto mx-auto px-4 sm:px-6 lg:px-8">
      <div
        v-if="registeredUser === null"
        class="flex flex-col items-center"
      >
        <div>
          <div
            v-if="loading"
            class="text-white"
          >
            Chargement de l'utilisateur
          </div>
          <div
            v-else-if="guestId === null"
            class="text-white text-xl justify-center "
          >
            Invitation expirée
          </div>
          <div
            v-else
            class="max-w-md"
          >
            <div class="flex text-white text-xl justify-center my-10">
              Veuillez compléter ce formulaire pour créer un compte
            </div>
            <div class="grid grid-cols-2 gap-2">
              <RegisterInput
                v-model="firstname"
                :error="errors.firstname"
                type="text"
                placeholder="Prénom"
              />
              <RegisterInput
                v-model="lastname"
                :error="errors.lastname"
                type="text"
                placeholder="Nom"
              />
            </div>
            <div class="flex flex-row flex-wrap mt-4 mb-2 gap-4 text-sm text-white justify-center">
              <div class="flex flex-row items-center" @click="gender=0">
                <input
                  id="man"
                  v-model="gender"
                  value="0"
                  type="radio"
                  class="border-2 border-white mr-2"
                >
                <label
                  for="man"
                  class="flex flex-row items-center cursor-pointer"
                >
                  <img
                    :class="['h-16 mr-2', gender === 0
                      ? 'rounded-full border-white border-2'
                      : 'border-0'
                    ]"
                    :src="profileMale"
                  >
                  Homme
                </label>
              </div>
              <br>
              <div class="flex flex-row items-center" @click="gender=1">
                <input
                  id="woman"
                  v-model="gender"
                  value="1"
                  type="radio"
                  class="border-2 border-white mr-2"
                >
                <label
                  for="woman"
                  class="flex flex-row items-center cursor-pointer"
                >
                  <img
                    :class="['h-16 mr-2', gender === 1
                      ? 'rounded-full border-white border-2'
                      : 'border-0'
                    ]"
                    :src="profileFemale"
                  >
                  Femme
                </label>
              </div>
            </div>
            <div
              v-if="errors.gender"
              class="mt-2 text-sm text-orange-light"
            >
              {{ errors.gender }}
            </div>

            <div
              :class="['mt-1 relative border-b-2', errors.rank
                ? 'mb-0 border-orange-light'
                : 'mb-8'
              ]"
            >
              <select
                v-model="rankId"
                class="appearance-none w-full px-3 py-2 h-12 text-white border-0 focus:border-0 focus:ring-0 sm:text-sm bg-transparent"
              >
                <option
                  :value="null"
                  disabled
                  hidden
                >
                  Sélectionnez votre poste
                </option>
                <option
                  v-for="rank in ranks"
                  :key="rank.id"
                  :value="rank.id"
                >
                  {{ rank.title }}
                </option>
              </select>
            </div>

            <RegisterInput
              v-model="email"
              :error="errors.email"
              type="email"
              placeholder="Email"
            />

            <RegisterInput
              v-model="password"
              type="password"
              placeholder="Mot de passe"
            />
            <RegisterInput
              v-model="passwordConfirmation"
              :error="errors.passwordConfirmation"
              type="password"
              placeholder="Confirmez votre mot de passe"
            />
            <div
              :class="['mt-2 text-sm', errors.password
                ? 'text-orange-light'
                : 'text-gray-300'
              ]"
            >
              {{ errors.password || passwordInfo }}
            </div>
          </div>
        </div>
        <BaseAlert
          v-if="errors.page"
          type="error"
          title="Erreur"
        >
          {{ errors.page }}
        </BaseAlert>
        <button
          type="button"
          class="flex justify-center mx-auto py-3 px-7 mt-8 text-xl text-primary-dark rounded-full bg-secondary"
          @click="register"
        >
          Créer mon compte
        </button>
      </div>
      <div v-else>
        <RegisterSuccess
          :user="registeredUser"
        />
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {usersApi} from '@/container';
import BaseAlert from '@/core/components/base/BaseAlert.vue';
import RegisterInput from '@/register/views/RegisterInput.vue';
import passwordPolicyHelper from '@/core/helpers/passwordPolicyHelper';
import RegisterSuccess from '@/register/views/RegisterSuccess.vue';
import profileMale from '@/assets/images/profile-male.png';
import profileFemale from '@/assets/images/profile-female.png';
import {UserAccessRank} from "@/core/interfaces/User";

export default defineComponent({
    name: 'RegisterGuest',

    components: {
        BaseAlert,
        RegisterInput,
        RegisterSuccess
    },

    data() {
        return {
            errors: [] as any,
            loading: true,
            guestId: null as number | null,
            firstname: undefined as string | undefined,
            lastname: undefined as string | undefined,
            gender: null as number | null,
            email: undefined as string | undefined,
            password: undefined as string | undefined,
            passwordConfirmation: undefined as string | undefined,
            registeredUser: null as any,
            rankId: null as number | null,
            ranks: [] as UserAccessRank[],
            profileMale,
            profileFemale
        };
    },

    computed: {
        isEmailValid() {
            return this.email && this.email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        },

        submitEnabled() {
            return (this.firstname && this.firstname.length > 0) &&
                (this.lastname && this.lastname.length > 0) &&
                (this.gender !== null) &&
                this.isEmailValid &&
                (this.rankId !== null) &&
                (this.password === this.passwordConfirmation) &&
                passwordPolicyHelper.isValid(this.password);
        },

        passwordInfo() {
            if (passwordPolicyHelper.isValid(this.password)) {
                return 'Votre mot de passe est sécurisé 👍';
            } else {
                var info = 'Votre mot de passe doit contenir';
                if (!passwordPolicyHelper.hasMinimumLength(this.password)) {
                    info += ' au moins ' + String(passwordPolicyHelper.minLength) + ' caractères,';
                }
                if (!passwordPolicyHelper.hasNumber(this.password)) {
                    info += ' un nombre,';
                }
                if (!passwordPolicyHelper.hasLowercase(this.password)) {
                    info += ' un caractère minuscule,';
                }
                if (!passwordPolicyHelper.hasUppercase(this.password)) {
                    info += ' un caractère majuscule,';
                }
                if (!passwordPolicyHelper.hasSpecial(this.password)) {
                    info += ' un caractère spécial,';
                }
                return info.replace(/.$/, '.');
            }
        }
    },

    mounted() {
        usersApi()
            .getByInvitationHash(this.$route.params.hash)
            .then((guest) => {
                this.email = guest.email;
                this.guestId = guest.id;
                this.loading = false;
            })
            .catch(() => {
                this.loading = false;
            });

        usersApi().ranks({is_manager: false})
            .then(ranks => this.ranks = ranks);
    },

    methods: {
        register() {
            if (this.submitEnabled) {
                const user = {
                    given: this.firstname,
                    familyBirth: this.lastname,
                    gender: this.gender,
                    email: this.email,
                    password: this.password,
                    guestId: this.guestId,
                    rankId: this.rankId
                };
                usersApi()
                    .registerFromInvitation(user)
                    .then((response) => {
                        this.registeredUser = response.user;
                    })
                    .catch(() => {
                        this.errors = {page: 'Un utilisateur existe déjà avec cet email'};
                    });
            } else {
                const errorsToDisplay: any = [];
                if (!this.firstname || this.firstname.length === 0) {
                    errorsToDisplay.firstname = 'Prénom requis';
                }
                if (!this.lastname || this.lastname.length === 0) {
                    errorsToDisplay.lastname = 'Nom requis';
                }
                if (this.gender === null) {
                    errorsToDisplay.gender = 'Genre requis';
                }
                if (!this.isEmailValid) {
                    errorsToDisplay.email = 'Email non valide';
                }
                if (this.rankId === null) {
                    errorsToDisplay.rank = 'Poste non valide';
                }

                if (!passwordPolicyHelper.isValid(this.password)) {
                    errorsToDisplay.password = this.passwordInfo;
                } else if (this.password !== this.passwordConfirmation) {
                    errorsToDisplay.password = 'Les 2 mots de passe entrés ne correspondent pas';
                }

                this.errors = errorsToDisplay;
            }
        }
    }
});
</script>
