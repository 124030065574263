<script setup lang="ts">

import BaseCard from "@/core/components/base/BaseCard.vue";
import BaseCardHeader from "@/core/components/base/BaseCardHeader.vue";
import {Line} from "vue-chartjs";
import BaseCardBody from "@/core/components/base/BaseCardBody.vue";
import {LaboratoryOperatorAnalysis} from "@/core/http/resources/laboratory/LaboratoryResource";
import {computed} from "vue";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import {currencyze} from "@/core/filters/Currencyze";
import ChartService from "@/laboratory/services/ChartService";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const props = defineProps<{
    data: LaboratoryOperatorAnalysis[];
}>();

/**
 * Extract the lines labels
 */
const labels = computed(() => {
    const data = props.data[0]?.sale_histogram ?? [];

    return data.map(item => item.key_as_string);
});

const datasets = computed(() => {
    return props.data.map((item, index) => {
        const colors = ChartService.colorPalette();
        const color = colors[index % colors.length];

        return {
            axis: 'y',
            label: item.operator,
            fill: false,
            data: item.sale_histogram.map(histogram => histogram.revenue),
            borderColor: color,
            borderWidth: 2,
            pointRadius: 2,
            pointHoverRadius: 5,
            pointBackgroundColor: color,
        };
    });
});
</script>

<template>
  <BaseCard>
    <BaseCardHeader>
      Courbe des ventes
    </BaseCardHeader>
    <BaseCardBody class="p-0 h-[200px]">
      <Line
        id="operator-line-chart"
        class="h-32"
        :data="{
          labels: labels,
          datasets: datasets
        }"
        :options="{
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
            mode: 'index' as const,
            intersect: false,
          },
          plugins: {
            tooltip: {
              backgroundColor: 'rgba(255, 255, 255, 0.95)',
              titleColor: '#1f2937',
              bodyColor: '#1f2937',
              bodyFont: {
                size: 12,
              },
              padding: 12,
              borderWidth: 1,
              callbacks: {
                label: (context) => {
                  const value = context.raw as number;
                  return `${context.dataset.label}: ${currencyze(value)}`;
                }
              }
            },
            legend: {
              display: false
            }
          },
          scales: {
            x: {
              display: true,
              grid: {
                display: false
              },
              ticks: {
                font: {
                  size: 8
                }
              }
            },
            y: {
              beginAtZero: true,
              grid: {
                color: 'rgba(0, 0, 0, 0.05)',
              },
              ticks: {
                callback: (value) => currencyze(value as number),
              }
            }
          },
          elements: {
            line: {
              tension: 0.4
            }
          },
          hover: {
            intersect: false,
            mode: 'nearest'
          }
        }"
      />
    </BaseCardBody>
  </BaseCard>
</template>
