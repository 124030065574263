import useSwr from "@/core/composables/swr/useSwr";
import {surveyApi} from "@/container";

/**
 * Composable to fetch the built survey instance with SWR.
 *
 * @param surveyInstanceId
 */
const useSurveyInstanceBuilt = (surveyInstanceId: number) => {
    const {data, isLoading} = useSwr(
        `survey-answer-instances/${surveyInstanceId}/built`,
        () => surveyApi().builtAnsweredInstance(surveyInstanceId),
        {
            revalidateOnFocus: false,
            ttl: 1000 * 60 * 20 // 20 minutes
        }
    );

    return {data, isLoading};
};

export default useSurveyInstanceBuilt;
