<template>
  <BaseCard>
    <BaseCardBody>
      <p class="font-heading text-xl text-primary">
        Déroulement du parcours
      </p>
      <div class="flex justify-start">
        <v-timeline
          dense
          side="end"
          class="flex flex-col items-start"
        >
          <v-timeline-item
            dot-color="secondary"
            icon="mdi-flag"
            icon-color="white"
            fill-dot
          >
            <v-card
              class="flex justify-center"
            >
              <v-card-text class="text-title text-primary font-bold">
                Détection du patient concerné au comptoir
              </v-card-text>
            </v-card>
          </v-timeline-item>
          <v-timeline-item
            v-for="step in pathway.steps"
            :key="step.id"
            dot-color="primary"
            fill-dot
          >
            <template #icon>
              <span class="font-content text-white font-bold">{{ step.index }}</span>
            </template>
            <v-card>
              <v-card-title class="font-heading">
                {{ step.title }}
              </v-card-title>
              <v-card-text v-if="step.instructions">
                <markdown
                  :content="step.instructions"
                  class="text-sm"
                  inherit-styles
                />
              </v-card-text>
            </v-card>
          </v-timeline-item>
          <v-timeline-item
            icon="mdi-check"
            icon-color="white"
            fill-dot
            dot-color="secondary"
          >
            <v-card
              class="flex justify-center"
            >
              <v-card-text class="text-title text-primary font-bold">
                Fin du parcours
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </div>
    </BaseCardBody>
  </BaseCard>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import BaseCard from '@/core/components/base/BaseCard.vue';
import BaseCardBody from '@/core/components/base/BaseCardBody.vue';
import Markdown from '@/core/components/markdown/Markdown.vue';

export default defineComponent({
    name: 'SupportProgramPathwayTimeline',
    components: { BaseCardBody, BaseCard, Markdown },
    props: {
        pathway: {
            type: Object,
            required: true
        }
    }
});
</script>
