<script setup lang="ts">
import {LaboratoryOperatorAnalysis} from "@/core/http/resources/laboratory/LaboratoryResource";
import {computed} from "vue";
import {currencyze} from "@/core/filters/Currencyze";
import {useRouter} from "vue-router";

const props = defineProps<{
    data: LaboratoryOperatorAnalysis[],
    loading: boolean
}>();
const router = useRouter();

const headers = [
    {
        title: "Vendeur",
        key: "operator"
    },
    {
        title: "Unités vendues",
        key: "total_quantity"
    },
    {
        title: "CA TTC",
        key: "revenue"
    },
    {
        title: "Panier moyen (€)",
        key: "average_cart_price"
    },
    {
        title: "Panier moyen (unités)",
        key: "average_cart_quantity"
    }
];

const dataTableItems = computed(() => {
    return props.data.map((item) => {
        return {
            operator: item.operator,
            revenue: currencyze(item.revenue),
            average_cart_price: currencyze(item.average_cart_price),
            average_cart_quantity: item.average_cart_quantity,
            total_quantity: item.total_quantity,
            operator_code: item.operator_code
        };
    });
});

const handleClickRow = (event: PointerEvent, row) => {
    router.push({
        name: "laboratory.sales",
        query: {
            operator_code: row.item.operator_code,
            entity_name: row.item.operator
        }
    });
};
</script>

<template>
  <v-data-table
    :loading="props.loading"
    :items="dataTableItems"
    :headers="headers"
    :hover="true"
    @click:row="handleClickRow"
  />
</template>

<style scoped>

</style>
