<script setup lang="ts">
import BaseCard from "@/core/components/base/BaseCard.vue";
import BaseCardBody from "@/core/components/base/BaseCardBody.vue";
import {computed, reactive, ref} from "vue";
import BaseSelect from "@/core/components/base/BaseSelect.vue";
import Icon from "@/core/components/icon/Icon.vue";
import debounce from "lodash.debounce";
import dayjs from "dayjs";
import {useRouter} from "vue-router";
import PrescriptionTagSelector from "@/prescription/components/PrescriptionTagSelector.vue";
import usePatientDashboard from "@/patient-administration/hooks/usePatientDasboard";
import Customer from "@/customer/interfaces/Customer";
import {currencyze} from "@/core/filters/Currencyze";
import dateHelper from "@/core/helpers/dateHelper";
import {PatientSearchParams} from "@/customer/interfaces/Patient";
import ProgramLogo from "@/program/components/program-logo/ProgramLogo.vue";

const router = useRouter();

const dateOptions = [
    {
        label: "Sur 1 mois",
        value: dayjs().subtract(1, "month").format("YYYY-MM-DD")
    },
    {
        label: "Sur 3 mois",
        value: dayjs().subtract(3, "month").format("YYYY-MM-DD")
    },
    {
        label: "Sur 1 an",
        value: dayjs().subtract(1, "year").format("YYYY-MM-DD")
    },
];

const headers = [
    {
        title: "Patient",
        key: "name",
        sortable: false
    },
    {
        title: "CA (Ordos)",
        key: "revenue_prescribed",
        firstSortDesc: true
    },
    {
        title: "CA (Hors-ordos)",
        key: "revenue_others",
        firstSortDesc: true
    },
    {
        title: "Visites",
        key: "visits",
        firstSortDesc: true
    },
    {
        title: "Dernière visite",
        key: "last_visit",
        firstSortDesc: true
    }
];

const search = ref<string | null>(null);
const page = ref(1);

const searchParams = reactive<PatientSearchParams>({
    limit: 100,
    from: dateOptions[0].value,
    sort_by: null,
    sort_order: null,
    tags: [],
    certified: null,
    children: null,
    consumer_key: null,
    tags_operator: "AND"
});

const {
    data,
    isLoading,
    isValidating
} = usePatientDashboard(searchParams);

const debounceSearch = debounce((event) => {
    search.value = (event.target as HTMLInputElement)?.value ?? null;
}, 1000);

const _data = computed(() => {
    return data.value?.data.map((customer: Customer) => {
        return {
            name: customer.information?.name || "Patient Anonyme",
            programs: customer.programs,
            birth_date: customer.information?.birth_date ?? null,
            revenue_prescribed: currencyze(customer.statistics.family?.revenue.prescribed
                || customer.statistics.self?.revenue.prescribed),
            revenue_others: currencyze(customer.statistics.family?.revenue.others
                || customer.statistics.self?.revenue.others),
            visits: customer.statistics.family!.visits || customer.statistics.self!.visits,
            last_visit: dateHelper.shortFormat(customer.statistics.family!.last_visit || customer.statistics.self!.last_visit),
            id: customer.id,
            key: customer.id
        };
    });
});

/**
 * Handle item per page update.
 *
 * @param _itemsPerPage
 */
const handleItemPerPageUpdate = (_itemsPerPage: number) => {
    searchParams.limit = _itemsPerPage;
};

/**
 * Handle page update.
 *
 * @param _page
 */
const handlePageUpdate = (_page: number) => {
    page.value = _page;
};

/**
 * Handle sort by update.
 *
 * @param _sortBy
 */
const handleSortByUpdate = (_sortBy: { key: string, order: "asc" | "desc" }[]) => {
    page.value = 1;
    searchParams.sort_by = _sortBy[0].key ?? "visits";
    searchParams.sort_order = _sortBy[0].order ?? "desc";
};

/**
 * Handle row click.
 *
 * @param event
 * @param row
 */
const handleRowClick = (event: PointerEvent, row: { item: Customer }) => {
    if (!row.item.id) {
        return;
    }

    router.push({
        name: "customer.home",
        params: {
            customerId: row.item.id
        }
    });
};
</script>

<template>
  <div class="p-6 space-y-6">
    <div>
      <h1>
        Mes patients
      </h1>
      <p class="text-base font-content">
        Analysez votre patientèle, jouez avec les filtres, triez par colonne et fidélisez
        vos patients en les aidant à renouveler leurs ordonnances.
      </p>
      <PrescriptionTagSelector v-if="data?.meta.tags" v-model="searchParams" :tags="data.meta.tags" class="mt-2" />
    </div>
    <BaseCard>
      <div class="bg-white px-4 py-5 border-b border-gray-200 flex items-center justify-between">
        <div class="flex gap-3 items-center">
          <h2>Liste des patients</h2>
          <BaseSelect v-model:modelValue="searchParams.from" trigger-class="justify-end w-auto" :options="dateOptions" />
        </div>
        <div v-if="false" class="bg-white rounded-md px-2 flex items-center shadow-md">
          <input
            type="text" class="focus:ring-0 flex-1 w-64" placeholder="Rechercher un patient"
            @input="debounceSearch"
          >
          <Icon name="mdi-magnify" color="primary" />
        </div>
      </div>
      <BaseCardBody>
        <v-data-table-server
          :items="_data"
          :headers="headers"
          :hover="true"
          :items-length="searchParams.limit"
          :loading="isLoading || isValidating"
          :items-per-page-options="[20,100]"
          :items-per-page="searchParams.limit"
          :page="1"
          @update:items-per-page="handleItemPerPageUpdate"
          @update:page="handlePageUpdate"
          @update:sort-by="handleSortByUpdate"
          @click:row="handleRowClick"
        >
          <template #[`item.name`]="{item}">
            <div class="flex items-center gap-2">
              <p>{{ item.name || 'Patient Anonyme' }}</p>
              <p v-if="item.birth_date" class="text-xs text-gray-500">
                {{ $filters.date(item.birth_date, "relative", true) }}
              </p>
            </div>
            <div class="flex gap-2">
              <ProgramLogo
                v-for="program in item.programs"
                :key="item.id + '-' + program.id"
                :program="program"
                :size="15"
              />
            </div>
          </template>
        </v-data-table-server>
      </BaseCardBody>
    </BaseCard>
  </div>
</template>
