<template>
  <div class="program_group_consumer_table">
    <v-data-table
      v-model="customers"
      :disable-sort="degraded"
      :headers="headers.map(header => {
        return {
          ...header,
          align: 'center',
          sort: customSort
        }
      })"
      :show-select="enableSelection"
      :items="parsedItems"
      item-value="key"
      :footer-props="{
        'items-per-page-options': [10, 25, 50, 100],
        'items-per-page-text': 'Patients par page',
        'show-current-page': true,
        'show-first-last-page': true
      }"
      @click:row="onRowClick"
    >
      <template #[`item.name`]=" { item } ">
        <div class="flex flex-col">
          <p>{{ item.name }}</p>
          <p
            v-if="item.birthdate"
            class="text-sm text-gray-500"
          >
            {{ $filters.date(item.birthdate, 'short') }}
          </p>
        </div>
      </template>
      <template #[`item.excluded`]="{ item }">
        <div
          v-for="(_excluded, index) in item.excluded"
          :key="_excluded"
          class="flex justify-center"
        >
          <div
            v-if="_excluded"
            class="flex items-center gap-1"
          >
            <p>{{ _excluded }}&nbsp;✅</p>
          </div>
          <div
            v-if="index !== item.excluded?.length - 1"
            class="bg-gray-300 my-1"
            style="height: 1px"
          />
        </div>
      </template>
      <template #[`footer.page-text`]="{ pageStart, pageStop, itemsLength }">
        <span v-if="itemsLength > 0">
          Affichage de {{ pageStart }} à {{ pageStop }} sur {{ itemsLength }} patients
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import dateHelper from '@/core/helpers/dateHelper';
import {SupportProgramMeta, SupportProgramPatient} from "@/program/interfaces/Program";
import dayjs from "dayjs";
import {mapWritableState} from "pinia";
import {useCommunicationStore} from "@/communication/stores/communication.store";

interface ConsumerItem {
    key: string;
    name: string;
    birthdate: string;
    groups: string;
    excluded: string[];

    [tracker: string]: string | string[];
}

export default defineComponent({
    name: 'SupportProgramConsumerTable',
    props: {
        consumers: {
            type: Array as PropType<SupportProgramPatient[]>,
            required: true
        },
        meta: {
            type: Object as PropType<SupportProgramMeta>,
            required: true
        },
        enableSelection: {
            type: Boolean,
            default: false
        },
        degraded: {
            type: Boolean,
            default: false
        }
    },
    emits: ['click'],
    data() {
        return {
            customers: []
        };
    },
    computed: {
        ...mapWritableState(useCommunicationStore, ['programCustomers']),
        /**
         * Table headers.
         */
        headers(): { title: string; key: string }[] {
            const columns: { title: string; key: string }[] = [];

            columns.push({
                title: `Patients ${this.degraded ? '' : '(' + this.parsedItems.length + ')'}`,
                key: 'name'
            });

            if (Object.keys(this.meta.included).length > 1) {
                columns.push({
                    title: this.includedColumnLabel,
                    key: 'groups'
                });
            }

            columns.push({
                title: this.excludedColumnLabel,
                key: 'excluded'
            });

            this.trackerColumns.forEach((trackerColumn: string) => {
                columns.push({
                    title: trackerColumn,
                    key: trackerColumn
                });
            });

            this.pathwayProgressColumns.forEach((progressColumn) => {
                columns.push(progressColumn);
            });

            return columns;
        },
        /**
         * Parse the consumer for the table.
         */
        parsedItems(): ConsumerItem[] {
            return this.consumers.map(consumer => {
                if (this.enableSelection && !consumer.phone) {
                    return;
                }
                return {
                    key: consumer.key,
                    name: consumer.name,
                    birthdate: consumer.birthdate,
                    groups: this.groupLabels(consumer),
                    excluded: this.aggregatedExcludedValues(consumer),
                    ...consumer.tracker?.columns?.reduce((trackerColumns, tracker) => {
                        trackerColumns[tracker.label] = tracker.value;
                        return trackerColumns;
                    }, {}),
                    ...this.pathwayProgressConsumerColumns(consumer)
                };
            }).filter((consumer): consumer is ConsumerItem => !!consumer);
        },

        /**
         * Get the tracker columns.
         */
        trackerColumns(): string[] {
            return this.consumers.reduce((trackerColumns: string[], consumer) => {
                if (!consumer.tracker?.columns) {
                    return trackerColumns;
                }

                consumer.tracker.columns.forEach(tracker => {
                    if (!trackerColumns.includes(tracker.label)) {
                        trackerColumns.push(tracker.label);
                    }
                });

                return trackerColumns;
            }, []);
        },

        /**
         * Get the progress columns.
         */
        pathwayProgressColumns() {
            return this.meta.progress?.map((progress, index) => ({
                title: progress,
                key: 'progress' + index,
            })) ?? [];
        },

        /**
         * Get the excluded column label.
         */
        excludedColumnLabel(): string {
            return this.meta?.labels?.excluded ?? 'Date';
        },
        /**
         * Get the included column label.
         */
        includedColumnLabel(): string {
            return 'Cibles';
        },
        /**
         * Determine if we should display the labels in the excluded columns.
         * We should display the labels if we have more than one excluded group.
         */
        shouldAggregateExcludedLabels(): boolean {
            return Object.keys(this.meta.excluded).length > 1;
        }
    },
    watch: {
        customers: {
            immediate: true,

            handler(value) {
                this.programCustomers = value;
            }
        }
    },
    methods: {
        /**
         * Included group label.
         * Should be all the inclusion group labels with a pipe separator.
         */
        groupLabels(consumer): string {
            let groups = '';
            consumer.groups.included.forEach(group => {
                if (Object.hasOwn(this.meta.included, group.slug)) {
                    if (!groups) {
                        groups = this.meta.included[group.slug];
                    } else {
                        groups += ' | ' + this.meta.included[group.slug];
                    }
                }
            });

            return groups;
        },
        localeDate(dateString: string) {
            return dateHelper.shortFormat(new Date(dateString));
        },
        /**
         * Get the date of the last visit for the excluded group.
         */
        date(consumer, key): string | void {
            const group = consumer.groups.excluded.find(item => item.slug === key);

            if (group && consumer.last_visits.excluded) {
                return this.localeDate(consumer.last_visits.excluded);
            }
        },
        /**
         * Click event.
         */
        onRowClick(event, row) {
            const consumer = this.consumers.find(consumer => consumer.key === row.item.key);

            if (consumer && !this.enableSelection) {
                this.$emit('click', consumer);
            }
        },
        /**
         * Aggregate all the excluded values with the label and the exclusion date.
         * At the moment, we doesn't have the date of the exclusion group, so we need to take the global excluded date.
         */
        aggregatedExcludedValues(consumer): string[] {
            return consumer.groups.excluded.map((group) => {
                const text = this.meta.excluded[group.slug] ?? null;
                const columnValue = this.localeDate(consumer.last_visits.excluded);

                if (!this.shouldAggregateExcludedLabels) {
                    return columnValue;
                }

                return `${text} (${columnValue})`;
            });
        },

        /**
         * Get the pathway progress columns for a consumer.
         */
        pathwayProgressConsumerColumns(consumer: SupportProgramPatient) {
            const progresses = {};

            this.meta.progress?.forEach((_, index) => {
                progresses['progress' + index] = consumer.pathway?.progress[index]
                    ? (consumer.pathway?.progress[index] as string).toString()
                    : '-';
            });

            return progresses;
        },
        /**
         * Custom sort function applied to all the columns.
         */
        customSort(a: string | string[] | null, b: string | string[] | null): number {
            if (Array.isArray(a) && Array.isArray(b)) {
                return a.length - b.length;
            }

            if (Array.isArray(a)) {
                return -1;
            }

            if (Array.isArray(b)) {
                return 1;
            }

            if (dayjs(a, 'DD-MM-YYYY').isValid() && dayjs(b, 'DD-MM-YYYY').isValid()) {
                return dayjs(a, 'DD-MM-YYYY').isAfter(dayjs(b, 'DD-MM-YYYY')) ? 1 : -1;
            }

            return (a || '').localeCompare(b || '');
        }
    }
});
</script>
