<template>
  <BaseContainer>
    <div class="flex flex-1 flex-col p-5 bg-gradient-to-r from-darkPurple-default to-darkBlue-default">
      <div
        v-if="!answered"
        class="flex flex-row justify-between"
      >
        <div
          v-if="loading"
          class="flex flex-1"
        >
          <BaseSpinner
            size="medium-small"
            color="white"
          />
        </div>
        <div v-if="!loading && !!question">
          <div class="flex flex-row items-center">
            <div class="flex bg-white rounded-full mr-3 p-2">
              <img
                :src="pharmacistLogo"
                class="h-12"
                alt="pharmacien"
              >
            </div>
            <span class="text-md font-bold text-white">
              {{ question.title }}
            </span>
          </div>
          <div class="flex flex-1 flex-row flex-wrap mt-5">
            <survey-answer
              :question="question"
              inline
              class="mb-5"
              @on-answer-press="answerQuestion"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <div class="flex flex-row items-center">
          <div class="flex bg-white rounded-full mr-3 p-2">
            <img
              :src="pharmacistLogo"
              class="h-12"
              alt="pharmacien"
            >
          </div>
          <div>
            <span class="font-bold text-white">
              Votre réponse a bien été enregistrée, merci.
            </span>
          </div>
        </div>
      </div>
    </div>
  </BaseContainer>
</template>

<script lang="ts">
import BaseSpinner from '@/core/components/base/spinner/BaseSpinner.vue';
import BaseContainer from '@/core/components/base/BaseContainer.vue';
import SurveyAnswer from '@/survey/components/SurveyAnswer.vue';
import {pathwayApi, surveyApi} from '@/container';
import {defineComponent} from "vue";
import {SurveyQuestion} from "@/core/interfaces/survey/Survey";
import pharmacistLogo from '@/assets/icons/pharmacist.svg';

export default defineComponent({
    name: 'SurveyInline',
    components: {
        SurveyAnswer,
        BaseSpinner,
        BaseContainer
    },
    props: {
        survey: {
            type: Object,
            required: true
        },
        consumerKey: {
            type: String,
            required: true
        },
        pathwayStepId: {
            type: Number,
            required: false,
            default: null
        },
        storedInstanceId: {
            type: Number,
            required: false,
            default: null
        }
    },
    data() {
        return {
            question: null as null | SurveyQuestion,
            answered: false,
            loading: false,
            instanceId: null as number | null,
            pharmacistLogo
        };
    },
    mounted() {
        this.question = this.survey.sections[0].questions[0];

        if (!this.storedInstanceId) {
            surveyApi()
                .createInstance(this.survey.id, this.consumerKey)
                .then(response => {
                    this.instanceId = response.id;
                });
        } else {
            this.answered = true;
            this.instanceId = this.storedInstanceId;
        }
    },
    methods: {
    /**
     * Answer a survey question.
     *
     * @param answer The answer to register.
     */
        answerQuestion(answer: any) {
            if (!this.instanceId) {
                return;
            }

            this.loading = true;

            surveyApi()
                .answer(this.survey.id, this.instanceId, answer)
                .then(this.storeAnswer)
                .finally(() => {
                    this.answered = true;
                    this.loading = false;
                });
        },
        /**
     * Store the instance in pathway if a pathway exists.
     *
     * @returns {Promise}
     */
        storeAnswer() {
            if (!this.pathwayStepId || !this.instanceId) {
                return Promise.resolve();
            }

            return pathwayApi().storeConsumerStep(
                this.pathwayStepId,
                this.consumerKey,
                this.instanceId,
                'survey-answer',
                true
            );
        }
    }
});
</script>
