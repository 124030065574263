import {disruptionLogisticsApi} from "@/container";
import useSwr from "@/core/composables/swr/useSwr";

/**
 * Composable to fetch the product availability partners.
 */
const useProductAvailabilityPartners = () => {
    const {data, error, mutate, isLoading} = useSwr(
        'product-availability/partners',
        () => disruptionLogisticsApi().partnerLaboratories()
    );

    return {
        data,
        error,
        isLoading,
        mutate,
    };
};

export default useProductAvailabilityPartners;
