<script setup lang="ts">
import BaseLogo from "@/core/components/logo/BaseLogo.vue";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";
import useLaboratory from "@/laboratory/composables/useLaboratory";

const props = defineProps<{
    id: string;
}>();

const links = [
    {
        label: "Accueil",
        to: {name: "laboratory.home"}
    },
    {
        label: "Patients",
        to: {name: "laboratory.consumers"}
    },
    {
        label: "Vendeurs",
        to: {name: "laboratory.operators"}
    },
    {
        label: "Produits",
        to: {name: "laboratory.products"}
    },
    {
        label: "Ventes",
        to: {name: "laboratory.sales"}
    }
];

const {data, isLoading} = useLaboratory(props.id);
</script>

<template>
  <BaseFlexSpinner v-if="isLoading" />
  <div
    v-else
    class="h-full overflow-auto" :style="{
      backgroundImage: data?.branding?.background_mobile
        ? `linear-gradient(to top, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 80%), url(${data.branding.background_mobile.url})`
        : '',
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    }"
  >
    <div class="flex bg-white gap-32 pr-6 items-center">
      <div class="flex-1 bg-white px-4 pt-6 pb-4 space-y-6">
        <div class="space-y-2">
          <h1 class="text-black">
            Gestion de la relation patient {{ data?.name }}
          </h1>
          <div class="border-l-2 border-black pl-3">
            Bonjour, bienvenue dans l'espace de gestion de la relation votre pharmacie avec vos patients {{
              data?.name
            }}.
          </div>
        </div>
        <div class="flex gap-4">
          <router-link
            v-for="link in links" :key="link.label"
            :to="link.to" class="text-black"
            active-class="font-bold"
          >
            {{ link.label }}
          </router-link>
        </div>
      </div>
      <BaseLogo alt="logo" :logo="data?.logo" img-class="object-contain" class="bg-white" />
    </div>
    <router-view :laboratory-id="props.id" />
  </div>
</template>
