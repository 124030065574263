<template>
  <div
    v-if="loading"
    class="p-6"
  >
    <BaseSpinner size="medium-small" />
  </div>
  <div v-else>
    <div
      v-if="survey"
      class="p-6 flex flex-row text-white bg-gradient-to-r from-darkPurple-default to-darkBlue-default"
    >
      <div class="flex flex-col">
        <p class="text-2xl">
          {{ survey.name }}
        </p>
        <p class="py-2">
          {{ verdict?.title }}
        </p>
        <p class="pt-5">
          {{ verdict?.description || "" }}
        </p>
      </div>
      <div
        v-if="verdict?.options?.isScoreEssential"
        class="px-5 flex flex-1 flex-col align-end"
      >
        <span class="text-5xl bg-white text-primary p-5 rounded-full">{{ verdict.score }}</span>
      </div>
    </div>
    <BaseCard v-if="summary" class="space-y-4">
      <BaseCardHeader>
        <h2>
          Résumé
          <Icon name="mdi-creation" color="primary" />
        </h2>
      </BaseCardHeader>
      <BaseCardBody>
        <MarkdownView :content="summary" />
      </BaseCardBody>
    </BaseCard>
    <div
      v-if="!!survey?.sections"
      class="p-3"
    >
      <SurveyBuiltSection
        v-for="section in survey.sections"
        :key="`section-${section.id}`"
        :section="section"
        :survey-id="survey.id"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {surveyApi} from '@/container';
import BaseSpinner from '@/core/components/base/spinner/BaseSpinner.vue';
import SurveyBuiltSection from '@/survey/components/SurveyBuiltSection.vue';
import {Survey, SurveyVerdict} from "@/core/interfaces/survey/Survey";
import Icon from "@/core/components/icon/Icon.vue";
import MarkdownView from "@/core/components/markdown/Markdown.vue";
import BaseCard from "@/core/components/base/BaseCard.vue";
import BaseCardHeader from "@/core/components/base/BaseCardHeader.vue";
import BaseCardBody from "@/core/components/base/BaseCardBody.vue";

export default defineComponent({
    name: 'SurveyBuilt',
    components: {BaseCardBody, BaseCardHeader, BaseCard, MarkdownView, Icon, SurveyBuiltSection, BaseSpinner},
    data() {
        return {
            survey: null as Survey | null,
            verdict: null as SurveyVerdict | null,
            summary: null as string | null,
            loading: false
        };
    },
    watch: {
        '$route.params': {
            immediate: true,

            handler(params) {
                if (params.instanceId) {
                    this.loading = true;
                    this.verdict = params.verdict;
                    surveyApi()
                        .builtAnsweredInstance(params.instanceId)
                        .then((response) => {
                            this.survey = response.survey;
                            this.verdict = response.verdict ?? null;
                            this.summary = response.ai_summary?.summary ?? null;
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            }
        }
    }
});
</script>
