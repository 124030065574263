<template>
  <div
    class="flex flex-1 cursor-pointer bg-white"
    @click="redirect"
  >
    <div
      :class="(item.data.type === 'order-created' || item.data.type === 'available-again')
        ? 'flex bg-orange-light items-center rounded-l-2xl pl-3 pr-1 py-2'
        : 'flex bg-white items-center rounded-l-2xl pl-3 pr-1 py-2'"
      :style="{ height: BROADCAST_NOTIFICATION_HEIGHT + 'px' }"
    >
      <div class="mr-3 flex-shrink-0">
        <img
          class="h-10 w-10"
          src="@/assets/images/logos/apodis.svg"
          alt="Logo Apodis"
        >
      </div>
      <div
        class="flex flex-col"
      >
        <div class="text-primary text-lg font-black break-all line-clamp-1">
          {{ item.data.title }}
        </div>
        <div class="text-sm break-words line-clamp-3">
          {{ item.data.description }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {BroadcastNotificationProps} from "@/notification/interfaces";
import {ref, watch} from "vue";
import {LiveItemType} from "@/core/interfaces/live/LiveItem";
import {BROADCAST_NOTIFICATION_HEIGHT} from "@/notification/components/notification-broadcast/constants";

const props = defineProps<{
    item: BroadcastNotificationProps
}>();

const timeout = ref<NodeJS.Timeout | null>(null);

/**
 * Redirects to the notification page.
 */
const redirect = () => {
    let path = '';

    switch (props.item.data.type) {
        case LiveItemType.ORDER_CREATED:
            path = `/notification/history?orderId=${props.item.data.resource_id}`;
            break;
        case 'available-again':
            path = `/disruption-logistics/disruptions`;
            break;
        default:
            path = '/notification/history';
    }

    window.ipcRenderer.send('navigate', path, true);
};

watch(() => props.item, (item) => {
    if (!item.settings.notification.time || item.data.type === 'available-again') {
        return;
    }

    if (timeout.value) {
        clearTimeout(timeout.value);
    }

    timeout.value = setTimeout(() => {
        window.ipcRenderer.send('hide-notification');
    }, item.settings.notification.time);
}, {immediate: true});
</script>
