import {defineStore} from "pinia";
import Host from "../core/interfaces/Host";
import LegacyAccess from "@/core/interfaces/Access";
import StorageService from "@/core/services/StorageService";
import {environmentApi, healthcenterApi, hostApi, notificationApi} from "@/container";
import {version} from "../../package.json";

export const useSystemStore = defineStore('system', {
    state: () => {
        return {
            host: null as Host | null,
            environment: null as LegacyAccess | null,
            notificationCount: 0 as number,
            groupingLogo: undefined as string | undefined,
        };
    },
    actions: {
        /**
         * Init system store.
         *
         * @param systemLoaded
         */
        async init() {
            return Promise.all([
                this.updateHostName(),
                this.loadNotificationCount(),
                this.loadEnvironment()
            ])
                .then(() => StorageService().store('systemLoaded', 'true'));
        },
        /**
         * Refresh store if necessary
         */
        refresh() {
            const systemLoaded = StorageService().read('systemLoaded');
            const systemIsLoaded = systemLoaded === 'true';

            if (systemIsLoaded && this.environment === null && this.host === null) {
                return this.init();
            }

        },
        /**
         * Update host name.
         */
        async updateHostName() {
            return this.getHostname()
                .then((hostName: string) => {
                    hostApi()
                        .update({
                            name: hostName,
                            connect_version: version
                        })
                        .then(host => {
                            this.host = host;
                        });
                });
        },
        /**
         * Load the notification count.
         */
        async loadNotificationCount(): Promise<void> {
            return notificationApi()
                .count()
                .then(response => {
                    this.notificationCount = response.count;
                });
        },
        /**
         * Load the environment.
         */
        async loadEnvironment(): Promise<void> {
            return environmentApi().read()
                .then((environment) => {
                    this.environment = environment;
                    this.initializeOrganizationSocket(environment.organizationId);

                    if (environment.entity?.grouping?.is_partner) {
                        return healthcenterApi()
                            .healthcenterGrouping(environment.entity.id)
                            .then((grouping) => {
                                this.groupingLogo = grouping.branding?.logo.renderPath;
                            });
                    }
                });
        },
        /**
         * Retrieve the host name from electron process.
         */
        async getHostname(): Promise<string> {
            return await window.ipcRenderer.invoke('getHostname');
        },
        /**
         * Subscribe to the organization socket channel.
         *
         * @param organizationId
         */
        initializeOrganizationSocket(organizationId: number) {
            window.ipcRenderer.socketSubscribe(`private-organization.${organizationId}`);
        }
    }
});
