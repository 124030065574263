<script setup lang="ts">
import {InterviewInstance} from "@/interview/interfaces/interview";
import InterviewShortCutContainer
    from "@/interview/components/instance-modal/short-cuts/InterviewShortCutContainer.vue";

import InterviewSurveyReport from "@/interview/components/instance-modal/InterviewSurveyReport.vue";
import BaseSpinner from "@/core/components/base/spinner/BaseSpinner.vue";
import Icon from "@/core/components/icon/Icon.vue";
import MarkdownView from "@/core/components/markdown/Markdown.vue";
import useSurveyInstanceBuilt from "@/survey/composables/useSurveyInstanceBuilt";

const props = defineProps<{
    instance: Omit<InterviewInstance, "interview">;
}>();

const {data, isLoading} = useSurveyInstanceBuilt(props.instance.survey_answer_instance_id);
</script>

<template>
  <InterviewShortCutContainer>
    <BaseSpinner v-if="isLoading" />
    <div v-else class="flex flex-col gap-8">
      <div v-if="data?.ai_summary?.summary" class="bg-white rounded-md p-4 space-y-6">
        <h2>
          Résumé
          <Icon name="mdi-creation" color="primary" />
        </h2>

        <MarkdownView :content="data.ai_summary.summary" />
      </div>
      <div v-if="data?.survey" class="bg-white rounded-md p-4 space-y-4">
        <h2>Questions-Réponses</h2>
        <InterviewSurveyReport :survey="data.survey" />
      </div>
    </div>
  </InterviewShortCutContainer>
</template>

<style scoped>

</style>
