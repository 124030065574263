<template>
  <div class="bg-white shadow-md rounded-lg cursor-pointer" @click="navigateToExtension(extension)">
    <div class="p-3">
      <div class="flex gap-3">
        <ProgramLogo :program="extension" :size="60" />
        <div class="flex flex-col flex-1">
          <div class="flex items-center">
            <h1 class="text-black">
              {{ extension.name }}
            </h1>
            <icon name="mdi-chevron-right" :size="25" />
          </div>
          <div class="text-sm text-gray-500 flex-1">
            {{ extension.short_description }}
          </div>
        </div>
        <SupportProgramNotificationToggle
          v-if="extension.enabled && ExtensionService.isProgramExtension(extension) && extension.sub_type === 'support'"
          class="self-start h-16"
          label-direction="left"
          color="secondary"
          :support-program-id="extension.sub_id"
        />
      </div>
    </div>
    <div class="bg-primary/5 rounded-b-lg px-3 py-2 flex justify-between items-center">
      <VSkeletonLoader
        v-if="loadingShowcase"
        class="bg-transparent w-32"
        height="15"
        type="ossein"
      />
      <div v-else class="flex flex-col md:flex-row md:gap-4 md:items-center">
        <div
          v-for="(item, index) in orderedShowcaseItems"
          :key="index"
        >
          <ShowcaseStatisticItem
            :item="item"
          />
        </div>
      </div>
      <button class="text-primary font-bold">
        {{ extension.enabled ? 'Accéder au module' : 'Voir le potentiel' }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import {ref, watch, computed} from 'vue';
import {useRouter} from "vue-router";
import Icon from "@/core/components/icon/Icon.vue";
import Extension from "@/core/interfaces/extension/Extension";
import ProgramLogo from "@/program/components/program-logo/ProgramLogo.vue";
import {extensionApi} from "@/container";
import {ShowcaseItem, ShowcaseType} from "@/core/interfaces/showcase";
import ShowcaseStatisticItem from "@/subscription-plan/components/ShowcaseStatisticItem.vue";
import ExtensionNavigationService from "@/extension/services/ExtensionNavigationService";
import SupportProgramNotificationToggle
    from "@/program/components/support-program/SupportProgramNotificationToggle.vue";
import ExtensionService from "@/extension/services/ExtensionService";

const loadingShowcase = ref(false);
const router = useRouter();
const showcase = ref<ShowcaseItem[] | null>(null);

const props = defineProps<{
    extension: Extension
}>();

/**
 * Fetch the showcase of the extension.
 */
const fetchShowcase = async (extensionId: number) => {
    loadingShowcase.value = true;

    try {
        showcase.value = await extensionApi()
            .showcase(extensionId);
    } finally {
        loadingShowcase.value = false;
    }
};

watch(() => props.extension.id, fetchShowcase, {immediate: true});

/**
 * Orders the showcase items in order to get the consumer count first, then the potential.
 */
const orderedShowcaseItems = computed(() => {
    if (!showcase.value) {
        return [];
    }

    return [...showcase.value]
        .filter((item: ShowcaseItem) => item.type !== ShowcaseType.FIRST_THREE_CONSUMERS)
        .sort((a: ShowcaseItem, b: ShowcaseItem) => {
            if (a.type === ShowcaseType.CONSUMER_COUNT) {
                return -1;
            }

            if (b.type === ShowcaseType.CONSUMER_COUNT) {
                return 1;
            }

            return 0;
        });
});

/**
 * Navigate to the extension page.
 */
const navigateToExtension = (extension: Extension) => {
    ExtensionNavigationService.navigate(extension, router);
};
</script>
