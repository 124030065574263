import {laboratoryApi} from "@/container";
import useSwr from "@/core/composables/swr/useSwr";

/**
 * Composable to fetch a laboratory.
 *
 * @param {string} laboratoryId The id of the laboratory.
 */
const useLaboratory = (laboratoryId?: string) => {
    const {data, error, isValidating, isLoading} = useSwr(
        laboratoryId && `/laboratories/${laboratoryId}`,
        () => laboratoryApi().patientPartnerLaboratory(Number(laboratoryId)),
    );

    return {
        data,
        error,
        isValidating,
        isLoading
    };
};

export default useLaboratory;
