import {guidanceApi, pathwayApi} from '@/container';
import {usePatientStore} from '@/stores/patient.store';

/**
 * The survey service.
 *
 * @author Pierre-Gérard David <pg.david@apodispharma.com>
 * @returns The public object.
 */
export default function () {
    return {
        /**
         * Start a pathway step.
         *
         * @param {Object} pathway The pathway.
         * @param {Object} step The pathway step.
         * @param {string} consumerKey The consumer key.
         *
         * @returns {*}
         */
        start(pathway, step, consumerKey) {
            return this.call('start', pathway, step, consumerKey);
        },
        /**
         * Finish a pathway step.
         *
         * @param {Object} pathway The pathway.
         * @param {Object} step The pathway step object.
         * @param {string} consumerKey The consumer key.
         * @param {Object} data Optional consumer steppable data to store.
         *
         * @returns {*}
         */
        finish(pathway, step, consumerKey, data = null) {
            return this.call('finish', pathway, step, consumerKey, data);
        },
        /**
         * call a step dynamic method.
         *
         * @param name The method name.
         * @param {Object} pathway The pathway.
         * @param {Object} step The pathway step.
         * @param {string} consumerKey The consumer key.
         * @param {Object} data Optional consumer steppable data to store.
         */
        call(name, pathway, step, consumerKey, data) {
            const rawMethod = step.type
                ? name + step.type.charAt(0).toUpperCase() + step.type.slice(1)
                : 'pass';
            const method = rawMethod.replace(/(-)(.)/g, (match, p1, p2) => p2.toUpperCase());

            if (typeof this[method] === 'function') {
                return this[method](pathway, step, consumerKey, data);
            } else {
                throw new Error('Unhandled step type.');
            }
        },

        /**
         * Start a survey step.
         */
        startSurvey() {
            // Survey modal should be open inside a component using inject
            return Promise.resolve(true);
        },

        /**
         * Start a survey step.
         */
        startSurveyBundle() {
            // Survey modal should be open inside a component using inject
            return Promise.resolve(true);
        },

        /**
         * Print guidance document.
         *
         * @param {Object} pathway The pathway.
         * @param {Object} step The pathway step object.
         * @param {string} consumerKey The consumer key.
         */
        startGuidance(pathway, step, consumerKey) {
            if (!!step.steppable.document_route) {
                return guidanceApi()
                    .document(step.steppable.document_route)
                    .then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response], {type: response.type}));
                        const printWindow = window.open(url);
                        printWindow.print();
                    })
                    .finally(() => this.finishGuidance(pathway, step, consumerKey, null) /** Auto-finish step when print clicked **/);
            } else {
                this.finishGuidance(pathway, step, consumerKey, null);
            }
        },
        /**
         * Finish a guidance step.
         *
         * @param {Object} pathway The pathway.
         * @param {Object} step The pathway step object.
         * @param {string} consumerKey The consumer key.
         *
         * @returns {Promise}
         */
        finishGuidance(pathway, step, consumerKey) {
            return pathwayApi()
                .storeConsumerStep(step.id, consumerKey)
                .then(() => this.updatePathway(pathway, consumerKey));
        },
        /**
         * Start an aftercare step.
         *
         * @param {Object} pathway The pathway.
         * @param {Object} step The pathway step object.
         * @param {string} consumerKey The consumer key.
         */
        startAftercare(pathway, step, consumerKey) {
            return pathwayApi()
                .storeConsumerStep(step.id, consumerKey)
                .then(() => this.updatePathway(pathway, consumerKey));
        },

        /**
         * Pass the current step and go to next.
         *
         * @param {Object} pathway The pathway.
         * @param {Object} step The pathway step object.
         * @param {string} consumerKey The consumer key.
         */
        pass(pathway, step, consumerKey) {
            return pathwayApi()
                .storeConsumerStep(step.id, consumerKey)
                .then(() => this.updatePathway(pathway, consumerKey));
        },

        /**
         * Start an onboarding step.
         *
         * @returns {Promise<unknown>}
         */
        startOnboarding() {
            return new Promise(resolve => {
                resolve(true);
            });
        },

        /**
         * Finish an onboarding step.
         *
         * @param {Object} pathway The pathway.
         * @param {Object} step The pathway step object.
         * @param {string} consumerKey The consumer key.
         *
         * @returns {Promise<unknown>}
         */
        finishOnboarding(pathway, step, consumerKey) {
            return pathwayApi()
                .storeConsumerStep(step.id, consumerKey)
                .then(() => this.updatePathway(pathway, consumerKey));
        },
        /**
         * Update pathway after a step.
         *
         * @param {Object} pathway The pathway.
         * @param {string} consumerKey The consumer key.
         * @returns {Promise<any>}
         */
        updatePathway(pathway, consumerKey) {
            const {loadPathway} = usePatientStore();
            return loadPathway(pathway.support_program_id, consumerKey);
        }
    };
}
