import {RouteRecordRaw} from "vue-router";
import LaboratoryListView from "@/laboratory/views/LaboratoryListView.vue";
import LaboratoryLayoutView from "@/laboratory/views/LaboratoryLayoutView.vue";
import LaboratoryPatientView from "@/laboratory/views/LaboratoryPatientsView.vue";
import LaboratoryOperatorView from "@/laboratory/views/LaboratoryOperatorsView.vue";
import LaboratorySalesView from "@/laboratory/views/LaboratorySalesView.vue";
import LaboratoryHomeView from "@/laboratory/views/LaboratoryHomeView.vue";
import LaboratoryProductsView from "@/laboratory/views/LaboratoryProductsView.vue";

const routes: RouteRecordRaw[] = [
    {
        path: '/laboratories',
        name: 'laboratories',
        component: LaboratoryListView,
        meta: {
            title: 'Mes partenaires'
        }
    },
    {
        path: '/laboratories/:id',
        name: 'laboratory',
        component: LaboratoryLayoutView,
        props: true,
        redirect() {
            return {name: 'laboratory.home'};
        },
        children: [
            {
                path: 'home',
                name: 'laboratory.home',
                component: LaboratoryHomeView
            },
            {
                path: 'consumers',
                name: 'laboratory.consumers',
                component: LaboratoryPatientView
            },
            {
                path: 'operators',
                name: 'laboratory.operators',
                component: LaboratoryOperatorView
            },
            {
                path: 'sales',
                name: 'laboratory.sales',
                component: LaboratorySalesView,
                props: true
            },
            {
                path: 'products',
                name: 'laboratory.products',
                component: LaboratoryProductsView,
                props: true
            }
        ]
    }
];

export default routes;
