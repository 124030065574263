<template>
  <base-container>
    <div
      class="flex flex-1 flex-col"
    >
      <div>
        <nav
          class="flex border-b-2 border-collapse"
          aria-label="Tabs"
        >
          <router-link
            :to="{ name: 'patient-administration.dashboard' }"
            active-class="border-primary text-primary font-semibold border-b-2.5"
            class="border-transparent -mb-0.5 text-primary whitespace-nowrap py-2 px-5 font-medium text-sm"
          >
            Mes patients
          </router-link>
          <router-link
            :to="{ name: 'patient-administration.renewal.index' }"
            active-class="border-primary text-primary font-semibold border-b-2.5"
            class="border-transparent -mb-0.5 text-primary whitespace-nowrap py-2 px-5 font-medium text-sm"
          >
            Renouvellement
          </router-link>
          <router-link
            :to="{ name: 'patient-administration.communication' }"
            active-class="border-primary text-primary font-semibold border-b-2.5"
            class="border-transparent -mb-0.5 text-primary whitespace-nowrap py-2 px-5 font-medium text-sm"
          >
            Communication
          </router-link>
          <router-link
            :to="{ name: 'patient-administration.certified-patients' }"
            active-class="border-primary text-primary font-semibold border-b-2.5"
            class="border-transparent -mb-0.5 text-primary whitespace-nowrap py-2 px-5 font-medium text-sm"
          >
            Application mobile
          </router-link>
          <router-link
            :to="{ name: 'patient-administration.orders' }"
            active-class="border-primary text-primary font-semibold border-b-2.5"
            class="border-transparent -mb-0.5 text-primary whitespace-nowrap py-2 px-5 font-medium text-sm"
          >
            Click&Collect
          </router-link>
          <router-link
            :to="{ name: 'patient-administration.offers' }"
            active-class="border-primary text-primary font-semibold border-b-2.5"
            class="border-transparent -mb-0.5 text-primary whitespace-nowrap py-2 px-5 font-medium text-sm"
          >
            Promotions
          </router-link>
          <router-link
            :to="{ name: 'patient-administration.surveys' }"
            active-class="border-primary text-primary font-semibold border-b-2.5"
            class="border-transparent -mb-0.5 text-primary whitespace-nowrap py-2 px-5 font-medium text-sm"
          >
            Questionnaires
          </router-link>
        </nav>
      </div>
      <router-view :key="$route.path" />
    </div>
  </base-container>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import BaseContainer from '@/core/components/base/BaseContainer.vue';

export default defineComponent({
    name: 'PatientAdministrationScreen',
    components: {
        BaseContainer
    }
});
</script>
