<script setup lang="ts">
import BaseCard from "@/core/components/base/BaseCard.vue";
import BaseCardHeader from "@/core/components/base/BaseCardHeader.vue";
import BaseCardBody from "@/core/components/base/BaseCardBody.vue";
import Icon from "@/core/components/icon/Icon.vue";

defineProps<{
    label: string;
    value: string | number;
    periodLabel: string;
}>();
</script>

<template>
  <BaseCard>
    <BaseCardHeader>
      <div class="flex">
        <label class="flex-1 line-clamp-1">{{ label }}</label>
        <v-tooltip :text="periodLabel">
          <template #activator="{ props }">
            <Icon name="mdi-information-slab-circle" color="gray-700" v-bind="props" />
          </template>
        </v-tooltip>
      </div>
    </BaseCardHeader>
    <BaseCardBody>
      <span class="font-bold text-xl">{{ value }}</span>
    </BaseCardBody>
  </BaseCard>
</template>

<style scoped>

</style>
