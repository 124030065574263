import {ref} from "vue";
import {DetectionNotificationProps, DetectionPatient} from "@/notification/interfaces";
import {UserSettings} from "@/settings/interfaces";
import {detectionApi} from "@/container";
import Consumer from "@/core/interfaces/consumers/Consumer";
import {Program} from "@/program/interfaces/Program";

const useCustomerDetectionIpcEvents = () => {
    const isDetecting = ref(false);

    /**
     * Handle the detection of a patient.
     *
     * @param hostname
     * @param patient
     * @param settings
     */
    const handleDetection = async (
        hostname: string | null,
        patient: DetectionPatient,
        settings: UserSettings
    ): Promise<DetectionNotificationProps> => {
        isDetecting.value = true;
        window.ipcRenderer.send('detection-start');

        try {
            const detection = await detectionApi().create(hostname, patient, true);
            const item: DetectionNotificationProps = {
                patient,
                customer: null,
                loyalty_cards: [],
                settings,
                certified: false
            };

            if (detection?.customer) {
                window.ipcRenderer.send('customer-detected', detection.customer);
                const data = await detectionApi().read(detection.customer.id);

                const customer = data.consumers.find((consumer: Consumer) => consumer.type === 'customer');
                const beneficiaries = data.consumers.filter((consumer: Consumer) => consumer.type === 'beneficiary');

                item.customer = {...customer, beneficiaries};
                item.loyalty_cards = data.loyalty_cards ?? [];
                item.certified = data.certified;
            }

            return item;
        } finally {
            isDetecting.value = false;
            window.ipcRenderer.send('detection-completed');
        }
    };

    /**
     * Get the preview detection item.
     *
     * @param settings
     */
    const previewItem = (settings: UserSettings) => {
        const patient = {
            nir: '170000000000000',
            lastName: 'au comptoir',
            firstName: 'Patient',
            birthDate: '01011980'
        };

        const exampleProgram = {
            id: 1,
            name: "Programme exemple",
            teaser: "Recommandations du programme"
        } as Program;

        const item: DetectionNotificationProps = {
            patient,
            customer: {
                id: 0,
                type: "customer",
                nir: "170000000000000",
                information: {
                    first_name: 'Patient',
                    last_name: 'au comptoir',
                    birth_date: '01011980',
                    gender: 'male',
                    name: 'Patient au comptoir',
                },
                statistics: null,
                healthcenter: null,
                key: 'c_0',
                programs: [
                    exampleProgram,
                    {
                        ...exampleProgram,
                        id: 2
                    },
                    {
                        ...exampleProgram,
                        id: 3
                    }
                ],
                beneficiaries: [],
            },
            settings,
            loyalty_cards: [],
            certified: false
        };

        return item;
    };

    /**
     * Reset the patient detection.
     *
     * @param patient
     * @param hostname
     */
    const resetPatient = (patient: DetectionPatient, hostname: string) => {
        detectionApi().create(hostname, patient, false);
    };

    return {
        isDetecting,
        handleDetection,
        resetPatient,
        previewItem
    };
};

export default useCustomerDetectionIpcEvents;
