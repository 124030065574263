<template>
  <div class="flex flex-col gap-y-4">
    <h1>Renseignez le plan</h1>
    <div class="step-card">
      <div v-if="!dates?.length" class="flex flex-row justify-between items-center">
        <p>Date de première délivrance</p>
        <v-menu
          v-model="menuStartDate"
          :close-on-content-click="false"
          transition="scale-transition"
          location="right"
          :min-width="350"
        >
          <template #activator="{ props }">
            <v-text-field
              v-model="startDateFormatted"
              density="compact"
              variant="underlined"
              prepend-icon="mdi-calendar"
              v-bind="props"
              style="max-width: 140px; min-width: 125px"
              hide-details
            />
          </template>
          <v-date-picker
            v-model="startDate"
            no-title
            landscape
            hide-header
            @update:model-value="menuStartDate = false"
          />
        </v-menu>
      </div>
      <div class="flex flex-row justify-between items-start gap-x-6">
        <p>Durée de l'ordonnance</p>
        <div class="flex flex-1 flex-wrap gap-2">
          <button
            v-for="duration in monthDurationOptions"
            :key="duration"
            class="tag"
            :class="{ 'bg-primary': selectedDuration === duration }"
            @click="updateEndDate(duration)"
          >
            <span v-if="duration % 12 !== 0">{{ duration }} mois</span>
            <span v-else>{{ duration / 12 }} {{ pluralize('an', duration / 12) }}</span>
          </button>
        </div>
      </div>
      <div class="flex flex-row justify-between items-center">
        <p>Fin de validité de l'ordonnance</p>
        <v-menu
          v-model="menuEndDate"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          :min-width="350"
        >
          <template #activator="{ props }">
            <v-text-field
              v-model="endDateFormatted"
              density="compact"
              prepend-icon="mdi-calendar"
              variant="underlined"
              v-bind="props"
              style="max-width: 140px; min-width: 125px"
              hide-details
            />
          </template>
          <v-date-picker
            v-model="endDate"
            no-title
            @update:model-value="menuEndDate = false"
          />
        </v-menu>
      </div>
      <div>
        <h4>Sélectionnez les dates de renouvellement</h4>
        <div class="flex justify-center">
          <v-date-picker
            v-model="dates"
            :min="startDate"
            :max="endDate"
            no-title
            multiple
            hide-weekdays
            :hide-header="true"
            :selected-items-text="selectedDatesLabel"
            :events="[startDate, endDate]"
            color="#9988F0"
          />
        </div>
        <div v-if="dates?.length === 0" class="bg-gray-200 p-4 rounded-b-xl mb-2">
          <p>
            Allez plus vite en choisissant une fréquence !
            <span class="text-sm">Elle sera appliquée du {{ startDateFormatted }} au {{ endDateFormatted }}</span>
          </p>

          <v-chip-group
            active-class="primary--text"
            column
          >
            <v-chip
              v-for="frequency in frequencyOptions"
              :key="frequency"
              @click="quickFill(frequency)"
            >
              <span>{{ frequency }} jours</span>
            </v-chip>
          </v-chip-group>
        </div>
      </div>
    </div>
    <div
      v-if="!!dates.length"
      class="border rounded-xl p-4"
      style="background-color: #DEDCF3"
    >
      <p>
        L'ordonnance pourra être renouvelée aux dates suivantes:
      </p>
      <v-chip-group
        active-class="text-primary"
        column
      >
        <v-chip
          v-for="(date, index) in dates"
          :key="index"
          close
          close-icon="mdi-close"
          @click="removeDate(date)"
          @click:close="removeDate(date)"
        >
          <span>{{ $filters.date(date, 'short') }}</span>
        </v-chip>
      </v-chip-group>
    </div>
    <div class="flex flex-row justify-end">
      <base-button
        class="mr-4"
        @click="toggleRenewability()"
      >
        <span v-if="isRenewable">Noter comme non renouvelable</span>
        <span v-else>Noter comme renouvelable</span>
      </base-button>
      <base-button
        :disabled="!dates.length || !isRenewable"
        primary
        @click="$emit('next-step')"
      >
        Continuer
      </base-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import BaseButton from '@/core/components/base/BaseButton.vue';
import dayjs from 'dayjs';
import dateHelper from "@/core/helpers/dateHelper";
import {computed, ref} from "vue";
import {Prescription} from "@/prescription/interfaces/Prescription";
import {prescriptionApi} from "@/container";
import {useToastStore} from "@/stores/toast.store";
import {useRouter} from "vue-router";
import {pluralize} from "@/core/filters/Pluralize";

const toastStore = useToastStore();
const router = useRouter();

const componentProps = defineProps<{ prescription: Prescription }>();

const endDate = defineModel<Date>("endDate", {required: true});
const startDate = defineModel<Date>("startDate", {required: true});
const dates = defineModel<Date[]>("dates", {required: true});
const isRenewable = ref<boolean>(componentProps.prescription.renewable!);
const menuStartDate = ref(false);
const menuEndDate = ref(false);
const selectedDuration = ref<number | undefined>();

defineEmits(['next-step']);

const startDateFormatted = computed(() => dateHelper.shortFormat(startDate.value));
const endDateFormatted = computed(() => dateHelper.shortFormat(endDate.value));
const selectedDatesLabel = computed(() => dates.value.length + ' sélectionnées');
const frequencyOptions = [7, 28, 30, 84, 90];
const monthDurationOptions = [1, 2, 3, 6, 12];

/**
 * Fill the dates with a frequency.
 */
const quickFill = (frequency: number) => {
    let newDate = dayjs(startDate.value).add(frequency, 'day');
    const datesInFrequency: Date[] = [];

    while (newDate.isBefore(dayjs(endDate.value))) {
        datesInFrequency.push(newDate.toDate());

        newDate = newDate.add(frequency, 'day');
    }

    dates.value = datesInFrequency;
};

/**
 * Remove a date from the list.
 */
const removeDate = (date: Date) => {
    dates.value.splice(dates.value.indexOf(date), 1);
};

/**
 * Update the renewable state of the prescription.
 */
const toggleRenewability = () => {
    const updatedRenewability = !isRenewable.value;

    if (componentProps.prescription && componentProps.prescription?.professional?.id && componentProps.prescription.customer.id) {
        return prescriptionApi().toggleRenewable(componentProps.prescription, updatedRenewability)
            .then(() => {
                isRenewable.value = updatedRenewability;

                if (updatedRenewability) {
                    toastStore.showSuccess({content: 'L\'ordonnance a été marqué comme renouvelable'});
                } else {
                    toastStore.showSuccess({content: 'L\'ordonnance a été marqué comme non renouvelable'});
                    router.go(-1);
                }
            })
            .catch(() => {
                toastStore.showError({content: 'Erreur lors de la mise à jour de l\'ordonnance'});
            });
    }
};

const updateEndDate = (duration: number) => {
    endDate.value = dayjs(startDate.value).add(duration, 'month').toDate();
    selectedDuration.value = duration;
};

</script>

<style scoped>
:deep(.v-date-picker-month__day--hide-adjacent) {
    display: none !important;
}

.tag {
    @apply rounded-lg bg-gray-100 px-2 py-1 text-sm;
}
</style>
