/**
 * Service for laboratory charts.
 */
const ChartService = () => {
    return {
        /**
         * The color palette for the charts.
         */
        colorPalette() {
            return [
                "#3513E2",
                "#718AFF",
                "#034e2e",
                "#2a6179",
                "#27a58c",
                "#6F08AF",
                "#DB9200",
                "#F06453",
                "#CC3363",
                "#89023E",
                "#FFD700",
                "#c9bf73"
            ];
        }
    };
};

export default ChartService();
