import {AxiosInstance} from "axios";
import {PaginatedData, PaginationRequest} from "@/core/interfaces/api";
import {SurveyAnswerInstanceIndex} from "@/core/interfaces/survey/Survey";

interface SurveyAnswerInstanceRequest extends PaginationRequest {
    filler_type?: 'patient_user' | 'user_access_point';
}

/**
 * Resource for survey answered instances.
 *
 * @param apiClient
 * @param hdsClient
 *
 * @return {Object}
 */
export default function (apiClient: AxiosInstance, hdsClient: AxiosInstance) {
    return {
        /**
         * Index of completed survey answer instances for current organization.
         */
        async index(params: SurveyAnswerInstanceRequest): Promise<PaginatedData<SurveyAnswerInstanceIndex[]>> {
            return apiClient.get("/survey-answer-instances", {params})
                .then(response => response.data);
        },
        /**
         * Get the answered instances.
         *
         * @param params
         * @return {*}
         */
        answeredInstance(params: unknown) {
            return hdsClient.get('/surveys/answered-instances', {params})
                .then(response => response.data);
        },
        /**
         * Get the survey answer instance export.
         *
         * @param instanceID
         * @returns {Promise<Blob>}
         */
        export(instanceID: number): Promise<Blob> {
            return hdsClient.get(`survey-answer-instances/${instanceID}/export`, {responseType: 'blob'})
                .then(response => response.data);
        }
    };
}
