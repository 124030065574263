<script setup lang="ts">

import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";
import {useSystemStore} from "@/stores/system.store";
import {onMounted} from "vue";
import {
    authenticationService,
    environmentApi,
    storageService
} from "@/container";
import {useRouter} from "vue-router";
import {useExtensionStore} from "@/stores/extension.store";
import {useSubscriptionPlanStore} from "@/stores/subscription-plan.store";
import SentryService from "@/core/services/SentryService";

const systemStore = useSystemStore();
const extensionStore = useExtensionStore();
const subscriptionStore = useSubscriptionPlanStore();
const router = useRouter();

/**
 * On mounted, check if the user is authenticated.
 * If not, redirect to the login page.
 * If authenticated, update the host name and hydrate the stores.
 * Finally, redirect to the home page.
 */
onMounted(() => {
    if (!authenticationService().authenticated) {
        authenticationService().logout();
        router.replace({
            name: 'login'
        });
    } else {
        authCall()
            .then((environment: unknown) => {
                if (!environment) {
                    return Promise.reject();
                }

                window.ipcRenderer.authenticated(storageService().loadSettings());
                SentryService.setUser(authenticationService().user.email);

                return Promise.all([
                    systemStore.init(),
                    hydrateStores()
                ])
                    .then(() => {
                        router.replace({
                            name: 'home'
                        });
                    });
            })
            .catch(() => {
                authenticationService().logout();
                router.replace({
                    name: 'login'
                });
            });
    }
});

/**
 * Make a call to check the token validity.
 */
const authCall = (): Promise<unknown> => {
    return environmentApi()
        .read(false);
};

/**
 * Hydrate the core application stores.
 */
const hydrateStores = () => {
    return Promise.all([
        extensionStore.loadExtensions(),
        subscriptionStore.loadSubscriptionPlans()
    ]);
};
</script>

<template>
  <div class="h-screen overflow-hidden bg-primary-lightest flex flex-col">
    <BaseFlexSpinner />
  </div>
</template>
