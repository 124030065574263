<script setup lang="ts">
import Icon from "@/core/components/icon/Icon.vue";
import {DotLottieVue} from "@lottiefiles/dotlottie-vue";

interface Props {
    podiumHeights?: {
        1: string;
        2: string;
        3: string;
    };
    showTrophies?: boolean;
    className?: string;
}

withDefaults(defineProps<Props>(), {
    podiumHeights: () => ({
        1: '200px',
        2: '160px',
        3: '120px'
    }),
    showTrophies: true,
    className: ''
});

const podiumOrder = [2, 1, 3];

const getTrophyColor = (position: number): string => {
    switch (position) {
    case 1:
        return '#FFD700'; // Gold
    case 2:
        return '#C0C0C0'; // Silver
    case 3:
        return '#CD7F32'; // Bronze
    default:
        return '#000000';
    }
};
</script>

<template>
  <div :class="['flex items-end justify-center gap-4 relative overflow-hidden', className]">
    <div
      v-for="position in podiumOrder"
      :key="position"
      class="flex flex-col items-center max-w-48 flex-1"
    >
      <Icon
        v-if="showTrophies"
        name="mdi-trophy"
        class="mb-2"
        :size="50"
        :color="getTrophyColor(position)"
      />
      <div
        class="w-full bg-white rounded-t-lg shadow-lg flex flex-col items-center justify-between p-4 transition-all duration-300"
        :style="{ height: podiumHeights[position] }"
      >
        <slot :name="`position-${position}`" />
      </div>
    </div>
    <DotLottieVue
      src="animations/party.json"
      style="width: 100%; height: 100%; position: absolute"
      autoplay
      :loop="false"
    />
  </div>
</template>
